import React from "react";

const ImageCard = ({ src }) => {
  return (
    <div className="image__card__container">
      <img src={src} loading="lazy" />
    </div>
  );
};

export default ImageCard;
