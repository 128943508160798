import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { FiPlus } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import axios from "axios";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BannerImg from "../../img/banner.png";
import "./AddBanner.css";

const AddBanner = () => {
  const uid = localStorage.getItem("id");
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);
  const [link, setLink] = useState({});

  const generateRandomNumber = () => {
    const min = 10000000;
    const max = 99999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleAddBanner = () => {
    const newBanner = { id: generateRandomNumber(), image: null, url: "" };
    setBanners((prevBanners) => [...prevBanners, newBanner]);
  };

  const handleFileChange = async (e, id) => {
    const image = new FormData();
    image.append("photo", e.target.files[0]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };

    try {
      const res = await axios.post(
        "https://cdn.ecombd.org/upload/banner",
        image,
        config
      );
      toast.success("Image uploaded to CDN");
      setBanners((prevBanners) =>
        prevBanners.map((banner) =>
          banner.id === id ? { ...banner, image: res.data } : banner
        )
      );
    } catch (error) {
      console.error(error);
      toast.error("Failed to upload image");
    }
  };

  const handleURLAdd = (e, id) => {
    const newUrl = e.target.value;
    setBanners((prevBanners) =>
      prevBanners.map((banner) =>
        banner.id === id ? { ...banner, url: newUrl } : banner
      )
    );
    setLink((prevLink) => ({
      ...prevLink,
      [id]: newUrl,
    }));
  };

  const deleteImageHandler = (id) => {
    setBanners((prevBanners) =>
      prevBanners.filter((banner) => banner.id !== id)
    );
  };

  const handleSubmit = async () => {
    const isEnable = banners.every((banner) => banner.image !== null);
    if (!isEnable) {
      toast.error("Banner image require.");
      return;
    }
    try {
      await AxiosUrl.post(`add-add-banner/${uid}`, banners);
      setBanners([]);
      navigate("/banner-list");
      toast.success("Banners updated successfully");
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  return (
    <div className="add-banner-container">
      <ToastContainer />
      {banners.length > 0 &&
        banners.map((banner) => (
          <div className="image-div" key={banner.id}>
            <div className="image-container">
              <img src={banner.image ? banner.image : BannerImg} alt="banner" />
            </div>
            <div className="upload-container">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, banner.id)}
                aria-label={`Upload image for banner ${banner.id}`}
              />
              <div className="upload-container-input">
                <input
                  type="text"
                  placeholder="Enter Link URL"
                  value={link[banner.id] || ""}
                  onChange={(e) => handleURLAdd(e, banner.id)}
                  aria-label={`Enter URL for banner ${banner.id}`}
                />
              </div>
            </div>
            <div
              className="add-banner-close-btn"
              onClick={() => deleteImageHandler(banner.id)}
              role="button"
              aria-label={`Delete banner ${banner.id}`}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") deleteImageHandler(banner.id);
              }}
            >
              <MdClose />
            </div>
          </div>
        ))}
      <div className="add-banner-btn-groups">
        <Button
          variant="contained"
          color="primary"
          endIcon={<FiPlus />}
          onClick={handleAddBanner}
        >
          Add Banner
        </Button>
        {banners.length > 0 && (
          <Button
            variant="contained"
            color="secondary"
            className="add-banner-update-btn"
            onClick={handleSubmit}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddBanner;
