import React, { useState } from "react";
import { VaritionModal } from "../../LandingPageModals/LandingPageModals";

const Variantions = ({ data, setData }) => {
  const [openModal, setOpenModal] = useState();
  return (
    <div className="variation__container">
      <div className="container">
        <button
          className="landing-page-edit-btn"
          onClick={() => setOpenModal(true)}
          style={{ marginTop: "30px" }}
        >
          Edit
        </button>
        <div className="varinat__title">ভেরিয়েশন</div>
        <div className="variant__cards">
          {data.Variations.map((variant) => (
            <div className="variant__card" key={variant.id}>
              <img src={variant.src} alt="variant" />
              <div>Color: {variant.color}</div>
              <div>Price: {variant.price}</div>
            </div>
          ))}
        </div>
      </div>
      <VaritionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default Variantions;
