import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosUrl from "../../../AxiosUrl";
import "./Contact.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #00000038",
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (min-width: 600px)": {
    width: 400,
  },
};

function ContactModal({ open, setContactModal, setRefresh, Refresh, Data }) {
  const [Mobile, setMobile] = useState(Data?.number || "");
  const [Email, setEmail] = useState(Data?.Email || "");
  const [Address, setAddress] = useState(Data?.Address || "");
  const [Linkedin, setLinkedin] = useState(Data?.Linkedin || "");
  const [TikTok, setTikTok] = useState(Data?.TikTok || "");
  const [WhatsApp, setWhatsApp] = useState(Data?.WhatsApp || "");
  const [MessangerUrl, setMessangerUrl] = useState(Data?.MessangerUrl || "");
  const [FacebookPageUrl, setFacebookPageUrl] = useState(
    Data?.FacebookUrl || ""
  );
  const [YouTubeUrl, setYouTubeUrl] = useState(Data?.YouTubeUrl || "");
  const [InstagramUrl, setInstagramUrl] = useState(Data?.InstagramUrl || "");
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const fromData = {
    uid,
    Mobile,
    Email,
    Address,
    WhatsApp,
    MessangerUrl,
    FacebookPageUrl,
    YouTubeUrl,
    InstagramUrl,
    TikTok,
    Linkedin,
  };

  const submitDomain = () => {
    setLoading(true);

    AxiosUrl.post(`/update-domain-new/${uid}`, fromData)
      .then(() => {
        setRefresh(Refresh + 1);
        setContactModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={setContactModal}
      onClose={() => setContactModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box sx={style}>
        <ToastContainer />
        <div className="titleCloseBtn" style={{ textAlign: "right" }}>
          <Button
            onClick={() => setContactModal(false)}
            style={{ color: "red" }}
          >
            X
          </Button>
        </div>

        <div className="title">
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <TextField
              placeholder="Mobile"
              fullWidth
              label="Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
              value={Mobile}
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Email"
              fullWidth
              label="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              value={Email}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Address"
              fullWidth
              label="Store Address"
              onChange={(e) => setAddress(e.target.value)}
              value={Address}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="WhatsApp"
              fullWidth
              label="WhatsApp"
              onChange={(e) => setWhatsApp(e.target.value)}
              value={WhatsApp}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Messanger Url"
              fullWidth
              label="Messanger Url"
              onChange={(e) => setMessangerUrl(e.target.value)}
              value={MessangerUrl}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Facebook Page Url"
              fullWidth
              label="Facebook Page Url"
              onChange={(e) => setFacebookPageUrl(e.target.value)}
              value={FacebookPageUrl}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="YouTube Url"
              fullWidth
              label="YouTube Url"
              onChange={(e) => setYouTubeUrl(e.target.value)}
              value={YouTubeUrl}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Instagram Url"
              fullWidth
              label="Instagram Url"
              onChange={(e) => setInstagramUrl(e.target.value)}
              value={InstagramUrl}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="TikTok"
              fullWidth
              label="TikTok Url"
              onChange={(e) => setTikTok(e.target.value)}
              value={TikTok}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Linkedin"
              fullWidth
              label="Linkedin Url"
              onChange={(e) => setLinkedin(e.target.value)}
              value={Linkedin}
            />
          </Box>
        </div>

        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button
            onClick={() => setContactModal(false)}
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button onClick={submitDomain} variant="contained" disabled={loading}>
            {loading ? "Loading..." : "Continue"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default ContactModal;
