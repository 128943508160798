import React, { useState } from "react";
import { ChangePageName } from "../../LandingPageModals/LandingPageModals";

const PageName = ({ data, setData }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="description" style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <div className="page-name-container">
          <div className="page-name-title" style={{ color: "#000" }}>
            Set Page Name : {data.Name}
          </div>
          <button
            className="landing-page-edit-btn"
            onClick={() => setOpen(true)}
          >
            Edit
          </button>
        </div>
      </div>
      <ChangePageName
        open={open}
        setOpen={setOpen}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default PageName;
