import React, { useState, useEffect } from "react";
import "./ChangeStatusModal.css";
import myAxios from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Modal({ setChangeStatusOpen, data, setRefresh, setSelectedItems }) {
  const uid = localStorage.getItem("id");
  const [loading, setLoading] = useState(false);
  const [Status, setStatus] = useState();

  const Ids = [];
  data.map((data) => {
    Ids.push(data._id);
  });

  const UpdateStatus = () => {
    if (!Status) {
      toast.error("Select status and submit...");
      return;
    }
    setLoading(true);
    setRefresh(false);
    const url = `edit-all-order/${uid}`;
    myAxios
      .post(url, { Status, Ids })
      .then((res) => {
        setLoading(false);
        setChangeStatusOpen(false);
        setRefresh(true);
        setSelectedItems([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="modalBackgroundB">
      <ToastContainer />
      <div className="modalContainerB">
        <div className="titleCloseBtnB">
          <button onClick={() => setChangeStatusOpen(false)}>X</button>
        </div>
        <p style={{ textAlign: "center" }}>
          Please select order status below and click on the submit button
        </p>

        <select
          name="status"
          className="orderEditStatus"
          onChange={(e) => setStatus(e.target.value)}
          value={Status}
        >
          <option value="">Select Status</option>
          <option value="1">New</option>
          <option value="2">Call Done</option>
          <option value="11">Overseas</option>
          <option value="3">Courier Done</option>
          <option value="4">Print Done</option>
        </select>

        <div className="footerB">
          <button
            onClick={() => {
              setChangeStatusOpen(false);
            }}
            id="cancelBtnB"
          >
            Cancel
          </button>
          <button onClick={UpdateStatus}>
            {loading ? "Loading.." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
