import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const DeliveryCharge = ({ data, setData }) => {
  const [insideDhaka, setInsideDhaka] = useState(
    data.DeliveryCharge.insideDhaka
  );

  const [outsideDhaka, setOutsideDhaka] = useState(
    data.DeliveryCharge.outsideDhaka
  );

  const handleDeliveryCharge = (e) => {
    e.preventDefault();
    setData({
      ...data,
      DeliveryCharge: {
        ...data.DeliveryCharge,
        insideDhaka: insideDhaka,
        outsideDhaka: outsideDhaka,
      },
    });
    toast.success("Udated deliveryCharge successfully");
  };

  return (
    <>
      <form
        className="container delivery-container"
        onSubmit={handleDeliveryCharge}
      >
        <div className="delivery_title">ডেলিভারি চার্জ</div>
        <div className="delivery_input-group">
          <label htmlFor="insideDhaka">ঢাকার ভিতরে</label>
          <input
            type="text"
            id="insideDhaka"
            name="insideDhaka"
            placeholder="Enter Delivery Charge"
            value={insideDhaka}
            onChange={(e) => setInsideDhaka(e.target.value)}
          />
        </div>
        <div className="delivery_input-group">
          <label htmlFor="outsideDhaka">ঢাকার বাহিরে</label>
          <input
            type="text"
            id="outsideDhaka"
            name="outsideDhaka"
            placeholder="Enter Delivery Charge"
            value={outsideDhaka}
            onChange={(e) => setOutsideDhaka(e.target.value)}
          />
        </div>
        <p style={{ marginBottom: "10px" }}>
          ডেলিভারি চার্জ লিখে নিচে Set Delivery Charge ক্লিক করুন
        </p>
        <button type="submit" className="landing-page-edit-btn">
          Set Delivery Charge
        </button>
      </form>
    </>
  );
};

export default DeliveryCharge;
