import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DeleteIcon from "@mui/icons-material/Delete";
import "./LandingPageModals.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ChangeHeroModal({
  data,
  setData,
  open,
  setOpen,
  updatedImage,
  setUpdatedImage,
}) {
  const handleClose = () => {
    setUpdatedImage(null);
    setOpen(false);
  };

  async function handleImageChange(event) {
    const image = new FormData();
    image.append("photo", event?.target?.files[0]);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Image uploaded to CDN");
        setUpdatedImage(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setUpdatedImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setUpdatedImage(null);
  //   }
  // };

  const handleUpdate = () => {
    setData({ ...data, HeroImage: updatedImage });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Hero Image"}</DialogTitle>
        <DialogContent>
          <input id="image-upload" type="file" onChange={handleImageChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeDescriptionModal({
  data,
  setData,
  openDescription,
  setOpenDescription,
}) {
  const [points, setPoints] = useState(data.BulletPoints);
  const [newPoints, setNewPoints] = useState([]);
  const [title, setTitle] = useState(data.Title);
  const [productCode, setProductCode] = useState(data.ProductCode);
  const [price, setPrice] = useState(data.Price);
  const [buying, setBuying] = useState(data.Buying);
  const [deliveryOption, setDeliveryOption] = useState(data.DeliveryOption);

  const updatePoint = (index, updatedPoint) => {
    const updatedPoints = [...points];
    updatedPoints[index] = updatedPoint;
    setPoints(updatedPoints);
  };

  const addNewPointInput = () => {
    const newPointInput = {
      id: parseInt(points.length) + parseInt(newPoints.length + 1),
      text: "",
    };
    setNewPoints([...newPoints, newPointInput]);
  };

  const updateNewPoint = (id, value) => {
    const updatedNewPoints = newPoints.map((point) =>
      point.id === id ? { ...point, text: value } : point
    );
    setNewPoints(updatedNewPoints);
  };

  const deletePoint = (id) => {
    const filteredPoints = points.filter((point) => point.id !== id);
    const filteredNewPoints = newPoints.filter((point) => point.id !== id);
    setPoints(filteredPoints);
    setNewPoints(filteredNewPoints);
  };

  const handleClose = () => {
    setOpenDescription(false);
  };

  const handleUpdate = () => {
    const newPointsValues = newPoints.map((point) => ({
      id: points.length + 1,
      text: point.text,
    }));
    setData({
      ...data,
      Title: title,
      ProductCode: productCode,
      Price: price,
      Buying: buying,
      DeliveryOption: deliveryOption,
      BulletPoints: [...points, ...newPointsValues],
    });
    setOpenDescription(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openDescription}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Description"}</DialogTitle>
        <DialogContent>
          <div className="changing-description-container">
            <div className="changing-description-title">
              <input
                className="changing-description-input"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                className="changing-description-input"
                type="text"
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
              />
              <input
                className="changing-description-input"
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <input
                className="changing-description-input"
                type="text"
                value={buying}
                onChange={(e) => setBuying(e.target.value)}
              />

              <input
                className="changing-description-input"
                type="text"
                value={deliveryOption}
                onChange={(e) => setDeliveryOption(e.target.value)}
              />
            </div>
            {points.map((point, index) => (
              <div className="changing-description" key={point.id}>
                <input
                  className="changing-description-input"
                  type="text"
                  value={point.text}
                  onChange={(e) =>
                    updatePoint(index, { id: point.id, text: e.target.value })
                  }
                />
                <div
                  className="changing-description-button"
                  onClick={() => deletePoint(point.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <div className="changing-description">
              {newPoints.map((newPoint) => (
                <div key={newPoint.id}>
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter new point"
                    value={newPoint.text}
                    onChange={(e) =>
                      updateNewPoint(newPoint.id, e.target.value)
                    }
                  />
                  <Button
                    className="changing-description-button"
                    variant="contained"
                    color="secondary"
                    onClick={() => deletePoint(newPoint.id)}
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </div>
              ))}
              <button
                className="changing-description-add-button"
                onClick={addNewPointInput}
              >
                Add New
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeDecisionModal({
  data,
  setData,
  openDecision,
  setOpenDecision,
}) {
  const [points, setPoints] = useState(data.Decisions);
  const [newPoints, setNewPoints] = useState([]);

  const updatePoint = (index, updatedPoint) => {
    const updatedPoints = [...points];
    updatedPoints[index] = updatedPoint;
    setPoints(updatedPoints);
  };

  const addNewPointInput = () => {
    const newPointInput = {
      id: parseInt(points.length) + parseInt(newPoints.length + 1),
      text: "",
    };
    setNewPoints([...newPoints, newPointInput]);
  };

  const updateNewPoint = (id, value) => {
    const updatedNewPoints = newPoints.map((point) =>
      point.id === id ? { ...point, text: value } : point
    );
    setNewPoints(updatedNewPoints);
  };

  const deletePoint = (id) => {
    const filteredPoints = points.filter((point) => point.id !== id);
    const filteredNewPoints = newPoints.filter((point) => point.id !== id);
    setPoints(filteredPoints);
    setNewPoints(filteredNewPoints);
  };

  const handleClose = () => {
    setOpenDecision(false);
  };

  const handleUpdate = () => {
    const newPointsValues = newPoints.map((point) => ({
      id: points.length + 1,
      text: point.text,
    }));
    setData({
      ...data,
      Decisions: [...points, ...newPointsValues],
    });
    setOpenDecision(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openDecision}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Decisions"}</DialogTitle>
        <DialogContent>
          <div className="changing-description-container">
            {points.map((point, index) => (
              <div className="changing-description" key={point.id}>
                <textarea
                  className="changing-description-textarea"
                  type="text"
                  value={point.text}
                  onChange={(e) =>
                    updatePoint(index, { id: point.id, text: e.target.value })
                  }
                ></textarea>
                <div
                  className="changing-description-button"
                  onClick={() => deletePoint(point.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <div>
              {newPoints.map((newPoint) => (
                <div className="changing-description" key={newPoint.id}>
                  <textarea
                    className="changing-description-textarea"
                    type="text"
                    placeholder="Enter new point"
                    value={newPoint.text}
                    onChange={(e) =>
                      updateNewPoint(newPoint.id, e.target.value)
                    }
                  ></textarea>
                  <div
                    className="changing-description-button"
                    onClick={() => deletePoint(newPoint.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              ))}
              <button
                className="changing-description-add-button"
                onClick={addNewPointInput}
              >
                Add New
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeConsModal({ data, setData, openCons, setOpenCons }) {
  const [points, setPoints] = useState(data.Cons);
  const [newPoints, setNewPoints] = useState([]);

  const updatePoint = (index, updatedPoint) => {
    const updatedPoints = [...points];
    updatedPoints[index] = updatedPoint;
    setPoints(updatedPoints);
  };

  const addNewPointInput = () => {
    const newPointInput = {
      id: parseInt(points.length) + parseInt(newPoints.length + 1),
      text: "",
    };
    setNewPoints([...newPoints, newPointInput]);
  };

  const updateNewPoint = (id, value) => {
    const updatedNewPoints = newPoints.map((point) =>
      point.id === id ? { ...point, text: value } : point
    );
    setNewPoints(updatedNewPoints);
  };

  const deletePoint = (id) => {
    const filteredPoints = points.filter((point) => point.id !== id);
    const filteredNewPoints = newPoints.filter((point) => point.id !== id);
    setPoints(filteredPoints);
    setNewPoints(filteredNewPoints);
  };

  const handleClose = () => {
    setOpenCons(false);
  };

  const handleUpdate = () => {
    const newPointsValues = newPoints.map((point) => ({
      id: points.length + 1,
      text: point.text,
    }));
    setData({
      ...data,
      Cons: [...points, ...newPointsValues],
    });
    setOpenCons(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openCons}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Cons"}</DialogTitle>
        <DialogContent>
          <div className="changing-description-container">
            {points.map((point, index) => (
              <div className="changing-description" key={point.id}>
                <textarea
                  className="changing-description-textarea"
                  type="text"
                  value={point.text}
                  onChange={(e) =>
                    updatePoint(index, { id: point.id, text: e.target.value })
                  }
                ></textarea>
                <div
                  className="changing-description-button"
                  onClick={() => deletePoint(point.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <div>
              {newPoints.map((newPoint) => (
                <div className="changing-description" key={newPoint.id}>
                  <textarea
                    className="changing-description-textarea"
                    type="text"
                    placeholder="Enter new point"
                    value={newPoint.text}
                    onChange={(e) =>
                      updateNewPoint(newPoint.id, e.target.value)
                    }
                  ></textarea>
                  <div
                    className="changing-description-button"
                    onClick={() => deletePoint(newPoint.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              ))}
              <button
                className="changing-description-add-button"
                onClick={addNewPointInput}
              >
                Add New
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeContentListModal({
  data,
  setData,
  openContentList,
  setOpenContentList,
}) {
  const [points, setPoints] = useState(data.Pros);
  const [newPoints, setNewPoints] = useState([]);

  const updatePoint = (index, updatedPoint) => {
    const updatedPoints = [...points];
    updatedPoints[index] = updatedPoint;
    setPoints(updatedPoints);
  };

  const addNewPointInput = () => {
    const newPointInput = {
      id: parseInt(points.length) + parseInt(newPoints.length + 1),
      text: "",
    };
    setNewPoints([...newPoints, newPointInput]);
  };

  const updateNewPoint = (id, value) => {
    const updatedNewPoints = newPoints.map((point) =>
      point.id === id ? { ...point, text: value } : point
    );
    setNewPoints(updatedNewPoints);
  };

  const deletePoint = (id) => {
    const filteredPoints = points.filter((point) => point.id !== id);
    const filteredNewPoints = newPoints.filter((point) => point.id !== id);
    setPoints(filteredPoints);
    setNewPoints(filteredNewPoints);
  };

  const handleClose = () => {
    setOpenContentList(false);
  };

  const handleUpdate = () => {
    const newPointsValues = newPoints.map((point) => ({
      id: points.length + 1,
      text: point.text,
    }));
    setData({
      ...data,
      Pros: [...points, ...newPointsValues],
    });
    setOpenContentList(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openContentList}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Pros"}</DialogTitle>
        <DialogContent>
          <div className="changing-description-container">
            {points.map((point, index) => (
              <div className="changing-description" key={point.id}>
                <textarea
                  className="changing-description-textarea"
                  type="text"
                  value={point.text}
                  onChange={(e) =>
                    updatePoint(index, { id: point.id, text: e.target.value })
                  }
                ></textarea>
                <div
                  className="changing-description-button"
                  onClick={() => deletePoint(point.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <div>
              {newPoints.map((newPoint) => (
                <div className="changing-description" key={newPoint.id}>
                  <textarea
                    className="changing-description-textarea"
                    type="text"
                    placeholder="Enter new point"
                    value={newPoint.text}
                    onChange={(e) =>
                      updateNewPoint(newPoint.id, e.target.value)
                    }
                  ></textarea>
                  <div
                    className="changing-description-button"
                    onClick={() => deletePoint(newPoint.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              ))}
              <button
                className="changing-description-add-button"
                onClick={addNewPointInput}
              >
                Add New
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeFeaturesModal({
  data,
  setData,
  openFeatures,
  setOpenFeatures,
}) {
  const [points, setPoints] = useState(data.Features);
  const [newPoints, setNewPoints] = useState([]);

  const updatePoint = (index, updatedPoint) => {
    const updatedPoints = [...points];
    updatedPoints[index] = updatedPoint;
    setPoints(updatedPoints);
  };

  const addNewPointInput = () => {
    const newPointInput = {
      id: parseInt(points.length) + parseInt(newPoints.length + 1),
      text: "",
    };
    setNewPoints([...newPoints, newPointInput]);
  };

  const updateNewPoint = (id, value) => {
    const updatedNewPoints = newPoints.map((point) =>
      point.id === id ? { ...point, text: value } : point
    );
    setNewPoints(updatedNewPoints);
  };

  const deletePoint = (id) => {
    const filteredPoints = points.filter((point) => point.id !== id);
    const filteredNewPoints = newPoints.filter((point) => point.id !== id);
    setPoints(filteredPoints);
    setNewPoints(filteredNewPoints);
  };

  const handleClose = () => {
    setOpenFeatures(false);
  };

  const handleUpdate = () => {
    const newPointsValues = newPoints.map((point) => ({
      id: points.length + 1,
      text: point.text,
    }));
    setData({
      ...data,
      Features: [...points, ...newPointsValues],
    });
    setOpenFeatures(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openFeatures}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Features"}</DialogTitle>
        <DialogContent>
          <div className="changing-description-container">
            {points.map((point, index) => (
              <div className="changing-description" key={point.id}>
                <textarea
                  className="changing-description-textarea"
                  type="text"
                  value={point.text}
                  onChange={(e) =>
                    updatePoint(index, { id: point.id, text: e.target.value })
                  }
                ></textarea>
                <div
                  className="changing-description-button"
                  onClick={() => deletePoint(point.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <div>
              {newPoints.map((newPoint) => (
                <div className="changing-description" key={newPoint.id}>
                  <textarea
                    className="changing-description-textarea"
                    type="text"
                    placeholder="Enter new point"
                    value={newPoint.text}
                    onChange={(e) =>
                      updateNewPoint(newPoint.id, e.target.value)
                    }
                  ></textarea>
                  <div
                    className="changing-description-button"
                    onClick={() => deletePoint(newPoint.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              ))}
              <button
                className="changing-description-add-button"
                onClick={addNewPointInput}
              >
                Add New
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeTitleModal({ data, setData, openTitle, setOpenTitle }) {
  const [title, setTitle] = useState(data.ProsTitle);

  const handleClose = () => {
    setOpenTitle(false);
  };

  const handleUpdate = () => {
    setData({
      ...data,
      ProsTitle: title,
    });
    setOpenTitle(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openTitle}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Title"}</DialogTitle>
        <DialogContent>
          <div className="changing-description-container">
            <div className="changing-description">
              <textarea
                className="changing-description-textarea"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></textarea>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeSliderModal({
  data,
  setData,
  openSlider,
  setOpenSlider,
}) {
  const [images, setImages] = useState(data.SubSliders);
  const [newImages, setNewImages] = useState([]);

  const addNewImageInput = () => {
    const newImageInput = {
      id: parseInt(images.length) + parseInt(newImages.length + 1),
      src: "",
      file: null,
    };
    setNewImages([...newImages, newImageInput]);
  };

  const handleImageChange = async (file) => {
    try {
      const imageFormData = new FormData();
      imageFormData.append("photo", file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: "zesrxdyctuutydrszrestdrxy",
        },
      };
      const url = "https://cdn.ecombd.org/upload";
      const response = await axios.post(url, imageFormData, config);
      toast.success("Image uploaded to CDN");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleFileChange = async (id, event) => {
    try {
      const imageUrl = await handleImageChange(event.target.files[0]);
      const updatedImages = images.map((image) =>
        image.id === id ? { ...image, src: imageUrl } : image
      );

      const updatedNewImages = newImages.map((newImage) =>
        newImage.id === id ? { ...newImage, src: imageUrl } : newImage
      );

      setImages(updatedImages);
      setNewImages(updatedNewImages);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImage = (id) => {
    const filteredImages = images.filter((image) => image.id !== id);
    const filteredNewImages = newImages.filter((image) => image.id !== id);
    setImages(filteredImages);
    setNewImages(filteredNewImages);
  };

  const handleClose = () => {
    setOpenSlider(false);
  };

  const handleUpdate = () => {
    const newImagesValues = newImages.map((image) => ({
      id: images.length + 1,
      src: image.src,
    }));
    setData({
      ...data,
      SubSliders: [...images, ...newImagesValues],
    });
    setOpenSlider(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        open={openSlider}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update Slider Images"}</DialogTitle>
        <DialogContent>
          <div className="changing-slider-container">
            {images.map((image, index) => (
              <div className="changing-slider" key={image.id}>
                <div className="changing-slider-inputs">
                  <img
                    className="changing-slider-image"
                    src={image.src}
                    alt={`Image ${index + 1}`}
                  />
                  <input
                    className="changing-slider-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(image.id, e)}
                  />
                </div>
                <div
                  className="changing-description-button"
                  onClick={() => deleteImage(image.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            {newImages.map((newImage) => (
              <div className="changing-slider" key={newImage.id}>
                <div className="changing-slider-inputs">
                  {!newImage.src && (
                    <img
                      className="changing-slider-preview"
                      src="https://st3.depositphotos.com/23594922/31822/v/450/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg"
                      alt="no-image"
                    />
                  )}
                  {newImage.src && (
                    <img
                      className="changing-slider-preview"
                      src={newImage.src}
                      alt="Preview"
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(newImage.id, e)}
                  />
                </div>
                <div
                  className="changing-description-button"
                  onClick={() => deleteImage(newImage.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <button
              className="changing-description-add-button"
              onClick={addNewImageInput}
            >
              Add New
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-slider-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="changing-slider-update" onClick={handleUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeFAQModal({ data, setData, openFAQ, setOpenFAQ }) {
  const [currentFAQs, setCurrentFAQs] = useState(data.Faqs);
  const [newFAQs, setNewFAQs] = useState([]);

  const updateFAQ = (index, updatedFAQ) => {
    const updatedFAQs = [...currentFAQs];
    updatedFAQs[index] = updatedFAQ;
    setCurrentFAQs(updatedFAQs);
  };

  const addNewFAQInput = () => {
    const newFAQInput = {
      id: parseInt(currentFAQs.length) + parseInt(newFAQs.length + 1),
      title: "",
      content: "",
    };
    setNewFAQs([...newFAQs, newFAQInput]);
  };

  const updateNewFAQ = (id, field, value) => {
    const updatedNewFAQs = newFAQs.map((faq) =>
      faq.id === id ? { ...faq, [field]: value } : faq
    );
    setNewFAQs(updatedNewFAQs);
  };

  const deleteFAQ = (id) => {
    const filteredFAQs = currentFAQs.filter((faq) => faq.id !== id);
    const filteredNewFAQs = newFAQs.filter((faq) => faq.id !== id);
    setCurrentFAQs(filteredFAQs);
    setNewFAQs(filteredNewFAQs);
  };

  const handleClose = () => {
    setOpenFAQ(false);
  };

  const handleUpdate = () => {
    const newFAQsValues = newFAQs.map((faq) => ({
      id: currentFAQs.length + 1,
      title: faq.title,
      content: faq.content,
    }));

    setData({ ...data, Faqs: [...currentFAQs, ...newFAQsValues] });
    setOpenFAQ(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openFAQ}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your FAQs"}</DialogTitle>
        <DialogContent>
          <div className="changing-faq-container">
            {currentFAQs.map((faq, index) => (
              <div className="changing-faq" key={faq.id}>
                <div className="changing-faq-content">
                  <div className="changing-faq-inputs">
                    <input
                      className="changing-description-input"
                      type="text"
                      placeholder="Enter FAQ title"
                      value={faq.title}
                      onChange={(e) =>
                        updateFAQ(index, {
                          id: faq.id,
                          title: e.target.value,
                          content: faq.content,
                        })
                      }
                    />
                    <textarea
                      className="changing-description-textarea"
                      placeholder="Enter FAQ description"
                      value={faq.content}
                      onChange={(e) =>
                        updateFAQ(index, {
                          id: faq.id,
                          title: faq.title,
                          content: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div
                    className="changing-description-button"
                    onClick={() => deleteFAQ(faq.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))}
            {newFAQs.map((newFAQ) => (
              <div className="changing-faq" key={newFAQ.id}>
                <div className="changing-faq-content">
                  <div className="changing-faq-inputs">
                    <input
                      className="changing-description-input"
                      type="text"
                      placeholder="Enter new FAQ title"
                      value={newFAQ.title}
                      onChange={(e) =>
                        updateNewFAQ(newFAQ.id, "title", e.target.value)
                      }
                    />
                    <textarea
                      className="changing-description-textarea"
                      placeholder="Enter new FAQ description"
                      value={newFAQ.content}
                      onChange={(e) =>
                        updateNewFAQ(newFAQ.id, "content", e.target.value)
                      }
                    ></textarea>
                  </div>
                  <div
                    className="changing-description-button"
                    onClick={() => deleteFAQ(newFAQ.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))}
            <button
              className="changing-description-add-button"
              onClick={addNewFAQInput}
            >
              Add New
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeCardModal({ data, setData, openModal, setOpenModal }) {
  const [currentItems, setCurrentItems] = useState(data.ThreeTitles);
  const [newItems, setNewItems] = useState([]);

  const updateItem = (index, updatedItem) => {
    const updatedItems = [...currentItems];
    updatedItems[index] = updatedItem;
    setCurrentItems(updatedItems);
  };

  const addNewItemInput = () => {
    const newItemInput = {
      id: parseInt(currentItems.length) + parseInt(newItems.length + 1),
      src: "",
      title: "",
      text: "",
    };
    setNewItems([...newItems, newItemInput]);
  };

  const updateNewItem = (id, field, value) => {
    const updatedNewItems = newItems.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    setNewItems(updatedNewItems);
  };

  const handleImageChange = async (file) => {
    try {
      const imageFormData = new FormData();
      imageFormData.append("photo", file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: "zesrxdyctuutydrszrestdrxy",
        },
      };
      const url = "https://cdn.ecombd.org/upload";
      const response = await axios.post(url, imageFormData, config);
      toast.success("Image uploaded to CDN");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleFileChange = async (id, event) => {
    try {
      const imageUrl = await handleImageChange(event.target.files[0]);

      const updatedCurrentItems = currentItems.map((item) =>
        item.id === id ? { ...item, src: imageUrl } : item
      );

      const updatedNewItems = newItems.map((item) =>
        item.id === id ? { ...item, src: imageUrl } : item
      );

      setCurrentItems(updatedCurrentItems);
      setNewItems(updatedNewItems);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteItem = (id) => {
    const filteredItems = currentItems.filter((item) => item.id !== id);
    const filteredNewItems = newItems.filter((item) => item.id !== id);
    setCurrentItems(filteredItems);
    setNewItems(filteredNewItems);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleUpdate = () => {
    const newItemsValues = newItems.map((item) => ({
      id: currentItems.length + 1,
      src: item.src,
      title: item.title,
      text: item.text,
    }));
    setData({ ...data, ThreeTitles: [...currentItems, ...newItemsValues] });
    setOpenModal(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Items"}</DialogTitle>
        <DialogContent>
          <div className="changing-item-container">
            {currentItems.map((item, index) => (
              <div className="changing-item" key={item.id}>
                <div className="changing-slider-inputs">
                  <img
                    className="changing-item-image"
                    src={item.src}
                    alt={`Image ${index + 1}`}
                  />
                  <input
                    className="changing-item-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(item.id, e)}
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter item title"
                    value={item.title}
                    onChange={(e) =>
                      updateItem(index, {
                        id: item.id,
                        src: item.src,
                        title: e.target.value,
                        text: item.text,
                      })
                    }
                  />
                  <textarea
                    className="changing-description-textarea"
                    placeholder="Enter item text"
                    value={item.text}
                    onChange={(e) =>
                      updateItem(index, {
                        id: item.id,
                        src: item.src,
                        title: item.title,
                        text: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div
                  className="changing-description-button"
                  onClick={() => deleteItem(item.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            {newItems.map((newItem) => (
              <div className="changing-item" key={newItem.id}>
                <div className="changing-slider-inputs">
                  <img
                    className="changing-item-image"
                    src={newItem.src || "https://via.placeholder.com/150"}
                    alt={`Image ${newItem.id}`}
                  />
                  <input
                    className="changing-item-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(newItem.id, e)}
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter new item title"
                    value={newItem.title}
                    onChange={(e) =>
                      updateNewItem(newItem.id, "title", e.target.value)
                    }
                  />
                  <textarea
                    className="changing-description-textarea"
                    placeholder="Enter new item text"
                    value={newItem.text}
                    onChange={(e) =>
                      updateNewItem(newItem.id, "text", e.target.value)
                    }
                  ></textarea>
                </div>
                <div
                  className="changing-description-button"
                  onClick={() => deleteItem(newItem.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <button
              className="changing-description-add-button"
              onClick={addNewItemInput}
            >
              Add New
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-item-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="changing-item-update" onClick={handleUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeVideoDataModal({
  data,
  setData,
  openVideoData,
  setOpenVideoData,
}) {
  const [title, setTitle] = useState(data.video.mainTitle);
  const [videoURL, setVideoURL] = useState(data.video.videoURL);
  const [subTitle, setSubtitle] = useState(data.video.subTitle);

  const handleClose = () => {
    setOpenVideoData(false);
  };

  const handleUpdate = () => {
    setData({
      ...data,
      video: {
        ...data.video,
        mainTitle: title,
        videoURL: videoURL,
        subTitle: subTitle,
      },
    });
    setOpenVideoData(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openVideoData}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Title"}</DialogTitle>
        <DialogContent>
          <div className="changing-description-container">
            <div className="changing-description">
              <input
                className="changing-description-input"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="changing-description">
              <input
                className="changing-description-input"
                type="text"
                value={videoURL}
                onChange={(e) => setVideoURL(e.target.value)}
              />
            </div>
            <div className="changing-description">
              <textarea
                className="changing-description-textarea"
                type="text"
                value={subTitle}
                onChange={(e) => setSubtitle(e.target.value)}
              ></textarea>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeLandingTwoFAQModal({
  data,
  setData,
  openFAQ,
  setOpenFAQ,
}) {
  const [currentFAQs, setCurrentFAQs] = useState(data.Faqs);
  const [newFAQs, setNewFAQs] = useState([]);

  const updateFAQ = (index, updatedFAQ) => {
    const updatedFAQs = [...currentFAQs];
    updatedFAQs[index] = updatedFAQ;
    setCurrentFAQs(updatedFAQs);
  };

  const addNewFAQInput = () => {
    const newFAQInput = {
      id: parseInt(currentFAQs.length) + parseInt(newFAQs.length + 1),
      title: "",
      content: "",
    };
    setNewFAQs([...newFAQs, newFAQInput]);
  };

  const updateNewFAQ = (id, field, value) => {
    const updatedNewFAQs = newFAQs.map((faq) =>
      faq.id === id ? { ...faq, [field]: value } : faq
    );
    setNewFAQs(updatedNewFAQs);
  };

  const deleteFAQ = (id) => {
    const filteredFAQs = currentFAQs.filter((faq) => faq.id !== id);
    const filteredNewFAQs = newFAQs.filter((faq) => faq.id !== id);
    setCurrentFAQs(filteredFAQs);
    setNewFAQs(filteredNewFAQs);
  };

  const handleClose = () => {
    setOpenFAQ(false);
  };

  const handleUpdate = () => {
    const newFAQsValues = newFAQs.map((faq) => ({
      id: currentFAQs.length + 1,
      title: faq.title,
      content: faq.content,
    }));

    setData({ ...data, Faqs: [...currentFAQs, ...newFAQsValues] });
    setOpenFAQ(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openFAQ}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your FAQs"}</DialogTitle>
        <DialogContent>
          <div className="changing-faq-container">
            {currentFAQs.map((faq, index) => (
              <div className="changing-faq" key={faq.id}>
                <div className="changing-faq-content">
                  <div className="changing-faq-inputs">
                    <input
                      className="changing-description-input"
                      type="text"
                      placeholder="Enter FAQ title"
                      value={faq.title}
                      onChange={(e) =>
                        updateFAQ(index, {
                          id: faq.id,
                          title: e.target.value,
                          content: faq.content,
                        })
                      }
                    />
                    <textarea
                      className="changing-description-textarea"
                      placeholder="Enter FAQ description"
                      value={faq.content}
                      onChange={(e) =>
                        updateFAQ(index, {
                          id: faq.id,
                          title: faq.title,
                          content: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div
                    className="changing-description-button"
                    onClick={() => deleteFAQ(faq.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))}
            {newFAQs.map((newFAQ) => (
              <div className="changing-faq" key={newFAQ.id}>
                <div className="changing-faq-content">
                  <div className="changing-faq-inputs">
                    <input
                      className="changing-description-input"
                      type="text"
                      placeholder="Enter new FAQ title"
                      value={newFAQ.title}
                      onChange={(e) =>
                        updateNewFAQ(newFAQ.id, "title", e.target.value)
                      }
                    />
                    <textarea
                      className="changing-description-textarea"
                      placeholder="Enter new FAQ description"
                      value={newFAQ.content}
                      onChange={(e) =>
                        updateNewFAQ(newFAQ.id, "content", e.target.value)
                      }
                    ></textarea>
                  </div>
                  <div
                    className="changing-description-button"
                    onClick={() => deleteFAQ(newFAQ.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))}
            <button
              className="changing-description-add-button"
              onClick={addNewFAQInput}
            >
              Add New
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-description-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="changing-description-update"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeLandingTwoCardModal({
  data,
  setData,
  openModal,
  setOpenModal,
}) {
  const [currentItems, setCurrentItems] = useState(data.courses);
  const [newItems, setNewItems] = useState([]);

  const updateItem = (index, updatedItem) => {
    const updatedItems = [...currentItems];
    updatedItems[index] = updatedItem;
    setCurrentItems(updatedItems);
  };

  const addNewItemInput = () => {
    const newItemInput = {
      id: parseInt(currentItems.length) + parseInt(newItems.length + 1),
      src: "",
      title: "",
      price: "",
      delivery_charge: "",
    };
    setCurrentItems([...currentItems, newItemInput]);
  };

  const updateNewItem = (id, field, value) => {
    const updatedNewItems = newItems.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    setNewItems(updatedNewItems);
  };

  const handleImageChange = async (file) => {
    try {
      const imageFormData = new FormData();
      imageFormData.append("photo", file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: "zesrxdyctuutydrszrestdrxy",
        },
      };
      const url = "https://cdn.ecombd.org/upload";
      const response = await axios.post(url, imageFormData, config);
      toast.success("Image uploaded to CDN");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleFileChange = async (id, event) => {
    try {
      const imageUrl = await handleImageChange(event.target.files[0]);

      const updatedCurrentItems = currentItems.map((item) =>
        item.id === id ? { ...item, src: imageUrl } : item
      );

      const updatedNewItems = newItems.map((item) =>
        item.id === id ? { ...item, src: imageUrl } : item
      );

      setCurrentItems(updatedCurrentItems);
      setNewItems(updatedNewItems);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteItem = (id) => {
    const filteredItems = currentItems.filter((item) => item.id !== id);
    const filteredNewItems = newItems.filter((item) => item.id !== id);
    setCurrentItems(filteredItems);
    setNewItems(filteredNewItems);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleUpdate = () => {
    const newItemsValues = newItems.map((item) => ({
      id: currentItems.length + 1,
      src: item.src,
      title: item.title,
      price: item.price,
      delivery_charge: item.delivery_charge,
    }));
    setData({ ...data, courses: [...currentItems, ...newItemsValues] });
    setOpenModal(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Items"}</DialogTitle>
        <DialogContent>
          <div className="changing-item-container">
            {currentItems.map((item, index) => (
              <div className="changing-item" key={item.id}>
                {console.log("image", item.src)}
                <div className="changing-slider-inputs">
                  <img
                    className="changing-item-image"
                    src={item.src}
                    alt={`Image ${index + 1}`}
                  />
                  <input
                    className="changing-item-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(item.id, e)}
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter item title"
                    value={item.title}
                    onChange={(e) =>
                      updateItem(index, {
                        id: item.id,
                        src: item.src,
                        title: e.target.value,
                        price: item.price,
                        delivery_charge: item.delivery_charge,
                      })
                    }
                  />
                  <input
                    className="changing-description-input"
                    placeholder="Enter item price"
                    value={item.price}
                    onChange={(e) =>
                      updateItem(index, {
                        id: item.id,
                        src: item.src,
                        title: item.title,
                        price: e.target.value,
                        delivery_charge: item.delivery_charge,
                      })
                    }
                  />

                  <textarea
                    className="changing-description-textarea"
                    placeholder="Enter item price"
                    value={item.delivery_charge}
                    onChange={(e) =>
                      updateItem(index, {
                        id: item.id,
                        src: item.src,
                        title: item.title,
                        price: item.price,
                        delivery_charge: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div
                  className="changing-description-button"
                  onClick={() => deleteItem(item.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            {newItems.map((newItem) => (
              <div className="changing-item" key={newItem.id}>
                <div className="changing-slider-inputs">
                  <img
                    className="changing-item-image"
                    src={newItem.src || "https://via.placeholder.com/150"}
                    alt={`Image ${newItem.id}`}
                  />
                  <input
                    className="changing-item-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(newItem.id, e)}
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter new item title"
                    value={newItem.title}
                    onChange={(e) =>
                      updateNewItem(newItem.id, "title", e.target.value)
                    }
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter new item price"
                    value={newItem.price}
                    onChange={(e) =>
                      updateNewItem(newItem.id, "price", e.target.value)
                    }
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter new item delivery charge"
                    value={newItem.title}
                    onChange={(e) =>
                      updateNewItem(
                        newItem.id,
                        "delivery_charge",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div
                  className="changing-description-button"
                  onClick={() => deleteItem(newItem.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <button
              className="changing-description-add-button"
              onClick={addNewItemInput}
            >
              Add New
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-item-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="changing-item-update" onClick={handleUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangeReviewImageLandingTwoModal({
  data,
  setData,
  open,
  setOpen,
  updatedImage,
  setUpdatedImage,
}) {
  const handleClose = () => {
    setUpdatedImage(null);
    setOpen(false);
  };

  async function handleImageChange(event) {
    const image = new FormData();
    image.append("photo", event?.target?.files[0]);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload-normal";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Image uploaded to CDN");
        setUpdatedImage(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleUpdate = () => {
    setData({ ...data, ReviewImage: updatedImage });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Review Image"}</DialogTitle>
        <DialogContent>
          <input id="image-upload" type="file" onChange={handleImageChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function VaritionModal({ data, setData, openModal, setOpenModal }) {
  const [currentItems, setCurrentItems] = useState(data.Variations);
  const [newItems, setNewItems] = useState([]);

  const updateItem = (index, updatedItem) => {
    const updatedItems = [...currentItems];
    updatedItems[index] = updatedItem;
    setCurrentItems(updatedItems);
  };

  const addNewItemInput = () => {
    const newItemInput = {
      id: parseInt(currentItems.length) + parseInt(newItems.length + 1),
      src: "",
      color: "",
      price: "",
    };
    setNewItems([...newItems, newItemInput]);
  };

  const updateNewItem = (id, field, value) => {
    const updatedNewItems = newItems.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    setNewItems(updatedNewItems);
  };

  const handleImageChange = async (file) => {
    try {
      const imageFormData = new FormData();
      imageFormData.append("photo", file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: "zesrxdyctuutydrszrestdrxy",
        },
      };
      const url = "https://cdn.ecombd.org/upload";
      const response = await axios.post(url, imageFormData, config);
      toast.success("Image uploaded to CDN");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleFileChange = async (id, event) => {
    try {
      const imageUrl = await handleImageChange(event.target.files[0]);

      const updatedCurrentItems = currentItems.map((item) =>
        item.id === id ? { ...item, src: imageUrl } : item
      );

      const updatedNewItems = newItems.map((item) =>
        item.id === id ? { ...item, src: imageUrl } : item
      );

      setCurrentItems(updatedCurrentItems);
      setNewItems(updatedNewItems);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteItem = (id) => {
    const filteredItems = currentItems.filter((item) => item.id !== id);
    const filteredNewItems = newItems.filter((item) => item.id !== id);
    setCurrentItems(filteredItems);
    setNewItems(filteredNewItems);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleUpdate = () => {
    const newItemsValues = newItems.map((item) => ({
      id: currentItems.length + 1,
      src: item.src,
      title: item.title,
      text: item.text,
    }));
    setData({ ...data, Variations: [...currentItems, ...newItemsValues] });
    setOpenModal(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update your Items"}</DialogTitle>
        <DialogContent>
          <div className="changing-item-container">
            {currentItems.map((item, index) => (
              <div className="changing-item" key={item.id}>
                <div className="changing-slider-inputs">
                  <img
                    className="changing-item-image"
                    src={item.src}
                    alt={`Image ${index + 1}`}
                  />
                  <input
                    className="changing-item-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(item.id, e)}
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter item color"
                    value={item.color}
                    onChange={(e) =>
                      updateItem(index, {
                        id: item.id,
                        src: item.src,
                        color: e.target.value,
                        price: item.price,
                      })
                    }
                  />
                  <input
                    className="changing-description-input"
                    placeholder="Enter item pirce"
                    value={item.price}
                    onChange={(e) =>
                      updateItem(index, {
                        id: item.id,
                        src: item.src,
                        color: item.color,
                        price: e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  className="changing-description-button"
                  onClick={() => deleteItem(item.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            {newItems.map((newItem) => (
              <div className="changing-item" key={newItem.id}>
                <div className="changing-slider-inputs">
                  <img
                    className="changing-item-image"
                    src={newItem.src || "https://via.placeholder.com/150"}
                    alt={`Image ${newItem.id}`}
                  />
                  <input
                    className="changing-item-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(newItem.id, e)}
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter new item color"
                    value={newItem.color}
                    onChange={(e) =>
                      updateNewItem(newItem.id, "color", e.target.value)
                    }
                  />
                  <input
                    className="changing-description-input"
                    type="text"
                    placeholder="Enter new item price"
                    value={newItem.color}
                    onChange={(e) =>
                      updateNewItem(newItem.id, "price", e.target.value)
                    }
                  />
                </div>
                <div
                  className="changing-description-button"
                  onClick={() => deleteItem(newItem.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            <button
              className="changing-description-add-button"
              onClick={addNewItemInput}
            >
              Add New
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="changing-item-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="changing-item-update" onClick={handleUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function ChangePageName({ data, setData, open, setOpen }) {
  const [name, setName] = useState(data.Name);
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setData({ ...data, Name: name });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <form onSubmit={handleUpdate}>
          <DialogTitle>{"Update your Page Name"}</DialogTitle>
          <DialogContent>
            <input
              className="changing-description-input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your page name"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
