import axios from "axios";

const UploadImageCdn = async (image) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      token: "zesrxdyctuutydrszrestdrxy",
    },
  };
  const url = "https://cdn.ecombd.org/upload/desc";
  const res = await axios.post(url, image, config);
  return res.data;
};

export default UploadImageCdn;
