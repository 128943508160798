import React from "react";

export const Input = ({
  label,
  id,
  name,
  value,
  onChange,
  placeholder,
  pattern,
  title,
}) => {
  return (
    <div className="form__input__group">
      <label htmlFor="name">
        {label} <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        pattern={pattern}
        title={title}
        required
      />
    </div>
  );
};
