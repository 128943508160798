import React, { useState, useEffect } from "react";
import "./AssignModal.css";
import myAxios from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Modal({ setAssignOpen, data, setRefresh, setSelectedItems }) {
  const uid = localStorage.getItem("id");
  const [loading, setLoading] = useState(false);
  const [SelecetUser, setSelecetUser] = useState();
  const [Assign, setAssignId] = useState();

  const ReAssign = () => {
    setLoading(true);
    setRefresh(false);
    const url = `re-assign/${uid}`;
    myAxios
      .post(url, { Assign, data })
      .then((res) => {
        setLoading(false);
        setAssignOpen(false);
        setRefresh(true);
        setSelectedItems([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const url = `get-team/${uid}`;
    myAxios
      .get(url)
      .then((res) => {
        setSelecetUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="modalBackgroundB">
      <ToastContainer />
      <div className="modalContainerB">
        <div className="titleCloseBtnB">
          <button onClick={() => setAssignOpen(false)}>X</button>
        </div>
        <p>
          Please select new team members below and click on the submit button
        </p>

        {SelecetUser && (
          <select
            name="status"
            className="orderEditStatus"
            onChange={(e) => setAssignId(e.target.value)}
            value={Assign}
          >
            <option value="">Select Member</option>
            {SelecetUser.map((data) => {
              return (
                <>
                  <option value={data._id}>{data.name}</option>
                </>
              );
            })}
          </select>
        )}
        <div className="footerB">
          <button
            onClick={() => {
              setAssignOpen(false);
            }}
            id="cancelBtnB"
          >
            Cancel
          </button>
          <button onClick={ReAssign}>{loading ? "Loading.." : "Submit"}</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
