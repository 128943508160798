import React, { useState, useEffect, useRef } from "react";
import "./DNS.css";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditDnsModal from "../DnsModal/EditDnsModal";
import DnsModal from "../DnsModal/DnsModal";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

function DNS() {
  const uid = localStorage.getItem("id");
  const [dns, setDns] = useState([]);
  const [edtiOpenModal, setEditOpenModal] = useState(false);
  const [openDns, setOpenDnsModal] = useState(false);
  const [edit, setEdit] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  const tableContainerRef = useRef(null);
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    if (dns.length > 0) {
      const hasScrolled = localStorage.getItem("hasScrolledDns");
      if (!hasScrolled) {
        let direction = 1;
        const scrollInterval = setInterval(() => {
          setScrollValue((prev) => {
            let newValue = prev + direction * 1;
            if (newValue >= 100) {
              direction = -1;
            } else if (newValue <= 0) {
              clearInterval(scrollInterval);
              localStorage.setItem("hasScrolledDns", "true");
            }
            return newValue;
          });
        }, 20);

        return () => clearInterval(scrollInterval);
      }
    }
  }, [dns]);

  useEffect(() => {
    if (dns.length > 0 && tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        left: tableContainerRef.current.scrollWidth * (scrollValue / 100),
        behavior: "smooth",
      });
    }
  }, [scrollValue]);

  useEffect(() => {
    if (!edtiOpenModal && !openDns) {
      getDns();
    }
  }, [refresh, edtiOpenModal, openDns]);

  const getDns = () => {
    setLoading(true);
    AxiosUrl.get(`/get-dns/${uid}`)
      .then((res) => {
        setDns(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const DeleteDns = (data) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this DNS record?"
    );
    if (confirmed) {
      AxiosUrl.post(`/delete-dns/${uid}`, {
        id: data._id,
        uid,
        dnsName: data.Domain,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data === "DNS record deleted successfully") {
            toast.success("Deleted successfully.");
            setRefresh((prev) => prev + 1);
          } else {
            toast.success(res.data);
          }
        })
        .catch(() => {
          toast.error("Failed to delete the DNS record.");
        });
    }
  };

  const handleSliderChange = (event, newValue) => {
    setScrollValue(newValue);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        left: tableContainerRef.current.scrollWidth * (newValue / 100),
        behavior: "smooth",
      });
    }
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <ToastContainer />
      {edtiOpenModal && (
        <EditDnsModal
          setEditOpenModal={setEditOpenModal}
          dnsRecord={edit}
          open={edtiOpenModal}
          setRefresh={() => setRefresh((prev) => prev + 1)}
        />
      )}
      {openDns && (
        <DnsModal
          setOpenDnsModal={setOpenDnsModal}
          open={openDns}
          setRefresh={() => setRefresh((prev) => prev + 1)}
        />
      )}
      <div className="dns-main">
        <div className="dns-video">
          <div>
            DNS কিভাবে কাজ করে দেখুন ➡️{" "}
            <a href="#" target="_blank">
              VIDEO LINK
            </a>{" "}
          </div>
        </div>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.36) 0px 0px 4px 0px",
            borderRadius: 10,
            overflow: "hidden",
            marginBottom: 10,
          }}
        >
          <div className="dns-create">
            <Button
              variant="contained"
              onClick={() => setOpenDnsModal(true)}
              style={{ fontSize: 13, fontWeight: "bold" }}
            >
              Add DNS Record
            </Button>
          </div>

          <div className="dns-slider-container">
            <p>
              টেবিল টি ডান দিকে বা বাম দিকে দেখতে নিচের স্লাইড টি ডানে বা বামে
              টান দিন{" "}
            </p>
            <Slider
              value={scrollValue}
              aria-label="Scroll"
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
              step={1}
              min={0}
              max={100}
            />
          </div>

          <TableContainer
            component={Paper}
            style={{
              borderRadius: 0,
              overflowX: "auto",
            }}
            ref={tableContainerRef}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" width={150}>
                    Domain
                  </TableCell>
                  <TableCell align="center" width={150}>
                    Type
                  </TableCell>
                  <TableCell align="center" width={70}>
                    Is Local
                  </TableCell>
                  <TableCell align="center" width={250}>
                    Records
                  </TableCell>

                  <TableCell align="center" width={70}>
                    Edit
                  </TableCell>
                  <TableCell align="right" width={80}>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <ReactLoading
                        type="cylon"
                        color="rgb(226,115,29)"
                        height={60}
                        width={60}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && dns.length <= 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <div className="dns-no-data">No data available</div>
                    </TableCell>
                  </TableRow>
                ) : (
                  dns.map((data) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      }}
                      key={data._id}
                    >
                      <TableCell align="left" component="th" scope="row">
                        {data.Domain}
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ fontSize: 13, fontWeight: "bold" }}
                      >
                        {data.Type}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor:
                              data.status === 1
                                ? "rgb(147, 46, 93)"
                                : "#275727",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {data.IsLocal ? "Yes" : "No"}
                        </Button>
                      </TableCell>

                      <TableCell align="center" component="th" scope="row">
                        {data.Record.map((record, index) => (
                          <p key={index} style={{ fontSize: "12px" }}>
                            {record}
                          </p>
                        ))}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor: "#275727",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                          onClick={() => {
                            setEdit(data);
                            setEditOpenModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: data.IsLocal ? "#ccc" : "#9b2a2a",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            cursor: data.IsLocal ? "not-allowed" : "pointer",
                          }}
                          onClick={() => !data.IsLocal && DeleteDns(data)}
                          disabled={data.IsLocal}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="dns-slider-container">
            <Slider
              value={scrollValue}
              aria-label="Scroll"
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
              step={1}
              min={0}
              max={100}
            />
            <p>
              টেবিল টি ডান দিকে বা বাম দিকে দেখতে উপরের স্লাইড টি ডানে বা বামে
              টান দিন{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DNS;
