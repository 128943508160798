import React, { useState } from "react";
import Accordion from "../utils/Accordion";
import { ChangeFAQModal } from "../../LandingPageModals/LandingPageModals";

export const Questions = ({ data, setData }) => {
  const [openFAQ, setOpenFAQ] = useState(false);
  return (
    <div className="questions">
      <div className="container">
        <div className="questions__container">
          <button
            className="landing-page-edit-btn"
            onClick={() => setOpenFAQ(true)}
          >
            Edit
          </button>
          <h1>সচরাচর জিজ্ঞাসিত প্রশ্ন</h1>
          <Accordion items={data.Faqs} />
        </div>
      </div>
      <ChangeFAQModal
        data={data}
        setData={setData}
        openFAQ={openFAQ}
        setOpenFAQ={setOpenFAQ}
      />
    </div>
  );
};
