import React from "react";
import { TextCard } from "../utils/Cards";

const EffectivenessCards = ({ data }) => {
  return (
    <div className="effectiveness__card">
      <div className="container">
        <div className="effectiveness__card__container">
          {data.ThreeTitles.length > 0 &&
            data.ThreeTitles.map((item) => (
              <TextCard key={item.id} item={item} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default EffectivenessCards;
