import React from "react";
import "./Accounts.css";
function Accounts() {
  return (
    <>
      <div className="Business-Video">
        <div>
          How dose it work ➡️{" "}
          <a href="#" target="_blank">
            Video Links
          </a>{" "}
        </div>
      </div>
      <div className="Business">
        <div className="Business-Head">
          <h1>Business Investment</h1>
        </div>
        <div className="Investor">
          <div className="Investor-Button">
            <button>Add Investor</button>
          </div>

          <div className="InvestorList">
            <div className="InvestorAmountList">Date</div>
            <div className="InvestorAmountList">Name</div>
            <div className="InvestorAmountList">Amount</div>
            <div className="InvestorAmountList removeBorder">Edit / Delete</div>
          </div>
          <div className="InvestorAmount">
            <div className="InvestorAmountList">2/4/2023</div>
            <div className="InvestorAmountList">Ruhul Amin</div>
            <div className="InvestorAmountList">৳2000000</div>
            <div className="InvestorAmountList removeBorder">
              <button className="action-button">Edit</button>
              <button className="action-button-del">Delete</button>
            </div>
          </div>
          <div className="InvestorAmount">
            <div className="InvestorAmountList">2/4/2023</div>
            <div className="InvestorAmountList">Dr Farid Uddin</div>
            <div className="InvestorAmountList">৳2000000</div>
            <div className="InvestorAmountList removeBorder">
              <button className="action-button">Edit</button>
              <button className="action-button-del">Delete</button>
            </div>
          </div>
          <div className="InvestorList">
            <div className="InvestorAmountList">Business Investment</div>
            <div className="InvestorAmountList">Total</div>
            <div className="InvestorAmountList">৳4000000</div>
            <div className="InvestorAmountList removeBorder">
              Edit or Delete
            </div>
          </div>
        </div>
      </div>

      <div className="Business">
        <div className="Business-Head">
          <h1>Fixed Asset</h1>
        </div>
        <div className="Investor">
          <div className="Investor-Button">
            <button>Add Fixed Asset</button>
          </div>

          <div className="InvestorList">
            <div className="InvestorAmountList">Date</div>
            <div className="InvestorAmountList">Name</div>
            <div className="InvestorAmountList">Amount</div>
            <div className="InvestorAmountList removeBorder">Edit / Delete</div>
          </div>
          <div className="InvestorAmount">
            <div className="InvestorAmountList">2/4/2023</div>
            <div className="InvestorAmountList">Purchase Server</div>
            <div className="InvestorAmountList">৳500000</div>
            <div className="InvestorAmountList removeBorder">
              <button className="action-button">Edit</button>
              <button className="action-button-del">Delete</button>
            </div>
          </div>
          <div className="InvestorAmount">
            <div className="InvestorAmountList">2/4/2023</div>
            <div className="InvestorAmountList">Office Advance</div>
            <div className="InvestorAmountList">৳100000</div>
            <div className="InvestorAmountList removeBorder">
              <button className="action-button">Edit</button>
              <button className="action-button-del">Delete</button>
            </div>
          </div>
          <div className="InvestorList">
            <div className="InvestorAmountList">Fixed Asset</div>
            <div className="InvestorAmountList">Total</div>
            <div className="InvestorAmountList">৳4000000</div>
            <div className="InvestorAmountList removeBorder">
              Edit or Delete
            </div>
          </div>
        </div>
      </div>
      <div className="Business">
        <div className="Business-Head-Current">
          <h1>Current Assets</h1>
        </div>
        <div className="Investor">
          <div className="InvestorList">
            <div className="InvestorAmountList">Fixed Asset</div>
            <div className="InvestorAmountList">Inventory</div>
            <div className="InvestorAmountList">Available</div>
          </div>
          <div className="InvestorAmount">
            <div className="InvestorAmountList">৳3253647</div>
            <div className="InvestorAmountList">৳3575688</div>
            <div className="InvestorAmountList">৳500000</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Accounts;
