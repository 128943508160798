import React, { useState, useEffect } from "react";
import "./BillingModal.css";

import BkashCreatePaymentAPICall from "../../action/BkashCreatePaymentAPICall";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import RuSu from "../../img/rusu.jpg";
import "react-toastify/dist/ReactToastify.css";

function BillingModal({ setOpenModal, data }) {
  console.log(data);
  const uid = localStorage.getItem("id");
  const [loading, setLoading] = useState(false);
  const [bkash, setBkash] = useState("");

  const subscribe = async () => {
    AxiosUrl.post(`/pay-bill/${uid}`, {
      data: data,
      bkash: bkash,
    })
      .then((res) => {
        BkashCreatePaymentAPICall(res.data.Amount, res.data.UID, res.data.UID);
        setOpenModal(false);
      })
      .catch((error) => console.log(error.response.data));
  };

  return (
    <div className="modalBackgroundB">
      <ToastContainer />
      <div className="modalContainerB">
        <div className="titleCloseBtnB">
          <button onClick={() => setOpenModal(false)}>X</button>
        </div>
        <img height="100" width={100} src={RuSu} alt="RuSu" />
        <p>
          RUSU BD (RUSU) বিকাশ মার্চেন্ট থেকে {data.package} টাকা চার্জ করা হবে,
          নিচে "PayBkash" বাটন এ ক্লিক করে অর্ডার টি কনফার্ম করুন ধন্যবাদ
        </p>

        <div className="footerB">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtnB"
          >
            Cancel
          </button>
          <button onClick={subscribe}>
            {loading ? "Loading.." : "PayBkash"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default BillingModal;
