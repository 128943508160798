const data = {
  Name: "",
  uid: "123",
  Title: "ইমপোর্টেড ব্যাগ",
  Landing: 1,
  ProductCode: "১০৮",
  Price: "3,690",
  Buying: "3,260",
  ComparePrice: "ComparePrice",
  DeliveryOption: "সারা বাংলাদেশে ডেলিভারি চার্জ সম্পূর্ণ ফ্রী !!",
  Reviews: {
    point: 4.5,
    number: 21,
    orders: 72,
  },
  HeroImage:
    "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",

  SubSliders: [
    {
      id: 1,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
    },
    {
      id: 2,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
    },
    {
      id: 3,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-white.webp",
    },
    {
      id: 4,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
    },
    {
      id: 5,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
    },
  ],
  BulletPoints: [
    {
      id: 1,
      text: "আকৃতিঃ ৩১ x ১৮ x ২৩ সে.মি।",
    },
    {
      id: 2,
      text: "উপাদানঃ পলিউরেথেন চামড়া এবং পলিয়েস্টার।",
    },
    {
      id: 3,
      text: "রঙঃ লাল।",
    },
    {
      id: 4,
      text: "ভেতরের অংশঃ দুটি মুল অংশ এবং চারটি সিক্রেট পকেট।",
    },
    {
      id: 5,
      text: "বন্ধের উপাদানঃ জিপার।",
    },
    {
      id: 6,
      text: "বন্ধের উপাদানঃ জিপার।",
    },
    {
      id: 7,
      text: "ওজনঃ ১.২ কেজি।",
    },
    {
      id: 8,
      text: "বিশেষ বৈশিষ্ট্যঃ পানি প্রতিরোধক।",
    },
  ],
  Features: [
    {
      id: 1,
      text: "সৌন্দর্যবর্ধক উপকরণ হিসেবে লেডিস ব্যাগ তালিকার সবচেয়ে উপরের উপকরণ। কারণ এটি শুধু সৌন্দর্য বাড়ায় না এর উপযোগিতা যথেষ্ট গুরুত্বপূর্ণ। এটির ব্যবহার সময়ের সাথে বেড়েই চলেছে। আর সেই সাথে যুক্ত হয়েছে বাহারি রং, নকশা আর ডিজাইন।",
    },

    {
      id: 2,
      text: "সকলে তার পছন্দ অনুযায়ী বেছে নিচ্ছে প্রিয় ব্যাগটি। তবে ব্যাগ পছন্দের পূর্বে ব্যাগটির কিছু বিশেষ বৈশিষ্ট্যের প্রতি নজর দেওয়া প্রয়োজন। এসবের মধ্যে রয়েছে ব্যাগটির গুনগত মান, উপযোগিতা, মূল্য ইত্যাদি। এসব বিষয় বিবেচনা করে ব্যাগ নির্বাচন করা প্রয়োজন।",
    },

    {
      id: 3,
      text: "আর এই নির্বাচনের সুবিধার্থে একটি ব্যাগের কিছু বৈশিষ্ট্য নিয়ে আলোচনা করা হয়েছে। আশা করছি এটি আপনাকে ব্যাগটি বেছে নিতে যথেষ্ট সাহায্য করবে।",
    },
  ],

  ProsTitle:
    "ঊপরের ব্যাগটির বৈশিষ্ট্য দেখেই আন্দাজ করা যায় ব্যাগটি অন্যান্য ব্যাগের তুলনায় অনেক আলাদা। তাই এর এই আলাদা হওয়ায় পেছনে যে সুবিধা রয়েছে তা নিয়ে আলোচনা করাই বাহুল্য। নিচে ব্যাগটির কিছু উল্লেখযোগ্য সুবিধা নিয়ে আলোচনা করা হলঃ",
  Pros: [
    {
      id: 1,
      text: "ব্যাগটি যথেষ্ট বড় এবং এতে প্রয়োজনীয় সকল কিছু খুব সহজেই ধরে যায়। চাইলেই অন্যান্য জিনিসের সাথে খুব সহজেই ২-৩ টি মোবাইল ফোন নেওয়া সম্ভব। এমনকি একটি ট্যাবলেটও নেওয়া সম্ভব। যেহেতু ব্যাগটিতে চারটি জিপার রয়েছে। এছাড়াও ছোটখাটো সকল কিছুই নিশ্চিন্তে নেওয়া সম্ভব।",
    },

    {
      id: 2,
      text: "ব্যাগটিতে রয়েছে অতিরিক্ত স্ট্র্যাপ ব্যাবহারের সুবিধা। যা অনেক জিনিস নিয়ে বহনে সাহায্য করে। এতে থাকা ম্যাগনেটিক লক সিস্টেমর মাধ্যমে খুব সহজেই লাগিয়ে নেওয়া সম্ভব। এছাড়াও ব্যবহারকারী যদি এটি না লাগাতে চান তাহলে এটি খুলেও রাখতে পারেন।",
    },

    {
      id: 3,
      text: "ব্যাগটির ডিজাইনের জন্য যে কোন বয়সের মানুষ যে কোন জায়গায় ব্যাগটি ব্যবহার করতে পারে। এটি যে কোন জায়গায় মানিয়ে নিতে সক্ষম।",
    },
    {
      id: 4,
      text: "ব্যাগটির ভেতরের পকেটের ডিজাইন বর্তমান যুগের প্রয়োজনীয় জিনিসের কথা মাথায় রেখেই বানানো হয়েছে। যেহেতু ব্যাগটি মহিলারা ব্যবহার করবে তাই তাদের জন্য মেকআপ প্রসাধনী রাখার জন্য আলাদা পকেট রয়েছে।",
    },
  ],
  Cons: [
    {
      id: 1,
      text: "ব্যাগটি পানি প্রতিরোধক নয়। তাই চাইলেই ব্যাগটি যেকোনো ঋতুতে ব্যবহার করা যাবে না। তাই এটি অনেকের অসুবিধার কারণ হতে পারে।",
    },

    { id: 2, text: "অতিরিক্ত স্ট্র্যাপ বিরক্তির কারণ হতে পারে মাঝে মাঝে।" },
  ],

  ThreeTitles: [
    {
      id: 1,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
      title: "ডিজাইন:",
      text: "ব্যাগটির লক্ষণীয় বিষয় হল এর কাঁচামাল এবং কোয়ালিটি। এটিতে কাঁচামাল হিসেবে ব্যবহৃত হয়েছে পলিউরেথেন চামড়া এবং পলিয়েস্টার। এই ধরনের উপকরণ একটি ব্যাগের জন্য উপযোগী। আর এজন্যই এটি সকলের কাছে গ্রহণযোগ্যতাও বাড়িয়েছে।",
    },
    {
      id: 2,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-white.webp",
      title: "গ্রহণযোগ্য উপযোগিতা:",
      text: "এর পরেই নজরে পড়বে ব্যাগটির রং। ব্যাগটি যদিও কেবল লাল রঙে পাওয়া যাবে তবুও এটি যথেষ্ট আকর্ষণীয় এবং মানানসই। এটি যেকোনো পরিবেশের সাথেই মানিয়ে নিতে সক্ষম।",
    },
    {
      id: 3,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
      title: "ক্ষয় এবং মরিচা প্রতিরোধক:",
      text: "ব্যাগটি সকল ঋতুতে ব্যবহার করা যাবে। কারণ ব্যাগটি পানি প্রতিরোধক। বৃষ্টির পানিতেও এটি থাকবে শুকনো। যা ব্যাগটির উপযোগিতা অনেকাংশে বাড়িয়ে তোলে।",
    },
  ],
  Faqs: [
    {
      id: 1,
      title: "ব্যাগটি কি সকল ঋতুতে ব্যবহার করা যাবে?",
      content:
        "না। ব্যাগটি সকল ঋতুতে ব্যবহার করা যাবে না। যেহেতু ব্যাগটির কাঁচামালে কোন বৃষ্টি প্রতিরোধক উপাদান নেই তাই এটি সতর্কতার সাথে ব্যবহার করতে হবে।",
    },
    {
      id: 2,
      title: "ব্যাগটি কি সময় উপযোগী?",
      content:
        "ব্যাগটি নিঃসন্দেহে সময় উপযোগী। যে কোন মানুষ যে কোন প্রয়োজনে ব্যাগটি ব্যবহার করা যাবে। ব্যাগটির নান্দনিকতা ও রুচিশীলতা এক্ষেত্রে ভুমিকা রাখছে। এজন্যই ব্যাগটির সকল ক্ষেত্রেই উপযোগী ও টেকসই।",
    },
    {
      id: 3,
      title: "নিত্যদিনের ব্যবহারে ব্যাগটি কি উপযোগী?",
      content:
        "অবশ্যই নিত্যদিনের ব্যবহারে ব্যাগটি শতভাগ উপযোগী। এর মানানসই ও নান্দনিক ডিজাইন যেকোনো পরিবেশের সাথে খাপ খাইয়ে নিতে সক্ষম। এজন্যই ব্যবহারকারী এটিকে তার নিজের প্রয়োজনে নিত্যদিনের সঙ্গী হিসেবে ব্যবহার করতে পারে। এর অনন্য ও আকর্ষণীয় ডিজাইন সৌন্দর্যবর্ধনেও যথেষ্ট ভুমিকা রাখতে পারে।",
    },
  ],
  Decisions: [
    {
      id: 1,
      text: "অনেকেই হয়ত ব্যাগটির মূল্য নিয়ে শঙ্কিত। কিন্তু ব্যাগটিতে ব্যবহার করা হয়েছে উন্নতমানের উপকরণ। এছাড়াও ব্যাগটির বাহ্যিক সৌন্দর্য যথেষ্ট আকর্ষণীয়। মূলত একটি ব্যাগ নির্বাচনের ক্ষেত্রে ব্যাগটির বৈশিষ্ট্য ও উপযোগিতার প্রতি বেশী নজর দেওয়া প্রয়োজন।",
    },

    {
      id: 2,
      text: "আর এজন্যই লেডিস ব্যাগটি এইসব বিষয়ের কথা মাথায় রেখেই বানানো হয়েছে। এটির ডিজাইন এবং গুনগত মান উন্নত এজন্য এটির গ্রহণযোগ্যতা সকলের কাছেই সমান থাকবে। নানান নামী দামি ব্রান্ডের সাথেও এটি খুব সহজেই তাল মেলাতে পারবে।",
    },

    {
      id: 3,
      text: "ব্যাগটিকে নিত্যদিনের সঙ্গী হিসেবেই পরিচিত। তাই তো এত কিছু দেখে এবং যাচাই করে সকলে বাছাই করেন। ব্যাগটির বৈশিষ্ট্য সম্পর্কে জ্ঞান থাকা খুবই গুরুত্বপূর্ণ। এজন্যই সকল বিষয় বিচার করে তবেই নির্বাচন করা উচিত।",
    },
  ],
  Variations: [
    {
      id: 1,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
      price: 300,
      color: "white",
    },
    {
      id: 2,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
      price: 200,
      color: "red",
    },
    {
      id: 3,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
      price: 3400,
      color: "green",
    },
    {
      id: 4,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
      price: 700,
      color: "blue",
    },
  ],
  DeliveryCharge: {
    insideDhaka: 70,
    outsideDhaka: 130,
  },
};

export default data;
