import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cartcontext } from "../../context/Context";
import myAxios from "../AxiosUrl";
import "./CheckOut.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CheckOut() {
  const navigate = useNavigate();
  const Globalstate = useContext(Cartcontext);
  const [loading, setLoading] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState("");
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;

  const totalPrice = state.reduce((total, item) => {
    return total + item.proceSell * item.setQuantity;
  }, 0);

  let orderData = [];
  let profitArry = [];
  state.map((id) => {
    let totalProfit = 0;
    totalProfit = id.proceSell - id.priceBuy;
    totalProfit *= id.setQuantity;
    profitArry.push(totalProfit);
    orderData.push({
      id: id._id,
      qit: id.setQuantity,
      imgeUrl: id.imgeUrl,
      name: id.productName,
    });
  });

  let profit = 0;
  profitArry.map((data) => {
    profit += data;
  });

  console.log("profit", profit);

  const item = state.length ? state.length : 0;
  const userId = "ysertudfyigoiturdestxc";
  const [cName, setcName] = useState("");
  const [cMobile, setcMobile] = useState("");
  const [address, setAddress] = useState("");
  const [product, setproduct] = useState("");
  const [advance, setAdvance] = useState("");
  const [privateNote, setPrivateNote] = useState("");

  const submitH = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!cName || !cMobile || !address || !deliveryCharge) {
      toast.error("Please provide Name, Mobile, Address, Delivery location");
      setLoading(false);
      return;
    }

    const url = `create-order`;
    const formData = {
      cName,
      cMobile,
      address,
      userId,
      advance,
      totalPrice,
      item,
      orderData,
      product,
      privateNote,
      deliveryCharge,
      profit,
    };
    myAxios
      .post(url, formData)
      .then((res) => {
        setcName("");
        setcMobile("");
        setAddress("");
        setproduct("");
        dispatch({ type: "RESET", payload: [] });
        setLoading(false);
        toast.success(res.data.message);
        navigate("/create-order");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <ToastContainer />
      <div className="add-product">
        <div className="add-sub">
          <h3>CREATE ORDER</h3>
          <div className="image-qnt-main-flex">
            {state.map((data, index) => {
              return (
                <div className="img-p" key={index}>
                  <img
                    height={"70px"}
                    width={"70px"}
                    src={data.imgeUrl}
                    alt="product"
                  />
                  <p>{`${data.setQuantity} pcs`}</p>
                </div>
              );
            })}
          </div>

          <div className="delivery-main-flex">
            <div className="delivery-in">
              <p>Delivery Location</p>
              <button
                className={
                  deliveryCharge === "dhaka"
                    ? "button-select"
                    : "delivery-button"
                }
                onClick={() => setDeliveryCharge("dhaka")}
              >
                Inside Dhaka
              </button>
              <button
                className={
                  deliveryCharge === "outside"
                    ? "button-select"
                    : "delivery-button"
                }
                onClick={() => setDeliveryCharge("outside")}
              >
                Outside Dhaka
              </button>
            </div>
          </div>
          {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
          <input
            onChange={(e) => {
              setcName(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Name"
            required=""
            value={cName}
          />
          <input
            onChange={(e) => {
              setcMobile(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Mobile Number"
            required=""
            value={cMobile}
          />
          <input
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Address"
            required=""
            value={address}
          />
          <input
            onChange={(e) => {
              setproduct(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Product:"
            required=""
            value={product}
          />
          <input
            onChange={(e) => {
              setPrivateNote(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Private Note: Optional"
            required=""
            value={privateNote}
          />
          <input
            onChange={(e) => {
              setAdvance(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Advance: Optional"
            required=""
            value={advance}
          />

          <button onClick={submitH} className="sub-button">
            {loading ? "Loading.." : "Complete Order"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CheckOut;
