import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import Logo from "../../img/logo.png";
import ReactPixel from "react-facebook-pixel";

function Login() {
  const [signup, setSignup] = useState(false);
  const [login, setLogin] = useState(true);

  const [forgot, setForgot] = useState(false);
  const [forgotVerify, setForgotVerify] = useState(false);
  const [VerifyDone, setVerifyDone] = useState(false);

  const [SignupVerify, setSignupVerify] = useState(false);
  const [SignupLoading, setSignupLoading] = useState(false);

  const [LoginLoading, setLoginLoading] = useState(false);
  const [lMobile, setLMobile] = useState();
  const [lpass, setLpass] = useState();

  const [mobile, setMobile] = useState();
  const [password, setPassword] = useState();
  const [username, setUserName] = useState();
  const [user_id, setUserID] = useState();
  const [forgotNumber, setForgotNumber] = useState();
  const [VerifyCode, setVerifyCode] = useState();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoginLoading(true);
    axios
      .post(`${URL}login`, { lMobile, lpass })
      .then((res) => {
        setLoginLoading(false);
        if (res.data.message === "Password not match") {
          toast.error("Password not match");
        }
        if (res.data.message === "User not found") {
          toast.error("User not found..");
        }
        if (res.data.refreshToken) {
          localStorage.setItem("token", res.data.refreshToken);
          localStorage.setItem("id", res.data.UserId);
          localStorage.setItem("IsSuper", res.data.IsSuper);
          localStorage.setItem("IsTeam", res.data.IsTeam);

          if (res.data.Role === 0) {
            window.location.reload("/dashboard");
          }
          if (res.data.Role === 1) {
            console.log(res.data.Role);
            window.location.reload("/super-admin-dashboard");
          }
          if (res.data.Role === 2) {
            window.location.reload("/dashboard");
          }
        }
      })
      .catch((err) => toast.error(err.response.data));
  };

  const handleSignup = async () => {
    setSignupLoading(true);

    if (!mobile || !password || !username) {
      toast.error("mobile, username, password, Required. ");
      setSignupLoading(false);
      return;
    }
    if (mobile.length < 11 || mobile.length > 11) {
      toast.error("১১ সংখ্যার মোবাইল নম্বর লিখুন");
      setSignupLoading(false);
      return;
    }
    axios
      .post(`${URL}signup`, { mobile, password, username })
      .then((res) => {
        setSignupLoading(false);
        if (res.data === "User already exists") {
          toast.error("User already exists");
        }
        if (res.data.refreshToken) {
          // setSignup(false);
          // setSignupVerify(true);
          localStorage.setItem("token", res.data.refreshToken);
          localStorage.setItem("id", res.data.UserId);
          localStorage.setItem("IsSuper", res.data.IsSuper);
          localStorage.setItem("IsTeam", res.data.IsTeam);
          // Otp_Send(res.data.UserId);
          // setUserID(res.data.UserId);
          window.location.reload("/dashboard");
        }
      })
      .catch((err) => toast.error(err.response.data));
  };

  const handleForgot = async (number) => {
    axios
      .post(`${URL}forgot/mobile`, { number })
      .then((res) => {
        if (res.data.otpStatus == true) {
          setSignupVerify(true);
          setUserID(res.data.userId);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => toast.error(err.response.data));
  };

  const Otp_Send = async (uid) => {
    axios
      .post(`${URL}otp/generate/${uid}`)
      .then((res) => {
        if (res.data === "OTP Success") {
          toast.success("OTP Success");
        }
      })
      .catch((err) => toast.error(err.response.data));
  };

  const Otp_Verify = async (uid, otp) => {
    axios
      .post(`${URL}otp/validate`, { userId: uid, otp })
      .then((res) => {
        if (res.data === "OTP is valid") {
          window.location.reload("/dashboard");
        }
        if (res.data === "OTP not found") {
          toast.error("OTP not found");
        }
        if (res.data === "OTP has expired") {
          toast.error("OTP has expired");
        }
        if (res.data === "OTP is invalid") {
          toast.error("OTP is invalid");
        }
        setLoginLoading(false);
      })
      .catch((err) => toast.error(err.response.data));
  };

  useEffect(() => {
    let is_running = false;

    if (is_running == true) {
      return;
    }

    is_running = true;
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init("6874141362695006", options);
    ReactPixel.pageView();
    is_running = false;
    console.log("FacebookPixel: 6874141362695006");
  }, []);

  return (
    <div className="login-container">
      <ToastContainer />
      {/* header section start */}
      <div className="header-login">
        <div className="logo-main">
          <a href="https://ecomtechbd.com">
            <img src={Logo} width={150} />
          </a>
        </div>
        <div className="login-main">
          <ul>
            <li>SIGNUP</li>
            <li>LOGIN</li>
          </ul>
        </div>
      </div>
      {/* header section end */}

      {/* login and signup section start */}
      <div className="login-signup-main">
        <div className="login-or-signup">
          <div className="login-signup-tab">
            <div
              className={login ? "login-tab-active" : "login-tab"}
              onClick={() => {
                setSignup(false);
                setLogin(true);
                setForgot(false);
                setForgotVerify(false);
                setVerifyDone(false);
                setSignupVerify(false);
              }}
            >
              LogIn
            </div>
            <div
              className={signup ? "signup-tab-active" : "signup-tab"}
              onClick={() => {
                setSignup(true);
                setLogin(false);
                setForgot(false);
                setForgotVerify(false);
                setVerifyDone(false);
                setSignupVerify(false);
              }}
            >
              Signup
            </div>
          </div>
          {login && (
            <div className="login-inputs">
              <input
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setLMobile(e.target.value)}
                value={lMobile}
              />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setLpass(e.target.value)}
                value={lpass}
              />
              <button className="login-btn" onClick={() => handleLogin()}>
                {LoginLoading ? "Loading.." : "LOG IN"}
              </button>
              <p
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => {
                  setForgotVerify(false);
                  setForgot(true);
                  setSignup(false);
                  setLogin(false);
                  setVerifyDone(false);
                }}
              >
                Forgot Password?
              </p>
            </div>
          )}
          {signup && (
            <div className="signup-inputs">
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setUserName(e.target.value)}
              />
              <input
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setMobile(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="login-btn"
                onClick={() => {
                  handleSignup();
                }}
              >
                {SignupLoading ? "Loading.." : "SIGN UP"}
              </button>
            </div>
          )}
          {forgot && (
            <div className="signup-inputs">
              <input
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setForgotNumber(e.target.value)}
              />
              <button
                className="login-btn"
                onClick={() => {
                  handleForgot(forgotNumber);
                  setForgot(false);
                  setSignup(false);
                  setLogin(false);
                  setVerifyDone(false);
                }}
              >
                FORGOT PASSWORD
              </button>
            </div>
          )}
          {SignupVerify && (
            <div className="signup-inputs">
              <p
                style={{
                  textAlign: "center",
                  border: "1px solid #ccc",
                  padding: "7px",
                }}
              >
                আপনার মোবাইলে একটি ভেরিফিকেসন কোড পাঠানো হয়েছে কোডটি নিচে লিখুন{" "}
              </p>
              <input
                type="number"
                placeholder="Verification Code"
                onChange={(e) => setVerifyCode(e.target.value)}
              />
              <button
                className="login-btn"
                onClick={() => {
                  Otp_Verify(user_id, VerifyCode);
                  setLoginLoading(true);
                }}
              >
                {LoginLoading ? "Loading.." : "Verify-Code"}
              </button>
              <p
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => {
                  setSignup(false);
                  setLogin(false);
                }}
              >
                Code-Not-Receive? Resend
              </p>
            </div>
          )}
          {forgotVerify && (
            <div className="signup-inputs">
              <p
                style={{
                  textAlign: "center",
                  border: "1px solid #ccc",
                  padding: "7px",
                }}
              >
                আপনার মোবাইলে একটি ভেরিফিকেসন কোড পাঠানো হয়েছে কোডটি নিচে লিখুন{" "}
              </p>
              <input type="number" placeholder="Verification Code" />
              <button
                className="login-btn"
                onClick={() => {
                  setForgotVerify(false);
                  setForgot(false);
                  setSignup(false);
                  setLogin(false);
                  setVerifyDone(true);
                }}
              >
                Verify-Code
              </button>
              <p
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => {
                  setForgotVerify(false);
                  setForgot(true);
                  setSignup(false);
                  setLogin(false);
                }}
              >
                Code-Not-Receive? Resend
              </p>
            </div>
          )}
          {VerifyDone && (
            <div className="signup-inputs">
              <input type="number" placeholder="Set New Password" />
              <button className="login-btn">Set New Password</button>
            </div>
          )}
        </div>
      </div>
      {/* login and signup section end */}
    </div>
  );
}

export default Login;
