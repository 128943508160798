import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosUrl from "../../../AxiosUrl";
import "./BkashNagadRocket.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #00000038",
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (min-width: 600px)": {
    width: 400,
  },
};

function BkashNagadRocketModal({ setBNRModal, setRefresh, Refresh, Data }) {
  const [Bkash, setBkash] = useState(Data?.Bkash || "");
  const [Nagad, setNagad] = useState(Data?.Nagad || "");
  const [Rocket, setRocket] = useState(Data?.Rocket || "");
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const fromData = {
    uid,
    Bkash,
    Nagad,
    Rocket,
  };

  const submitDomain = () => {
    setLoading(true);
    if (!Bkash && !Nagad && !Rocket) {
      setLoading(false);
      return toast.error("At least one field is required");
    }

    AxiosUrl.post(`/update-domain-new/${uid}`, fromData)
      .then(() => {
        setRefresh(Refresh + 1);
        setBNRModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={setBNRModal}
      onClose={() => setBNRModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box sx={style}>
        <ToastContainer />
        <div className="titleCloseBtn" style={{ textAlign: "right" }}>
          <Button onClick={() => setBNRModal(false)} style={{ color: "red" }}>
            X
          </Button>
        </div>

        <div className="title">
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <TextField
              placeholder="Bkash Number"
              fullWidth
              label="Bkash Number"
              onChange={(e) => setBkash(e.target.value)}
              value={Bkash}
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Nagad Number"
              fullWidth
              label="Nagad Number"
              onChange={(e) => setNagad(e.target.value)}
              value={Nagad}
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Rocket Number"
              fullWidth
              label="Rocket Number"
              onChange={(e) => setRocket(e.target.value)}
              value={Rocket}
            />
          </Box>
        </div>

        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button
            onClick={() => setBNRModal(false)}
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button onClick={submitDomain} variant="contained" disabled={loading}>
            {loading ? "Loading.." : "Continue"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default BkashNagadRocketModal;
