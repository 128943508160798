import React, { useState } from "react";
import "./ExpenseModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";

function ExpenseModal({ setOpenAddModal, setRefresh, refresh }) {
  const [Title, setTitle] = useState("");
  const [Amount, setAmount] = useState();
  const [ExpenseId, setExpenseId] = useState();
  const [Description, setDescription] = useState("");
  const [loading, setloading] = useState(false);
  const [ExpDate, setExpDate] = useState(new Date());
  const uid = localStorage.getItem("id");
  const fromData = {
    uid,
    Title,
    Amount,
    ExpenseId,
    Description,
    ExpDate,
  };

  const SubmitExpenseModal = () => {
    if (ExpDate > new Date()) {
      toast.error("You can set previous date but can't set any future date..");
      return;
    }
    setloading(true);
    if (!Title || !Amount || !ExpenseId || !Description) {
      toast.error("Title, Amount, ExpenseId, Description is Required..");
      return setloading(false);
    }

    AxiosUrl.post(`/add-expense`, fromData)
      .then((res) => {
        setOpenAddModal(false);
        setRefresh(refresh + 1);
        setloading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <div className="ExpenseBG">
      <ToastContainer />
      <div className="ExpenseModalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenAddModal(false);
              setRefresh(refresh + 1);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "15px" }}>
            <TextField
              fullWidth
              label="Expense Title"
              id="fullWidth"
              onChange={(e) => setTitle(e.target.value)}
              value={Title}
              placeholder="Example: Pay for internet bill"
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "15px" }}>
            <TextField
              type="number"
              fullWidth
              label="Expense Amount"
              id="fullWidth"
              onChange={(e) => setAmount(e.target.value)}
              value={Amount}
              placeholder="Example: Amount of the internet bill *Must be number"
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "15px" }}>
            <TextField
              fullWidth
              label="Expense ID"
              id="fullWidth"
              onChange={(e) => setExpenseId(e.target.value)}
              value={ExpenseId}
              placeholder="Example: Invoice number of the bill"
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              fullWidth
              label="Description"
              id="fullWidth"
              onChange={(e) => setDescription(e.target.value)}
              value={Description}
              placeholder="Example: Details of the bill that you've paid"
            />
          </Box>
          <p>
            if you want to set any previous date then select from the below by
            default it will pick today date
          </p>
          <div>
            <DatePicker
              selected={ExpDate}
              onChange={(date) => setExpDate(date)}
            />
          </div>
        </div>

        <div className="footer">
          <button
            onClick={() => {
              setOpenAddModal(false);
              setRefresh(refresh + 1);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              SubmitExpenseModal();
            }}
          >
            {loading ? "Loading.." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExpenseModal;
