import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "./HeaderMain.css";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "../../img/avatar.png";
import myAxios from "../AxiosUrl";
import AxiosUrl from "../AxiosUrl";
import ReactPixel from "react-facebook-pixel";

function HeaderMain() {
  const location = useLocation();
  const navigate = useNavigate();
  const navRef = useRef();
  const uid = localStorage.getItem("id");
  const CreateStore = localStorage.getItem("CreateStore");

  const [store, setStore] = useState();
  const [IsTrial, setIsTrial] = useState();
  const [DaysLeft, setDaysLeft] = useState();
  const [PayBill, setPayBill] = useState();
  const [VisitorLeft, setVisitorLeft] = useState();
  const [unlimited, setUnlimited] = useState();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  useEffect(() => {
    if (CreateStore == "false") {
      navigate("/new-store");
    }
  }, [location.pathname]);

  useEffect(() => {
    let is_running = false;

    if (is_running == true) {
      return;
    }

    is_running = true;
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init("6874141362695006", options);
    ReactPixel.pageView();
    is_running = false;
    console.log("FacebookPixel", "6874141362695006");
  }, []);

  useEffect(() => {
    AxiosUrl.get(`/get-subscription/${uid}`)
      .then((res) => {
        setVisitorLeft(res.data.VisitorLeft);
        setIsTrial(res.data.IsTrial);
        setDaysLeft(res.data.DaysLeft);
        setPayBill(res.data.PayBill);
        setUnlimited(res.data.Unlimited);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!IsTrial && DaysLeft <= 0) {
      navigate("/billing");
    }
    if (!IsTrial && DaysLeft <= 0 && PayBill) {
      navigate("/billing");
    }

    if (unlimited === "0" && VisitorLeft <= 0) {
      navigate("/billing");
    }
  }, [location.pathname]);

  useEffect(() => {
    myAxios
      .post(`/store/${uid}`)
      .then((res) => {
        setStore(res.data);
      })
      .catch((err) => {
        if (err.response.data === "Token is not valid") {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          setIsAuthenticate(false);
          setOpen(false);
          navigate("/");
        }
      });
  }, [uid]);

  useEffect(() => {
    myAxios
      .get(`/password-expire/${uid}`)
      .then((res) => {
        if (res.data === "NoToken") {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          setIsAuthenticate(false);
          setOpen(false);
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, [location.pathname]);

  const [isAuthenticated, setIsAuthenticate] = useState(
    localStorage.getItem("token") ? true : false
  );

  const [open, setOpen] = useState(false);

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("IsSuper");
    localStorage.removeItem("IsTeam");
    setIsAuthenticate(false);
    setOpen(false);
  };

  return (
    <>
      <header>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <h2 className="h-logo">{store?.domain ? store.domain : "Admin"}</h2>
        </Link>
        <nav ref={navRef}>
          <Link
            to="/dashboard"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Dashboard</a>
          </Link>
          <Link
            to="/product"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Product</a>
          </Link>
          <Link
            to="/category"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Category</a>
          </Link>
          <Link
            to="/special-category"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>HomeCategory</a>
          </Link>
          <Link
            to="/expense"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Expense</a>
          </Link>
          {/* <Link
            to="/purchase"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Purchase</a>
          </Link> */}

          {/* <Link
            to="/landing"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>LandingPage</a>
          </Link> */}
          <Link
            to="/theme"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Colors</a>
          </Link>
          <Link
            to="/dns-list"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>DNS</a>
          </Link>
          <Link
            to="/new-store"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Store</a>
          </Link>
          <Link
            to="/banner-list"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Slider</a>
          </Link>
          <Link
            to="/landing-list"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>LandingPage</a>
          </Link>
          <Link
            to="/users"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Customer</a>
          </Link>

          <Link
            to="/create-list"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Order</a>
          </Link>
          <Link
            to="/team"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Team</a>
          </Link>
          {/* <Link
            to="/accounts"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Accounts</a>
          </Link> */}
          <Link
            to="/billing"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Billing</a>
          </Link>

          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={logoutHandler}
          >
            <a>Logout</a>
          </Link>
          <div
            className="avatar"
            onClick={() => {
              showNavbar();
              navigate("/profile");
            }}
          >
            <img src={Avatar} height={40} width={40} />
          </div>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>

      {!IsTrial && DaysLeft <= 7 && DaysLeft > 0 && (
        <div className="notification-header">
          <Link
            to="/billing"
            style={{ textDecoration: "none", color: "bisque" }}
          >
            <p>{DaysLeft} days left to Deactivate pay bill </p>
          </Link>
        </div>
      )}

      {!IsTrial && DaysLeft <= 0 && PayBill ? (
        <div className="notification-header">
          <Link
            to="/billing"
            style={{ textDecoration: "none", color: "bisque" }}
          >
            <p>Account Was Deactivated - Pay bill to reactivate</p>
          </Link>
        </div>
      ) : (
        ""
      )}

      {unlimited === "0" && VisitorLeft <= 0 ? (
        <div className="notification-header">
          <Link
            to="/billing"
            style={{ textDecoration: "none", color: "bisque" }}
          >
            <p>No visitor available</p>
          </Link>
        </div>
      ) : (
        ""
      )}

      {IsTrial && (
        <div className="notification-header">
          <Link
            to="/billing"
            style={{ textDecoration: "none", color: "bisque" }}
          >
            <p>Trial Activated for 7 Days</p>
          </Link>
        </div>
      )}
    </>
  );
}

export default HeaderMain;
