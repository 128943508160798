import React, { useState, useEffect, useRef } from "react";
import { Whammy } from "../../whammy/whammy.js"; // Adjust the path as neededimport ReactPlayer from "react-player";
import myAxios from "../AxiosUrl";
import Draw from "./Draw.js";
import ReactPlayer from "react-player";
function VisitorVideo() {
  const [videoBlob, setVideoBlob] = useState();
  const [videoList, setVideoList] = useState();
  const uid = localStorage.getItem("id");
  const canvasRef = useRef(null);

  useEffect(() => {
    myAxios
      .get(`/get-video-list/${uid}`)
      .then((res) => {
        setVideoList(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, []);

  const handleCreateVideo = (VideoData) => {
    const videoArray = VideoData.data.map((data) => data.imageUrl);
    console.log(videoArray);
    setVideoBlob(videoArray);
    ImageArrayAnimation(videoArray);
  };
  function ImageArrayAnimation(imageArray) {
    const canvasRef = useRef(null);
    let currentFrameIndex = 0;
    let animationFrameId = null;
    const drawFrame = () => {
      if (!canvasRef.current) return;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const img = new Image();
      img.src = imageArray[currentFrameIndex];

      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        currentFrameIndex = (currentFrameIndex + 1) % imageArray.length;
        animationFrameId = requestAnimationFrame(drawFrame);
      };
    };
    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      canvas.width = 800; // Set canvas width and height accordingly
      canvas.height = 600;
      drawFrame();

      return () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
      };
    }, [imageArray]);
  }
  return (
    <div>
      <ul style={{ color: "#fff" }}>
        {videoList &&
          videoList.map((data) => {
            return <li onClick={() => handleCreateVideo(data)}>{data._id}</li>;
          })}
      </ul>
      <div>
        <h1>Image Array Video</h1>
        <canvas ref={canvasRef} />;
      </div>
    </div>
  );
}

export default VisitorVideo;
