import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosUrl from "../../../AxiosUrl";
import "./PathaoCourierAPI.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #00000038",
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (min-width: 600px)": {
    width: 400,
  },
};

function PathaoCourierAPIModal({
  setPathaoApiModal,
  setRefresh,
  Refresh,
  Data,
}) {
  const [PathaoAPIKey, setPathaoAPIKey] = useState(Data?.PathaoAPIKey || "");
  const [PathaoSecret, setPathaoSecret] = useState(Data?.PathaoSecret || "");
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const fromData = {
    uid,
    PathaoAPIKey,
    PathaoSecret,
  };

  const submitDomain = () => {
    setLoading(true);
    if (!PathaoAPIKey || !PathaoSecret) {
      setLoading(false);
      return toast.error("All fields are required");
    }

    AxiosUrl.post(`/update-domain-new/${uid}`, fromData)
      .then(() => {
        setRefresh(Refresh + 1);
        setPathaoApiModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={setPathaoApiModal}
      onClose={() => setPathaoApiModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box sx={style}>
        <ToastContainer />
        <div className="titleCloseBtn" style={{ textAlign: "right" }}>
          <Button
            onClick={() => setPathaoApiModal(false)}
            style={{ color: "red" }}
          >
            X
          </Button>
        </div>

        <div className="title">
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <TextField
              placeholder="Pathao Api Key"
              fullWidth
              label="Pathao Api Key"
              onChange={(e) => setPathaoAPIKey(e.target.value)}
              value={PathaoAPIKey}
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Pathao Api Secret"
              fullWidth
              label="Pathao Api Secret"
              onChange={(e) => setPathaoSecret(e.target.value)}
              value={PathaoSecret}
            />
          </Box>
        </div>

        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button
            onClick={() => setPathaoApiModal(false)}
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button onClick={submitDomain} variant="contained" disabled={loading}>
            {loading ? "Loading.." : "Continue"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default PathaoCourierAPIModal;
