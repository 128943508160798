import React from "react";
import VideoPlayer from "../utils/VideoPlayer";

const Video = ({ data }) => {
  return (
    <div className="container">
      <div className="video__container">
        <h2>{data.video.mainTitle}</h2>
        <VideoPlayer url={data.video.videoURL} />
        <h2>{data.video.subTitle}</h2>
        <button>এখানে অর্ডার করুন</button>
      </div>
    </div>
  );
};

export default Video;
