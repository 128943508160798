import React from "react";
import "./Purchase.css";
function Purchase() {
  return (
    <div>
      <div className="Purchase-Video">
        <div>
          How dose it work ➡️{" "}
          <a href="#" target="_blank">
            Video Link
          </a>{" "}
        </div>
      </div>
    </div>
  );
}

export default Purchase;
