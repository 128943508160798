import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
const AccordionItem = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="accordion-item">
      <div onClick={toggleAccordion}>
        <div className="accordion-header">
          <span className="icon">{isExpanded ? <FaMinus /> : <FaPlus />}</span>
          <span className="title">{title}</span>
        </div>
      </div>
      <p className={`accordion-content ${isExpanded ? "open" : "close"}`}>
        {content}
      </p>
    </div>
  );
};

export default AccordionItem;
