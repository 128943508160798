import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import Logo from "../../img/logo.png";

function ForgotPassword() {
  const [state, setState] = useState({
    forgotVerify: false,
    forgot: true,
    user_id: null,
    forgotNumber: "",
    VerifyCode: "",
    GetPass: null,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleForgot = async () => {
    if (!state.forgotNumber) {
      toast.error("Number required.");
      return;
    }

    try {
      setLoading(true);
      const res = await axios.post(`${URL}forgot/mobile`, {
        number: state.forgotNumber,
      });

      if (res.data.otpStatus) {
        setState({
          ...state,
          user_id: res.data.userId,
          forgot: false,
          forgotVerify: true,
        });
        toast.success("OTP sent successfully!");
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const Otp_Verify = async () => {
    if (!state.VerifyCode) {
      toast.error("Code required.");
      return;
    }

    try {
      setLoading(true);
      const res = await axios.post(`${URL}otp/validate`, {
        userId: state.user_id,
        otp: state.VerifyCode,
      });

      if (res.data.message === "OTP is valid") {
        setState({ ...state, GetPass: res.data.pass });
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.response.data);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyPassword = () => {
    if (state.GetPass) {
      navigator.clipboard.writeText(state.GetPass).then(() => {
        toast.success("Password copied to clipboard!");
      });
    }
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="header-login">
        <div className="logo-main">
          <a href="https://ecomtechbd.com">
            <img src={Logo} width={150} alt="Logo" />
          </a>
        </div>
        <div className="login-main">
          <ul>
            <li onClick={() => navigate("/")}>LOGIN</li>
          </ul>
        </div>
      </div>

      <div className="login-signup-main">
        <div className="login-or-signup">
          <div className="login-signup-tab">
            <div
              style={{
                backgroundColor: "#207fc7",
                width: "100%",
                textAlign: "center",
                color: "#fff",
                padding: "10px",
              }}
            >
              FORGOT PASSWORD
            </div>
          </div>

          {state.forgot && (
            <div className="signup-inputs">
              <input
                type="number"
                name="forgotNumber"
                placeholder="Mobile Number"
                onChange={handleChange}
                disabled={loading}
              />
              <button
                className="login-btn"
                onClick={handleForgot}
                disabled={loading}
              >
                {loading ? "Processing..." : "FORGOT PASSWORD"}
              </button>
              <p
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                  color: "#207fc7",
                  textDecoration: "underline",
                }}
                onClick={() => navigate("/")}
              >
                Back to Login
              </p>
            </div>
          )}

          {state.forgotVerify && (
            <div className="signup-inputs">
              {state.GetPass ? (
                <>
                  <p
                    style={{
                      textAlign: "center",
                      border: "1px solid #ccc",
                      padding: "7px",
                      cursor: "pointer",
                      backgroundColor: "#e0e0e0",
                      color: "#000",
                    }}
                    onClick={handleCopyPassword}
                    title="Click to copy"
                  >
                    আপনার নতুন পাসওয়ার্ড: {state.GetPass} {"<== Click to Copy"}
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      border: "1px solid #ccc",
                      padding: "7px",
                      cursor: "pointer",
                      backgroundColor: "#225cb7",
                      color: "#fff",
                    }}
                    onClick={() => navigate("/")}
                  >
                    LOGIN
                  </p>
                </>
              ) : (
                <>
                  <p
                    style={{
                      textAlign: "center",
                      border: "1px solid #ccc",
                      padding: "7px",
                    }}
                  >
                    আপনার মোবাইলে একটি ভেরিফিকেসন কোড পাঠানো হয়েছে কোডটি নিচে
                    লিখুন{" "}
                  </p>
                  <input
                    type="number"
                    name="VerifyCode"
                    placeholder="Verification Code"
                    onChange={handleChange}
                    disabled={loading}
                  />
                  <button
                    className="login-btn"
                    onClick={Otp_Verify}
                    disabled={loading}
                  >
                    {loading ? "Verifying..." : "Verify-Code"}
                  </button>
                  <p
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={() =>
                      setState({ ...state, forgot: true, forgotVerify: false })
                    }
                  >
                    Code-Not-Receive? Resend
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
