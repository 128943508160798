import React, { useState, useEffect, useRef } from "react";
import "./Category.css";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import SubCategoryEditModal from "./SubCategoryEditModal";
import SubCategoryModal from "./SubCategoryModal";

import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";

function SubCategoryList() {
  const uid = localStorage.getItem("id");
  const { categoryId } = useParams();
  const [subcategories, setSubCategories] = useState({ subcategories: [] });
  const [open, setOpenModal] = useState(false);
  const [openSubCategory, setOpenSubCategoryModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  const tableContainerRef = useRef(null);
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    const initialScroll = localStorage.getItem("subCategoryScroll");
    if (
      subcategories &&
      subcategories.subcategories.length > 0 &&
      initialScroll !== "done"
    ) {
      let direction = 1;
      const scrollInterval = setInterval(() => {
        setScrollValue((prev) => {
          let newValue = prev + direction * 1;
          if (newValue >= 100) {
            direction = -1;
          } else if (newValue <= 0) {
            clearInterval(scrollInterval);
            localStorage.setItem("subCategoryScroll", "done");
          }
          return newValue;
        });
      }, 20);

      return () => clearInterval(scrollInterval);
    }
  }, [subcategories]);

  useEffect(() => {
    if (
      subcategories &&
      subcategories.subcategories.length > 0 &&
      tableContainerRef.current
    ) {
      tableContainerRef.current.scrollTo({
        left: tableContainerRef.current.scrollWidth * (scrollValue / 100),
        behavior: "smooth",
      });
    }
  }, [scrollValue]);

  useEffect(() => {
    getSubCategories();
  }, [refresh]);

  const getSubCategories = () => {
    setLoading(true);
    AxiosUrl.get(`/subcategory/${uid}/${categoryId}`)
      .then((res) => {
        setSubCategories(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
      });
  };

  const deleteSubCategory = (subCategoryId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this subcategory?"
    );
    if (confirmed) {
      AxiosUrl.post(`/delete-subcategory/${uid}/${categoryId}/${subCategoryId}`)
        .then((res) => {
          if (res.data.acknowledged) {
            toast.success("Deleted successfully.");
            setRefresh((prev) => prev + 1);
          }
        })
        .catch(() => {
          toast.error("Failed to delete the subcategory.");
        });
    }
  };

  const handleSliderChange = (event, newValue) => {
    setScrollValue(newValue);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        left: tableContainerRef.current.scrollWidth * (newValue / 100),
        behavior: "smooth",
      });
    }
  };

  return (
    <div style={{ height: "80vh" }}>
      <ToastContainer />
      {open && edit && (
        <SubCategoryEditModal
          open={open}
          setOpenModal={setOpenModal}
          subcategories={subcategories}
          data={edit}
          setRefresh={() => setRefresh((prev) => prev + 1)}
        />
      )}
      {openSubCategory && (
        <SubCategoryModal
          setOpenSubCategoryModal={setOpenSubCategoryModal}
          setRefresh={() => setRefresh((prev) => prev + 1)}
          category={subcategories}
        />
      )}
      <div className="category-main-1234">
        <div className="category-video-1234">
          <div style={{ textAlign: "center" }}>
            মেইন ক্যাটাগরি - {subcategories.name} - নিচে থেকে সব-ক্যাটাগরি সেট
            করুন
          </div>
        </div>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.36) 0px 0px 4px 0px",
            borderRadius: 10,
            overflow: "hidden",
            marginBottom: 10,
          }}
        >
          <div className="create-category-1234">
            <Button
              variant="contained"
              onClick={() => setOpenSubCategoryModal(true)}
              style={{ fontSize: 13, fontWeight: "bold" }}
            >
              Create Sub-Category
            </Button>
          </div>

          <div className="slider-container-1234">
            <p>
              টেবিল টি ডান দিকে বা বাম দিকে দেখতে নিচের স্লাইড টি ডানে বা বামে
              টান দিন{" "}
            </p>
            <Slider
              value={scrollValue}
              aria-label="Scroll"
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
              step={1}
              min={0}
              max={100}
            />
          </div>

          <TableContainer
            component={Paper}
            style={{ borderRadius: 0, overflowX: "auto" }}
            ref={tableContainerRef}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" width={70}>
                    Image
                  </TableCell>
                  <TableCell align="center" width={150}>
                    SubCategory Name
                  </TableCell>
                  <TableCell align="center" width={70}>
                    Status
                  </TableCell>
                  <TableCell align="center" width={130}>
                    Edit
                  </TableCell>
                  <TableCell align="right" width={80}>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <ReactLoading
                        type="cylon"
                        color="rgb(226,115,29)"
                        height={60}
                        width={60}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && subcategories.subcategories.length <= 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <div className="no-data-available-1234">
                        No data available
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  subcategories.subcategories.map((data) => (
                    <TableRow
                      key={data._id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <TableCell align="left" component="th" scope="row">
                        <img
                          src={data.imgUrl}
                          height={50}
                          width={50}
                          alt={data.name}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ fontSize: 13, fontWeight: "bold" }}
                      >
                        {data.name}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor:
                              data.status === 1
                                ? "rgb(147, 46, 93)"
                                : "#275727",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {data.status === 1 ? "Deactive" : "Active"}
                        </Button>
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor: "#275727",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                          onClick={() => {
                            setEdit(data);
                            setOpenModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "#9b2a2a",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                          onClick={() => deleteSubCategory(data._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="sub-category-back-1234">
            <Button
              variant="contained"
              onClick={() => window.history.back()}
              style={{ fontSize: 13, fontWeight: "bold" }}
            >
              {`Back to ${subcategories.name}`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubCategoryList;
