import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Cartcontext } from "../../context/Context";
import "./AddtoCart.css";
import { Icon } from "@iconify/react";

function AddtoCart() {
  const navigate = useNavigate();
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const total = state.reduce((total, item) => {
    return total + item.proceSell * item.setQuantity;
  }, 0);

const cartNumber = state.length ? state.length : 0;
  return (
    <div className="CartMain" onClick={()=> navigate("/cart")}>
      <div className="cart-icon">
        <Icon
          style={{ fontSize: "20px" }}
          icon="material-symbols:add-shopping-cart-rounded"
        />
        <div>{`${cartNumber} ${cartNumber < 2 ? "Item" : "Items"} `}</div>
      </div>
      <div className="taka">{`৳${total ? total :"0"}`}</div>
    </div>
  );
}

export default AddtoCart;
