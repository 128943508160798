import React, { useEffect, useRef } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
} from "chart.js";

// Register required components
Chart.register(CategoryScale, LinearScale, BarController, BarElement, Tooltip);

function BarChart(data) {
  console.log(data);
  const chartRef = useRef();

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const cities = data.data.map((item) => item.City);
    const orders = data.data.map((item) => item.orders);

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: cities,
        datasets: [
          {
            label: "Orders",
            data: orders,
            backgroundColor: [
              "blue",
              "green",
              "red",
              "orange",
              "purple",
              "yellow",
              "pink",
              "cyan",
              "teal",
              "lime",
            ],
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => `Orders: ${context.parsed.y}`,
            },
          },
        },
      },
    });
  }, []);

  return <canvas ref={chartRef} />;
}

export default BarChart;
