import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Header.css";
import myAxios from "../AxiosUrl";
import Avatar from "../../img/avatar.png";
import { FiMenu } from "react-icons/fi";

function Header() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("teamid");
    localStorage.removeItem("Admin_Id");
    setOpen(false);
  };

  return (
    <div className={`main-header`}>
      <div className="mobile-container">
        <div className="logo">
          <Link style={{ textDecoration: "none" }} onClick={() => navigate(0)}>
            <div className="logo-text">Call Center</div>
          </Link>
        </div>
        <div className="menu-icon" onClick={() => setOpen(!open)}>
          <FiMenu style={{ fontSize: "24px" }} />
        </div>
      </div>
      <div className={`nav ${open ? "active" : ""}`}>
        <ul>
          <Link
            to="/teamlogin"
            style={{ textDecoration: "none" }}
            onClick={logoutHandler}
          >
            <li>Logout</li>
          </Link>
        </ul>
        <div className="avatar">
          <img src={Avatar} height={40} width={40} />
        </div>
      </div>
    </div>
  );
}

export default Header;
