import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import myAxios from "../AxiosUrl";
import "./EditProduct.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Addproduct() {
  const location = useLocation();
  const navigate = useNavigate();
  const [productName, setProductName] = useState(
    location.state.productName || ""
  );
  const [productDesc, setproductDesc] = useState(
    location.state.productDec || ""
  );
  const [buyPrice, setbuyPrice] = useState(location.state.priceBuy || "");
  const [sellingPrice, setsellingPrice] = useState(
    location.state.proceSell || ""
  );
  const [colorName, setcolorName] = useState(location.state.color || "");
  const [quantity, setquantity] = useState(location.state.quantity || "");
  const [photo, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [category, setCategory] = useState("");
  const [categorydata, setCategoryData] = useState("");
  const uid = localStorage.getItem("id");

  const cdnImage = async () => {
    const image = new FormData();
    image.append("photo", photo);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload";
    axios
      .post(url, image, config)
      .then((res) => {
        setImageUrl(res.data);
        toast.success("Image uploaded to CDN");
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategory();
    if (photo) {
      cdnImage();
    }
  }, [photo]);

  const submitH = async (e) => {
    e.preventDefault();
    if (
      !buyPrice ||
      !sellingPrice ||
      !quantity ||
      !colorName ||
      !productDesc ||
      !productName ||
      !photo ||
      !categorydata
    ) {
      toast.error("All Value Require");
      return;
    }

    const url = `update-product/${location.state._id}`;
    const formData = {
      productName,
      productDesc,
      buyPrice,
      sellingPrice,
      colorName,
      quantity,
      imageUrl,
      category: categorydata,
    };

    myAxios
      .post(url, formData)
      .then((res) => {
        setProductName("");
        setproductDesc("");
        setbuyPrice("");
        setsellingPrice("");
        setcolorName("");
        setquantity("");
        setImage("");
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCategory = () => {
    myAxios
      .get(`/category/${uid}`)
      .then((res) => {
        setCategory(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <ToastContainer />
      <div className="add-product">
        <div className="add-sub">
          <h3>EDIT PRODUCT</h3>
          {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
          <input
            onChange={(e) => {
              setProductName(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Product Name"
            value={productName}
            required=""
          />
          <input
            onChange={(e) => {
              setproductDesc(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Product Description"
            value={productDesc}
            required=""
          />
          <input
            onChange={(e) => {
              setbuyPrice(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Buying Price"
            value={buyPrice}
            required=""
          />
          <input
            onChange={(e) => {
              setsellingPrice(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Selling Price"
            value={sellingPrice}
            required=""
          />
          <input
            onChange={(e) => {
              setcolorName(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Color"
            value={colorName}
            required=""
          />
          <input
            onChange={(e) => {
              setquantity(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Quantity"
            value={quantity}
            required=""
          />
          <select
            name="category"
            className="category-addproduct"
            onChange={(e) => setCategoryData(e.target.value)}
          >
            {category &&
              category.map((data) => {
                return (
                  <option value={data.name} key={data._id}>
                    {data.name}
                  </option>
                );
              })}
          </select>
          <div className="upload-image">
            <input
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              type="file"
              className="myFile"
              name="photo"
            ></input>
          </div>
          <button onClick={submitH} className="sub-button">
            Update Product
          </button>
          <button
            onClick={() => {
              navigate("/stock");
            }}
            className="sub-button"
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default Addproduct;
