import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import AxiosUrl from "../../../AxiosUrl";
import noImage from "../../../../img/noinage.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #00000038",
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (min-width: 600px)": {
    width: 400,
  },
};

function StoreDescriptionModal({
  open,
  handleClose,
  setRefresh,
  Refresh,
  Data,
  setDescriptionModal,
}) {
  const [store, setStore] = useState(Data?.store || "");
  const [metaTitle, setMetaTitle] = useState(Data?.metaTitle || "");
  const [metaDescription, setMetaDescription] = useState(
    Data?.metaDescription || ""
  );
  const [metaKeywords, setMetaKeywords] = useState(Data?.metaKeywords || "");
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");
  const [photo, setPhoto] = useState("");
  const [imageUrl, setImageUrl] = useState(Data?.logo || "");

  const fromData = {
    uid,
    logo: imageUrl,
    store,
    metaTitle,
    metaDescription,
    metaKeywords,
  };

  const submitDomain = () => {
    setLoading(true);

    AxiosUrl.post(`/update-domain-new/${uid}`, fromData)
      .then(() => {
        setRefresh(Refresh + 1);
        setDescriptionModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error.response.data);
        setLoading(false);
      });
  };

  const cdnImage = async () => {
    const image = new FormData();
    image.append("photo", photo);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload-normal";
    try {
      const res = await axios.post(url, image, config);
      toast.success("Logo Uploaded");
      setImageUrl(res.data);
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  useEffect(() => {
    if (photo) {
      cdnImage();
    }
  }, [photo]);

  return (
    <Modal
      open={setDescriptionModal}
      onClose={() => setDescriptionModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box sx={style}>
        <div className="titleCloseBtn" style={{ textAlign: "right" }}>
          <Button
            onClick={() => setDescriptionModal(false)}
            style={{ color: "red" }}
          >
            X
          </Button>
        </div>
        <img
          src={imageUrl || noImage}
          alt="Store Logo"
          style={{
            display: "block",
            margin: "0 auto",
            maxHeight: 200,
            maxWidth: "100%",
          }}
        />
        <div
          className="category-upload"
          style={{ textAlign: "center", marginTop: 15 }}
        >
          <h3>Upload Logo (Max Width: 2 Inches)</h3>
          <input
            type="file"
            onChange={(e) => setPhoto(e.target.files[0])}
            className="CmFile"
            name="photo"
          />
        </div>
        <Box sx={{ marginTop: 2 }}>
          <TextField
            placeholder="Store Name"
            fullWidth
            label="Store Name"
            onChange={(e) => setStore(e.target.value)}
            value={store}
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <TextField
            placeholder="Meta Title"
            fullWidth
            label="Meta Title"
            onChange={(e) => setMetaTitle(e.target.value)}
            value={metaTitle}
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <TextField
            placeholder="Meta Description"
            fullWidth
            label="Meta Description"
            onChange={(e) => setMetaDescription(e.target.value)}
            value={metaDescription}
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <TextField
            placeholder="Meta Keywords"
            fullWidth
            label="Meta Keywords"
            onChange={(e) => setMetaKeywords(e.target.value)}
            value={metaKeywords}
          />
        </Box>
        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button
            onClick={() => setDescriptionModal(false)}
            style={{ marginRight: 10 }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={submitDomain} variant="contained" disabled={loading}>
            {loading ? "Loading..." : "Continue"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default StoreDescriptionModal;
