import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import myAxios from "../AxiosUrl";
import "./EditOrder.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function EditOrder() {
  const location = useLocation();
  const navigate = useNavigate();
  const [cName, setcName] = useState(location.state.cName || "");
  const [mobile, setMobile] = useState(location.state.cMobile || "");
  const [address, setAddress] = useState(location.state.address || "");
  const [status, setStatus] = useState(location.state.orderStatus);
  const [totalPrice, setTotalPrice] = useState(location.state.totalPrice);
  const [advance, setAdvance] = useState(location.state.advance);
  const [discount, setDiscount] = useState(location.state.discount);
  const [privateNote, setPrivateNote] = useState(location.state.privateNote);
  const [OrderNote, setOrderNote] = useState(location.state.OrderNote);
  const [loading, setLoading] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState(
    location.state.deliveryCharge
  );

  const submitH = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!cName || !mobile || !address || !status || !deliveryCharge) {
      toast.error("All Value Require");
      return;
    }

    const url = `edit-order/${location.state._id}`;
    const formData = {
      cName,
      mobile,
      address,
      status,
      privateNote,
      deliveryCharge,
      totalPrice,
      advance,
      discount,
      OrderNote,
    };

    myAxios
      .post(url, formData)
      .then((res) => {
        setLoading(false);
        navigate("/create-list");
        toast.success(res.data.message);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  //location.state.product
  return (
    <div>
      <ToastContainer />
      <div className="add-product">
        <div className="add-sub">
          <div className="image-qnt-main-flex">
            {location.state.orderData.map((data, index) => {
              return (
                <div className="img-p" key={index}>
                  <img
                    height={"50px"}
                    width={"50px"}
                    src={data.image}
                    alt="product"
                  />
                  <p>{`Quantity${data.quantity}`}</p>
                </div>
              );
            })}
          </div>
          <div className="image-qnt-main-flex">
            {location.state.product.map((data, index) => {
              console.log(data);
              return (
                <p
                  style={{ margin: "0px", padding: "0px" }}
                  key={index}
                >{`${data.Quality} Pcs ${data.pCode} Color ${data.color}`}</p>
              );
            })}
          </div>
          <div className="delivery-main-flex">
            <div className="delivery-in">
              <p>Delivery Location</p>
              <button
                className={
                  deliveryCharge === "dhaka"
                    ? "button-select"
                    : "delivery-button"
                }
                onClick={() => setDeliveryCharge("dhaka")}
              >
                Inside Dhaka
              </button>
              <button
                className={
                  deliveryCharge === "outside"
                    ? "button-select"
                    : "delivery-button"
                }
                onClick={() => setDeliveryCharge("outside")}
              >
                Outside Dhaka
              </button>
            </div>
          </div>
          <input
            onChange={(e) => {
              setcName(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Customer Name"
            value={cName}
            required=""
          />
          <input
            onChange={(e) => {
              setMobile(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Mobile Number"
            value={mobile}
            required=""
          />
          <input
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Address"
            value={address}
            required=""
          />
          <input
            onChange={(e) => {
              setTotalPrice(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Total Price"
            value={totalPrice}
            required=""
          />
          <input
            onChange={(e) => {
              setAdvance(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Advance"
            value={advance}
            required=""
          />

          <input
            onChange={(e) => {
              setDiscount(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Discount"
            value={discount}
            required=""
          />
          <input
            onChange={(e) => {
              setPrivateNote(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Private Note: Optional"
            value={privateNote}
            required=""
          />
          <input
            onChange={(e) => {
              setOrderNote(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Order Note: Optional"
            value={OrderNote}
            required=""
          />
          {status !== 5 && (
            <select
              name="status"
              className="orderEditStatus"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="1">New</option>
              <option value="2">Call Done</option>
              <option value="7">Call Not-Received</option>
              <option value="8">Ask-Advance</option>
              <option value="9">Ship-Later</option>
              <option value="10">Waiting</option>
              <option value="12">Delivery-Issue</option>
              <option value="13">Payment-Received</option>
              <option value="3">Courier Done</option>
              <option value="4">Print Done</option>
              <option value="11">Overseas</option>
              <option value="5">Cancel</option>
              <option value="6">Return</option>
            </select>
          )}
          <button onClick={submitH} className="sub-button">
            {loading ? "Loading.." : "Update Product"}
          </button>
          <button
            onClick={() => {
              navigate("/create-list");
            }}
            className="sub-button"
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditOrder;
