import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosUrl from "../../../AxiosUrl";
import "./SteadFastCourierAPI.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #00000038",
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (min-width: 600px)": {
    width: 400,
  },
};

function SteadFastCourierAPIModal({
  setSteadFastApiModal,
  setRefresh,
  Refresh,
  Data,
}) {
  const [SteadFastAPIKey, setSteadFastAPIKey] = useState(
    Data?.SteadFastAPIKey || ""
  );
  const [SteadFastSecret, setSteadFastSecret] = useState(
    Data?.SteadFastSecret || ""
  );
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const fromData = {
    uid,
    SteadFastAPIKey,
    SteadFastSecret,
  };

  const submitDomain = () => {
    setLoading(true);
    if (!SteadFastAPIKey || !SteadFastSecret) {
      setLoading(false);
      return toast.error("All fields are required");
    }

    AxiosUrl.post(`/update-domain-new/${uid}`, fromData)
      .then(() => {
        setRefresh(Refresh + 1);
        setSteadFastApiModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={setSteadFastApiModal}
      onClose={() => setSteadFastApiModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box sx={style}>
        <ToastContainer />
        <div className="titleCloseBtn" style={{ textAlign: "right" }}>
          <Button
            onClick={() => setSteadFastApiModal(false)}
            style={{ color: "red" }}
          >
            X
          </Button>
        </div>

        <div className="title">
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <TextField
              placeholder="SteadFast Api Key"
              fullWidth
              label="SteadFast Api Key"
              onChange={(e) => setSteadFastAPIKey(e.target.value)}
              value={SteadFastAPIKey}
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="SteadFast Api Secret"
              fullWidth
              label="SteadFast Api Secret"
              onChange={(e) => setSteadFastSecret(e.target.value)}
              value={SteadFastSecret}
            />
          </Box>
        </div>

        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button
            onClick={() => setSteadFastApiModal(false)}
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button onClick={submitDomain} variant="contained" disabled={loading}>
            {loading ? "Loading.." : "Continue"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default SteadFastCourierAPIModal;
