import React from "react";

const PackageCard = ({ card, isSelected, onSelect }) => {
  return (
    <div className="package__card__container" onClick={() => onSelect(card)}>
      <div className="package__card__radio">
        <input type="radio" checked={isSelected} />
      </div>
      <div className="package__card__content">
        <h3>{card.title}</h3>
        <p>{card.description}</p>
        <p>৳{card.price}</p>
      </div>
    </div>
  );
};

export default PackageCard;
