import React, { useState } from "react";
import Accordion from "../utils/Accordion";
import { ChangeLandingTwoFAQModal } from "../../LandingPageModals/LandingPageModals";

const Faq = ({ data, setData }) => {
  const [openFAQ, setOpenFAQ] = useState(false);
  return (
    <div className="landing1faq">
      <div className="container">
        <div className="landing1faq__container">
          <button
            className="landing-page-edit-btn"
            onClick={() => setOpenFAQ(true)}
            style={{ marginBottom: "20px" }}
          >
            Edit
          </button>
          <div className="landing1faq__title">
            <h2>
              নীচের প্রশ্নগুলো আমরা প্রতিনিয়ত পেয়ে থাকি। আশা করি এর মধ্যে আপনি
              আপনার প্রশ্নের উত্তর পেয়ে যাবেন ...
            </h2>
          </div>
          <Accordion items={data.Faqs} />
        </div>
      </div>
      <ChangeLandingTwoFAQModal
        data={data}
        setData={setData}
        openFAQ={openFAQ}
        setOpenFAQ={setOpenFAQ}
      />
    </div>
  );
};

export default Faq;
