import React, { useState } from "react";
import TextItem from "../utils/TextItem";
import { FiArrowRightCircle } from "react-icons/fi";
import { ChangeContentListModal } from "../../LandingPageModals/LandingPageModals";

export const EffectivenessContentList = ({ data, setData }) => {
  const [openContentList, setOpenContentList] = useState(false);
  return (
    <div className="effectiveness__content__list">
      <div className="container">
        <div className="effectiveness__content__list__container">
          <button
            className="landing-page-edit-btn landing-page-edit-btn-position"
            onClick={() => setOpenContentList(true)}
            style={{ marginBottom: "30px" }}
          >
            Edit
          </button>
          <div className="effectiveness__content__list__card">
            {data.Pros.length > 0 &&
              data.Pros.map((item, index) => (
                <TextItem
                  key={item.id}
                  icon={<FiArrowRightCircle className="icon" />}
                  text={item.text}
                />
              ))}
          </div>
        </div>
      </div>
      <ChangeContentListModal
        data={data}
        setData={setData}
        openContentList={openContentList}
        setOpenContentList={setOpenContentList}
      />
    </div>
  );
};
