import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosUrl from "../../../AxiosUrl";
import "./Tracking.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #00000038",
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (min-width: 600px)": {
    width: 400,
  },
};

function TrackingModal({ setTrackingModal, setRefresh, Refresh, Data }) {
  const [GoogleAnalytic, setGoogleAnalytic] = useState(
    Data?.GoogleAnalytic || ""
  );
  const [GoogleAdsTag, setGoogleAdsTag] = useState(Data?.GoogleAdsTag || "");
  const [pixel, setPixel] = useState(Data?.pixel || "");
  const [FAccessToken, setFAccessToken] = useState(Data?.FAccessToken || "");
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const fromData = {
    uid,
    pixel,
    FAccessToken,
    GoogleAnalytic,
    GoogleAdsTag,
  };

  const submitDomain = () => {
    setLoading(true);

    AxiosUrl.post(`/update-domain-new/${uid}`, fromData)
      .then(() => {
        setRefresh(Refresh + 1);
        setTrackingModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={setTrackingModal}
      onClose={() => setTrackingModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box sx={style}>
        <ToastContainer />
        <div className="titleCloseBtn" style={{ textAlign: "right" }}>
          <Button
            onClick={() => setTrackingModal(false)}
            style={{ color: "red" }}
          >
            X
          </Button>
        </div>

        <div className="title">
          <Box sx={{ width: "100%", marginBottom: "16px" }}>
            <TextField
              placeholder="FaceBook Pixel"
              fullWidth
              label="FaceBook Pixel"
              onChange={(e) => setPixel(e.target.value)}
              value={pixel}
            />
          </Box>
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <TextField
              placeholder="Write Access Token"
              fullWidth
              label="FaceBook Conversions API"
              onChange={(e) => setFAccessToken(e.target.value)}
              value={FAccessToken}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Google Analytic"
              fullWidth
              label="Google Analytic"
              onChange={(e) => setGoogleAnalytic(e.target.value)}
              value={GoogleAnalytic}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Google Ads Tag"
              fullWidth
              label="Google Ads Tag"
              onChange={(e) => setGoogleAdsTag(e.target.value)}
              value={GoogleAdsTag}
            />
          </Box>
        </div>

        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button
            onClick={() => setTrackingModal(false)}
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button onClick={submitDomain} variant="contained" disabled={loading}>
            {loading ? "Loading.." : "Continue"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default TrackingModal;
