import React, { useState, useRef, useEffect } from "react";
import "./Profile.css";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "../../img/avatar.png";
import SMS from "../SMSModal/SMSModal";

const Profile = () => {
  const uid = localStorage.getItem("id");
  const [Profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingp, setLoadingp] = useState(false);
  const [SMSModal, setSMSModal] = useState(false);
  const [password, setPassword] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [Amount, setAmount] = useState(10000);
  const bottomEl = useRef(null);
  const [isChangedPassword, setIsChangedPassword] = useState(false);

  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    AxiosUrl.post(`profile/${uid}`, formData)
      .then((res) => {
        setLoading(false);
        toast.success(res.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const handlePassword = (e) => {
    setLoadingp(true);
    if (!password & !oldPass) {
      return toast.error("Password and Old password Required.");
    }
    AxiosUrl.post(`change-password/${uid}`, {
      oldPass: oldPass,
      newPass: password,
    })
      .then((res) => {
        setLoadingp(false);
        if (res.data == "Password not match") {
          toast.error("Old password not match");
        } else {
          toast.success(res.data);
        }
      })
      .catch((error) => {
        setLoadingp(false);
        console.log(error);
      });
  };
  const updateProfile = (e) => {
    e.preventDefault();

    console.log(formData);
  };

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({ behavior: "smooth" });
  };
  const SubminSMS = () => {
    if (!Amount) {
      toast.error("Amount require.");
      return;
    }
    setSMSModal(true);
  };

  useEffect(() => {
    AxiosUrl.get(`profile/${uid}`)
      .then((res) => {
        setProfile(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="profile-page-container">
      {SMSModal && <SMS setSMSModal={setSMSModal} data={Amount} />}

      <ToastContainer />
      <div className="profile-container">
        <div className="profile-header">Profile</div>
        <form onSubmit={updateProfile} className="profile-form">
          <img className="profile-image" src={Avatar} alt="profile" />
          <div className="profile-form-item">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              defaultValue={Profile.name}
              onChange={handleChange}
            />
          </div>
          <div className="profile-form-item">
            <label htmlFor="email">Mobile</label>
            <input
              type="text"
              id="mobile"
              name="Mobile"
              placeholder="mobile"
              defaultValue={Profile.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="profile-form-item">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="Address"
              placeholder="address"
              defaultValue={Profile.address}
              onChange={handleChange}
            />
          </div>

          <button onClick={handleSubmit} className="login-btn">
            {loading ? "Loading.." : "Update Profile"}
          </button>
          <div className="profile-password-changed">
            {isChangedPassword
              ? "Do you want to hide ?"
              : "Do you want to change password ?"}{" "}
            <span
              className="profile-link"
              onClick={() => {
                scrollToBottom();
                setIsChangedPassword((prev) => !prev);
              }}
            >
              Click here
            </span>
          </div>

          {isChangedPassword && (
            <>
              <div className="profile-form-item">
                <label htmlFor="old-password">Old Password</label>
                <input
                  type="password"
                  id="old-password"
                  name="oldPassword"
                  placeholder="Old Password"
                  onChange={(e) => setOldPass(e.target.value)}
                />
              </div>
              <div className="profile-form-item">
                <label htmlFor="new-password">New Password</label>
                <input
                  type="password"
                  id="new-password"
                  name="nesPassword"
                  placeholder="new Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                onClick={handlePassword}
                type="button"
                className="login-btn"
              >
                {loadingp ? "Loading.." : "Change Password"}
              </button>
              <div ref={bottomEl} />
            </>
          )}
        </form>
      </div>
      <div className="profile-container">
        <div className="profile-header">SMS Balance</div>

        <div className="SMS-Balance">
          SMS Balance 1000 Taka and Per SMS 0.25 for 160 Characters{" "}
        </div>
        <div className="SMS-Balance">{Amount ? Amount * 0.25 : 0} SMS</div>

        <div className="SMS-Balance">
          <input
            type="number"
            id="name"
            name="name"
            placeholder="Amount: example 10000"
            defaultValue={Amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button className="buysms" onClick={SubminSMS}>
            Buy SMS Balance
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
