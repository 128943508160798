import React, { useState } from "react";
import { ChangeTitleModal } from "../../LandingPageModals/LandingPageModals";

const Effectiveness = ({ data, setData }) => {
  const [openTitle, setOpenTitle] = useState(false);
  return (
    <div className="effectiveness">
      <div className="container">
        <div className="effectiveness__container">
          <button
            className="landing-page-edit-btn"
            onClick={() => setOpenTitle(true)}
          >
            Edit
          </button>
          <h1 style={{ marginBottom: "10px", color: "black" }}>সুবিধা</h1>
          <p>{data.ProsTitle}</p>
        </div>
      </div>
      <ChangeTitleModal
        openTitle={openTitle}
        setOpenTitle={setOpenTitle}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default Effectiveness;
