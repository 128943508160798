import React, { useState, useEffect } from "react";
import "./CategorySelect.css";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CategoryEdtSelectModal from "../CategoryEdtSelectModal/CategoryEdtSelectModal";
import CategoryCreateSelectModal from "../CategoryCreateSelectModal/CategoryCreateSelectModal";

import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

function CategorySelect() {
  const uid = localStorage.getItem("id");
  const [category, setCategory] = useState([]);
  const [open, setOpenModal] = useState(false);
  const [openCategory, setOpenCategoryModal] = useState(false);

  const [data, setData] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategory();
  }, [open, refresh, openCategory]);

  const getCategory = () => {
    setLoading(true);
    AxiosUrl.get(`/home-category/${uid}`)
      .then((res) => {
        setCategory(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const deleteCategory = (cid) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this category?"
    );
    if (confirmDelete) {
      setRefresh(false);
      AxiosUrl.post(`/home-delete-category/${uid}`, { cid })
        .then((res) => {
          toast.success("Deleted successfully.");
          setRefresh(true);
        })
        .catch(() => {
          toast.error("Failed to delete.");
        });
    }
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <ToastContainer />
      {open && (
        <CategoryEdtSelectModal setOpenModal={setOpenModal} data={data} />
      )}
      {openCategory && (
        <CategoryCreateSelectModal
          setOpenCategoryModal={setOpenCategoryModal}
        />
      )}
      <div className="category-select-main">
        <div className="category-select-video">
          <div>
            স্পেশাল হোম ক্যাটাগরি কিভাবে কাজ করে ➡️{" "}
            <a href="#" target="_blank">
              VIDEO LINK
            </a>{" "}
          </div>
        </div>
        <div className="category-select-create">
          <Button
            variant="contained"
            onClick={() => setOpenCategoryModal(true)}
          >
            Add Home Category
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" width={60}>
                  Number
                </TableCell>
                <TableCell align="center" width={67}>
                  Status
                </TableCell>
                <TableCell align="center" width={200}>
                  Set Category
                </TableCell>
                <TableCell align="center" width={120}>
                  Selected
                </TableCell>
                <TableCell align="right" width={70}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <ReactLoading
                  type="cylon"
                  color="rgb(226,115,29)"
                  height={60}
                  width={60}
                />
              )}
              {!loading && category.length <= 0 ? (
                <div className="category-select-no-data">No data available</div>
              ) : (
                category.map((data, index) => {
                  return (
                    <TableRow key={data._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ borderBottom: "none" }}
                      >
                        <Button
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            padding: "2px 10px 2px 10px",
                            fontSize: "13px",
                          }}
                        >
                          {data.status === "0" ? "Deactive" : "Active"}
                        </Button>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ borderBottom: "none" }}
                      >
                        <Button
                          style={{
                            backgroundColor: "rgba(41, 58, 151, 0.85)",
                            color: "#fff",
                            padding: "2px 10px 2px 10px",
                            fontSize: "13px",
                          }}
                          onClick={() => {
                            setData(data);
                            setOpenModal(true);
                          }}
                        >
                          Select Category
                        </Button>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ borderBottom: "none" }}
                      >
                        <Button
                          style={{
                            backgroundColor: "rgba(41, 58, 151, 0.85)",
                            color: "#fff",
                            padding: "2px 10px 2px 10px",
                            fontSize: "13px",
                          }}
                        >
                          {data.cName ? data.cName : "NOT SET"}
                        </Button>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="right"
                        style={{ borderBottom: "none" }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#8f2121",
                            color: "#fff",
                            padding: "2px 10px 2px 10px",
                            fontSize: "13px",
                          }}
                          onClick={() => deleteCategory(data._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default CategorySelect;
