import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

const ButtonGroup = ({ next, previous, ...rest }) => {
  const { carouselState: { currentSlide, slidesToShow, totalItems } = {} } =
    rest;

  return (
    <div className="slider__btn__container">
      <button
        disabled={currentSlide === 0}
        className={`slider__image__button  ${
          currentSlide === 0 ? "disabled" : ""
        }`}
        onClick={() => previous()}
      >
        <AiOutlineLeft style={{ color: "white" }} />
      </button>
      <button
        disabled={currentSlide + slidesToShow === totalItems}
        className={`slider__image__button ${
          currentSlide + slidesToShow === totalItems ? "disabled" : ""
        }`}
        onClick={() => next()}
      >
        <AiOutlineRight style={{ color: "white" }} />
      </button>
    </div>
  );
};

export default function Slider({ children }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      swipeable={true}
      draggable={true}
      showDots={false}
      infinite={true}
      keyBoardControl={true}
      arrows={false}
      customButtonGroup={<ButtonGroup />}
    >
      {children}
    </Carousel>
  );
}
