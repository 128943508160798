import React, { useEffect, useState } from "react";
import "./Theme.css";
import ColorPicker from "./ColorPicker";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";

function Theme() {
  const [HeaderColor, setHeaderColor] = useState("#fff");
  const [HeaderTextColor, setHeaderTextColor] = useState("#000");
  const [FooterColor, setFooterColor] = useState("#fff");
  const [FooterTextColor, setFooterTextColor] = useState("#000");
  const [AddToCartColor, setAddToCartColor] = useState("#fff");
  const [AddToCartTextColor, setAddToCartTextColor] = useState("#000");
  const [BuyNowColor, setBuyNowColor] = useState("#fff");
  const [BuyNowTextColor, setBuyNowTextColor] = useState("#000");
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const formData = {
    HeaderColor: HeaderColor,
    HeaderTextColor: HeaderTextColor,
    FooterColor: FooterColor,
    FooterTextColor: FooterTextColor,
    AddToCartColor: AddToCartColor,
    AddToCartTextColor: AddToCartTextColor,
    BuyNowColor: BuyNowColor,
    BuyNowTextColor: BuyNowTextColor,
  };

  useEffect(() => {
    AxiosUrl.get(`/get-color/${uid}`)
      .then((res) => {
        setHeaderColor(res.data.data[0].HeaderColor);
        setHeaderTextColor(res.data.data[0].HeaderTextColor);
        setFooterColor(res.data.data[0].FooterColor);
        setFooterTextColor(res.data.data[0].FooterTextColor);
        setAddToCartColor(res.data.data[0].AddToCartColor);
        setAddToCartTextColor(res.data.data[0].AddToCartTextColor);
        setBuyNowColor(res.data.data[0].BuyNowColor);
        setBuyNowTextColor(res.data.data[0].BuyNowTextColor);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const saveTheme = () => {
    setLoading(true);
    AxiosUrl.post(`/set-color/${uid}`, formData)
      .then((res) => {
        setLoading(false);
        toast.success(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainTheme">
      <ToastContainer />
      <div className="ThemeContainer">
        <div className="ThemeHeaderFooter">
          <h3 style={{ backgroundColor: HeaderColor, color: HeaderTextColor }}>
            Header Color
          </h3>
          <ColorPicker
            name={"Header Background"}
            SetColor={setHeaderColor}
            C_Color={HeaderColor}
          />
          <ColorPicker
            name={"Header Text"}
            SetColor={setHeaderTextColor}
            C_Color={HeaderTextColor}
          />
          <button className="saveTheme" onClick={saveTheme}>
            {loading ? "Loading..." : "Save Theme Color"}
          </button>
        </div>
        <div className="ThemeHeaderFooter">
          <h3 style={{ backgroundColor: FooterColor, color: FooterTextColor }}>
            Footer Color
          </h3>

          <ColorPicker
            name={"Footer Background"}
            SetColor={setFooterColor}
            C_Color={FooterColor}
          />
          <ColorPicker
            name={"Footer Text"}
            SetColor={setFooterTextColor}
            C_Color={FooterTextColor}
          />
          <button className="saveTheme" onClick={saveTheme}>
            {loading ? "Loading..." : "Save Theme Color"}
          </button>
        </div>
      </div>
      <div className="ThemeContainer">
        <div className="ThemeHeaderFooter">
          <h3
            style={{
              backgroundColor: AddToCartColor,
              color: AddToCartTextColor,
            }}
          >
            Add To Cart Color
          </h3>
          <ColorPicker
            name={"AddToCart Background"}
            SetColor={setAddToCartColor}
            C_Color={AddToCartColor}
          />
          <ColorPicker
            name={"AddToCart Text"}
            SetColor={setAddToCartTextColor}
            C_Color={AddToCartTextColor}
          />
          <button className="saveTheme" onClick={saveTheme}>
            {loading ? "Loading..." : "Save Theme Color"}
          </button>
        </div>
        <div className="ThemeHeaderFooter">
          <h3
            style={{
              backgroundColor: BuyNowColor,
              color: BuyNowTextColor,
            }}
          >
            Buy Now Color
          </h3>

          <ColorPicker
            name={"BuyNow Background"}
            SetColor={setBuyNowColor}
            C_Color={BuyNowColor}
          />
          <ColorPicker
            name={"BuyNow Text"}
            SetColor={setBuyNowTextColor}
            C_Color={BuyNowTextColor}
          />
          <button className="saveTheme" onClick={saveTheme}>
            {loading ? "Loading..." : "Save Theme Color"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Theme;
