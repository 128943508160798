import React from "react";
import { DescriptionCard } from "../utils/Cards";
import ImageCard from "../utils/ImageCard";
import Slider from "../utils/Slider";

const descriptons = [
  "আকৃতিঃ ৩১ x ১৮ x ২৩ সে.মি।",
  "উপাদানঃ পলিউরেথেন চামড়া এবং পলিয়েস্টার।",
  " রঙঃ লাল।",
  " ভেতরের অংশঃ দুটি মুল অংশ এবং চারটি সিক্রেট পকেট।",
  " বন্ধের উপাদানঃ জিপার।",
  "স্ট্রাপঃ দুটি কাঁধের স্ট্র্যাপ।",
  " ওজনঃ ১.২ কেজি।",
  "বিশেষ বৈশিষ্ট্যঃ পানি প্রতিরোধক।",
];

const Description = ({ data }) => {
  return (
    <div className="description">
      <div className="container">
        <div className="description__container">
          <div className="description__image">
            <ImageCard src={data.HeroImage} alt={data.HeroImage} />
            <div className="description__image__slider">
              <Slider>
                {data.SubSliders.length > 0 &&
                  data.SubSliders.map((image, index) => (
                    <img key={index} src={image} />
                  ))}
              </Slider>
            </div>
          </div>
          <div className="description__text">
            <DescriptionCard data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
