import axios from "axios";
import { URL } from "../Component/AxiosUrlGlobal";

export default async function BkashCreatePaymentAPICall(
  total,
  Invoice,
  OrderId
) {
  try {
    const create = await axios.post(`${URL}api/bkash/create`, {
      amount: total,
      callbackURL: `${window.location.protocol}//${window.location.host}/callback`,
      Invoice: Invoice,
      OrderId: OrderId,
    });

    await axios.post(
      `${URL}save-payment-ec/${create.data.merchantInvoiceNumber}`,
      {
        paymentID: create.data.paymentID,
        Amount: create.data.amount,
      }
    );

    if (create.data.bkashURL) {
      window.location.href = create.data.bkashURL;
    } else {
      window.location.href = "/";
    }
  } catch (error) {
    console.log("An error occurred:", error);
  }
}
