import React, { useState } from "react";
import "./UserModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Modal({ setUserModal }) {
  const [name, setCustomerName] = useState("");
  const [mobile, setCustomerMobile] = useState("");
  const [address, setCustomerAddress] = useState("");
  const [location, setCustomerLocation] = useState("");
  const [loading, setloading] = useState(false);
  const uid = localStorage.getItem("id");
  const fromData = {
    name,
    mobile,
    address,
    location,
  };
  const submitCategory = () => {
    setloading(true);
    if (!name || !mobile || !address || !location) {
      toast.error("All value is required!");
      return setloading(false);
    }
    if (mobile.length < 11 || mobile.length > 11) {
      toast.error("১১ সংখ্যার মোবাইল নম্বর লিখুন");
      setloading(false);
      return;
    }
    AxiosUrl.post(`/create-customer/${uid}`, fromData)
      .then((data) => {
        if (data.data === "Customer Already Exist") {
          toast.error(`Already exist with ${mobile}`);
          setloading(false);
        }
        if (data.data === "Customer Successful") {
          toast.success(data.data);
          setUserModal(false);
        }
      })
      .catch((error) => console.log(error.response.data));
  };

  return (
    <div className="modalBackground">
      <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setUserModal(false)}>X</button>
        </div>
        <h3 style={{ textAlign: "center" }}>Add Customer</h3>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Customer Name"
              id="fullWidth"
              onChange={(e) => setCustomerName(e.target.value)}
              value={name}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Mobile Number"
              id="fullWidth"
              onChange={(e) => setCustomerMobile(e.target.value)}
              value={mobile}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "20px" }}>
            <TextField
              placeholder="Example: Road# 000, House# 000, Apt# A2, Gulshan Dhaka"
              fullWidth
              label="Customer Address"
              id="fullWidth"
              onChange={(e) => setCustomerAddress(e.target.value)}
              value={address}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "20px" }}>
            <TextField
              fullWidth
              placeholder="Example: dhaka or outside"
              label="Customer Location"
              id="fullWidth"
              onChange={(e) => setCustomerLocation(e.target.value)}
              value={location}
            />
          </Box>
        </div>

        <div className="footer">
          <button
            onClick={() => {
              setUserModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              submitCategory();
            }}
          >
            {loading ? "Loading.." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
