import React, { useState, useEffect } from "react";
import "./DomainModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Modal({ setOpenModal, modalData }) {
  const [domain, setDomain] = useState("");
  const [store, setStore] = useState("");
  const [pixel, setPixel] = useState("");
  const [number, setNumber] = useState();
  const [WhatsApp, setWhatsApp] = useState();
  const [bkash, setBkash] = useState();
  const [loading, setloading] = useState(false);
  const uid = localStorage.getItem("id");
  const [photo, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [Dhaka, setDhaka] = useState("");
  const [Outside, setOutside] = useState();

  const fromData = {
    uid,
    domain: domain.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/+$/, ""),
    logo: imageUrl,
    store,
    pixel,
    number,
    WhatsApp,
    bkash,
    Dhaka,
    Outside,
  };
  const submitDomain = () => {
    if (!store) {
      return toast.error("Store Name Require");
    }
    setloading(true);
    if (!domain) {
      return setloading(false);
    }

    AxiosUrl.post(`/add-domain/${uid}`, fromData)
      .then((data) => {
        if (data.data === "Domain Already Exist") {
          toast.error(data.data);
        }
        if (data.data === "Successful") {
          setOpenModal(false);
        }
        if (data.data === "One store allow per account") {
          toast.error(data.data);
        }
        setloading(false);
        setDomain("");
      })
      .catch((error) => {});
  };
  const cdnImage = async () => {
    const image = new FormData();
    image.append("photo", photo);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload/desc";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Logo Uploaded");
        setImageUrl(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (photo) {
      cdnImage();
    }
  }, [photo]);

  return (
    <div className="modalBackground">
      <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setOpenModal(false)}>X</button>
        </div>
        <div className="category-upload">
          <h3>Upload Logo Max Width 2 Inch</h3>
          <input
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
            type="file"
            className="CmFile"
            name="photo"
          ></input>
        </div>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Store Name"
              id="fullWidth"
              onChange={(e) => setStore(e.target.value)}
              value={store}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Domain Name"
              id="fullWidth"
              onChange={(e) => setDomain(e.target.value)}
              value={domain}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              placeholder="Optional Facebook Pixel Ex: 1307091900148979"
              fullWidth
              label="Facebook Pixel ID"
              id="fullWidth"
              onChange={(e) => setPixel(e.target.value)}
              value={pixel}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Mobile Number Ex: 01711000000"
              fullWidth
              label="Mobile Number"
              id="fullWidth"
              onChange={(e) => setNumber(e.target.value)}
              value={number}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="WhatsApp Number Ex: 01711000000"
              fullWidth
              label="WhatsApp Number"
              id="fullWidth"
              onChange={(e) => setWhatsApp(e.target.value)}
              value={WhatsApp}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Bkash Ex: 01711000000"
              fullWidth
              label="Bkash Number"
              id="fullWidth"
              onChange={(e) => setBkash(e.target.value)}
              value={bkash}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Delivery Charge: 70"
              fullWidth
              label="Delivery Charge Dhaka"
              id="fullWidth"
              onChange={(e) => setDhaka(e.target.value)}
              value={Dhaka}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Delivery Charge: 130"
              fullWidth
              label="Delivery Charge OutSide"
              id="fullWidth"
              onChange={(e) => setOutside(e.target.value)}
              value={Outside}
            />
          </Box>
        </div>

        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              submitDomain();
            }}
          >
            {loading ? "Loading.." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
