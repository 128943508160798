import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { FiPlus } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import axios from "axios";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BannerImg from "../../img/banner.png";
import "./EditBanner.css";

const EditBanner = () => {
  const location = useLocation();
  const data = location.state;
  const uid = localStorage.getItem("id");
  const navigate = useNavigate();

  const [banners, setBanners] = useState(data.Data);

  const [link, setLink] = useState(
    data.Data?.reduce((acc, banner) => {
      acc[banner.id] = banner.url || "";
      return acc;
    }, {}) || {}
  );

  function generateRandomNumber() {
    const min = 10000000;
    const max = 99999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  }

  const handleAddBanner = () => {
    const newBanner = { id: generateRandomNumber(), image: null, url: "" };
    setBanners([...banners, newBanner]);
  };

  const handleFileChange = (e, id) => {
    const image = new FormData();
    image.append("photo", e.target.files[0]);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload/banner";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Image uploaded to CDN");
        const newBanners = banners.map((banner) =>
          banner.id === id ? { ...banner, image: res.data } : banner
        );

        setBanners(newBanners);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleURLAdd = (e, id) => {
    const newBanners = banners.map((banner) =>
      banner.id === id ? { ...banner, url: e.target.value } : banner
    );

    setBanners(newBanners);
    setLink((prevValues) => ({
      ...prevValues,
      [id]: e.target.value,
    }));
  };

  const deleteImageHandler = (id) => {
    const newBanners = banners.filter((banner) => banner.id !== id);
    setBanners(newBanners);
  };

  const handleSubmit = () => {
    const isEnable = banners.every((banner) => banner.image !== null);
    if (!isEnable) {
      toast.error("Banner image require.");
      return;
    }
    AxiosUrl.post(`edit-banner/${uid}`, { bid: data._id, banners })
      .then((res) => {
        setBanners([]);
        navigate("/banner-list");
      })
      .catch((error) => toast.error(error.response.data));
  };
  return (
    <div className="add-banner-container">
      <ToastContainer />
      {banners.length > 0 &&
        banners.map((banner) => (
          <div className="image-div" key={banner.id}>
            <div className="image-container">
              <img
                src={banner.image ? banner.image : BannerImg}
                alt="banner-image"
              />
            </div>
            <div className="upload-container">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, banner.id)}
              />
              <div className="upload-container-input">
                <input
                  type="text"
                  placeholder="Enter Link URL"
                  value={link[banner.id] || ""}
                  onChange={(e) => handleURLAdd(e, banner.id)}
                  required
                />
              </div>
            </div>
            <div
              className="add-banner-close-btn"
              onClick={() => deleteImageHandler(banner.id)}
            >
              <MdClose />
            </div>
          </div>
        ))}
      <div className="add-banner-btn-groups">
        <Button
          variant="contained"
          color="primary"
          endIcon={<FiPlus />}
          onClick={handleAddBanner}
        >
          Add Banner
        </Button>
        {banners.length > 0 && (
          <Button
            variant="contained"
            color="secondary"
            className="add-banner-update-btn"
            onClick={handleSubmit}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditBanner;
