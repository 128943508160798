import React, { useState } from "react";

const DeliveryCharge = ({ data, setData }) => {
  const [insideDhaka, setInsideDhaka] = useState(
    data.DeliveryCharge.insideDhaka
  );

  const [outsideDhaka, setOutsideDhaka] = useState(
    data.DeliveryCharge.outsideDhaka
  );

  const handleDeliveryCharge = (e) => {
    e.preventDefault();
    setData({
      ...data,
      DeliveryCharge: {
        ...data.DeliveryCharge,
        insideDhaka: insideDhaka,
        outsideDhaka: outsideDhaka,
      },
    });
  };

  return (
    <form
      className="container delivery-container"
      onSubmit={handleDeliveryCharge}
      style={{ marginTop: "50px" }}
    >
      <div className="delivery_title">ডেলিভারি চার্জ</div>
      <div className="delivery_input-group">
        <label htmlFor="insideDhaka">ঢাকার ভিতরে</label>
        <input
          type="text"
          id="insideDhaka"
          name="insideDhaka"
          placeholder="Enter Delivery Charge"
          value={insideDhaka}
          onChange={(e) => setInsideDhaka(parseInt(e.target.value))}
        />
      </div>
      <div className="delivery_input-group">
        <label htmlFor="outsideDhaka">ঢাকার বাহিরে</label>
        <input
          type="text"
          id="outsideDhaka"
          name="outsideDhaka"
          placeholder="Enter Delivery Charge"
          value={outsideDhaka}
          onChange={(e) => setOutsideDhaka(parseInt(e.target.value))}
        />
      </div>
      <button className="landing-page-edit-btn">Edit</button>
    </form>
  );
};

export default DeliveryCharge;
