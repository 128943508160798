import React, { useState, useEffect } from "react";
import "./Domain.css";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DomainModal from "../DomainModal/DomainModal";
import EditDomainModal from "../EditDomainModal/EditDomainModal";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

function Domain() {
  const uid = localStorage.getItem("id");
  const [domain, setDomain] = useState([]);
  const [open, setOpenModal] = useState(false);
  const [eopen, setEOpenModal] = useState(false);
  const [edit, setEdit] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [photo, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDomain();
    setRefresh(false);
  }, [open, refresh, eopen]);

  const getDomain = () => {
    setLoading(true);
    AxiosUrl.get(`/get-domain/${uid}`)
      .then((res) => {
        setDomain(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const deleteDomain = (domain) => {
    AxiosUrl.post(`/delete-domain/${uid}`, { domain })
      .then((res) => {
        toast.success("Deleted..");
        setRefresh(true);
      })
      .catch(() => {});
  };

  return (
    <div style={{ height: "80vh" }}>
      <div className="domain-Video">
        <div>
          How dose it work ➡️{" "}
          <a href="#" target="_blank">
            Video Link
          </a>{" "}
        </div>
      </div>
      <ToastContainer />
      {open && <DomainModal setOpenModal={setOpenModal} />}
      {eopen && <EditDomainModal setEOpenModal={setEOpenModal} data={edit} />}
      <div className="category-main">
        <div className="create-category">
          <Button variant="contained" onClick={() => setOpenModal(true)}>
            Add Store
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Store Logo</TableCell>
                <TableCell align="right" style={{ textAlign: "center" }}>
                  Store Name
                </TableCell>
                <TableCell align="right">Domain Name</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Edit</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <ReactLoading
                  type="cylon"
                  color="rgb(226,115,29)"
                  height={60}
                  width={60}
                />
              )}
              {!loading && domain.length <= 0 ? (
                <div className="no-data-available">No data available</div>
              ) : (
                domain.map((data) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={data._id}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textAlign: "center" }}
                      >
                        {" "}
                        <img style={{ maxWidth: "199px" }} src={data.logo} />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textAlign: "center" }}
                      >
                        {data.store}
                      </TableCell>
                      <TableCell align="right">{data.domain}</TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            padding: "0px",
                          }}
                        >
                          {data.status === "0" ? "Deactive" : "Active"}
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "#318e31d9",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => {
                            setEdit(data);
                            setEOpenModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => deleteDomain(data.domain)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Domain;
