import React from "react";

const Pricing = ({ data }) => {
  return (
    // <div className="pricing">
    //   <div className="container">
    //     <div className="pricing__container">
    //       <h1 className="pricing__container__title">Pricing</h1>
    //       <div className="pricing__cards__container">
    //         {data.courses.length > 0 &&
    //           data.courses.map((card) => (
    //             <PricingCard key={card.id} card={card} />
    //           ))}
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div>
      {data.courses.length > 0 &&
        data.courses.map((data) => {
          return <div>{data.title}</div>;
        })}
    </div>
  );
};

const PricingCard = ({ card }) => {
  return (
    <div className="pricing__card__container">
      <h3>{card.title}</h3>
      <h1>{card.price}</h1>
      <h3>{card.delivery_charge}</h3>
    </div>
  );
};

export default Pricing;
