import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Description from "./Description";
import Features from "./Features";
import Effectiveness from "./Effectiveness";
import EffectivenessCards from "./EffectivenessCards";
import { EffectivenessContentList } from "./EffectivenessContentList";
import Problems from "./Problems";
import Decisions from "./Decisions";
import { Questions } from "./Questions";
import Variantions from "./Variations";
import myAxios from "../../AxiosUrl";
import "../landing-1.css";
import DeliveryCharge from "./DeliveryCharge";
import PageName from "./PageName";
import { toast, ToastContainer } from "react-toastify";

const UpdateLanding2Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state);
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const updateLanding = () => {
    setLoading(true);
    if (!data.Name) {
      toast.error("Please set page name");
      setLoading(false);
      return;
    }
    myAxios
      .post(`update-landing/${uid}`, { ...data, lid: location.state._id })
      .then((res) => {
        setLoading(false);
        navigate("/landing-list");
      })
      .catch((err) => console.log(err.response.data))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <ToastContainer />
      <div className="landing-page-main-container">
        <PageName data={data} setData={setData} />
        <Description data={data} setData={setData} />
        <Variantions data={data} setData={setData} />
        <DeliveryCharge data={data} setData={setData} />
        <Features data={data} setData={setData} />
        <Effectiveness data={data} setData={setData} />
        <EffectivenessCards data={data} setData={setData} />
        <EffectivenessContentList data={data} setData={setData} />
        <Problems data={data} setData={setData} />
        <Questions data={data} setData={setData} />
        <Decisions data={data} setData={setData} />
      </div>
      <div className="save-landing" onClick={updateLanding}>
        {loading ? "LOADING.." : "UPDATE PAGE"}
      </div>
    </>
  );
};

export default UpdateLanding2Home;
