import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./OrderList.css";
import myAxios from "../AxiosUrl";
import OrderViewModal from "../OrderViewModal/OrderViewModalTeam";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PrintModal from "../PrintModal/PrintModal";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

function Order() {
  const teamid = localStorage.getItem("teamid");
  const Admin_Id = localStorage.getItem("Admin_Id");
  const navigate = useNavigate();
  const [Totalpage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setPageLimit] = useState("all");
  const [pData, setPdata] = useState([]);
  const [modalData, setmodalData] = useState([]);
  const [open, setOpenModal] = useState(false);
  const [searchN, setSearchNum] = useState("");
  const [FilterDate, setFilterDate] = useState("Today");
  const [orderStatus, setOrderStatus] = useState();
  const [refresh, setRefresh] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [printModalData, setPrintModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [ReceiveReturn, setReceiveReturn] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const PageNumber = (e, p) => {
    setLoading(true);
    setPage(p);
  };

  const handleToggleItem = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((item) => item !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  const OrderD = pData.filter((fdata) => {
    if (!orderStatus || orderStatus === "undefined") {
      return fdata;
    } else if (ReceiveReturn) {
      return fdata.CourierReturn === "no" && fdata.orderStatus === 6;
    } else {
      return fdata.orderStatus === orderStatus;
    }
  });

  const getData = () => {
    setLoading(true);
    const Nurl = `get-order-team?searchN=${searchN}&orderStatus=${orderStatus}&uid=${Admin_Id}&assignedTo=${teamid}&page=${page}&limit=${limit}&FilterDate=${FilterDate}`;
    myAxios
      .get(Nurl)
      .then((res) => {
        setPdata(res.data.data);
        setTotalPage(res.data.total);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AlartSubmit = (id) => {
    setRefresh(false);
    confirmAlert({
      title: "DELETE CONFIRMATION",
      message: "Are you sure to delete the product.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteOrder(id),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };
  const deleteOrder = (id) => {
    const orderDelete = `delete-order/${id}`;
    myAxios
      .delete(orderDelete)
      .then((res) => {
        setRefresh(true);
        toast.success("Deleted..");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, [refresh, searchN, page, limit, FilterDate]);

  return (
    <div>
      <ToastContainer />
      {open && (
        <OrderViewModal
          modalData={modalData}
          setOpenModal={setOpenModal}
          setRefresh={setRefresh}
        />
      )}
      {openPrint && (
        <PrintModal
          printModalData={printModalData}
          setOpenPrint={setOpenPrint}
          setRefresh={setRefresh}
        />
      )}
      <section>
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <div className="filter-2nd">
          <div className="sort-day">
            <select
              name="filterData"
              className="date-filter"
              onChange={(e) => setFilterDate(e.target.value)}
            >
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="7 Days">Week</option>
              <option value="Month">Month</option>
              <option value="Last Month">Last Month</option>
              <option value="all">All Orders</option>
            </select>
          </div>
          <h3
            style={{ color: "rgb(88, 82, 82)", margin: "0px", padding: "0px" }}
          >
            {OrderD.length}
          </h3>
          <div>
            <label for="ShowOrders">Show Order: </label>
            <select
              name="cars"
              className="ShowOrder"
              onChange={(e) => setPageLimit(e.target.value)}
            >
              <option value="all">All</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>
        </div>
        <div className="fillter-main">
          <div className="filter-status">
            <ul>
              <li
                className={!orderStatus ? "active" : ""}
                onClick={() => setOrderStatus()}
              >
                All-Order
              </li>
              <li
                className={orderStatus === 1 ? "active" : ""}
                onClick={() => setOrderStatus(1)}
              >
                New
              </li>
              <li
                className={orderStatus === 2 ? "active" : ""}
                onClick={() => setOrderStatus(2)}
              >
                Call-Done
              </li>
              <li
                className={orderStatus === 7 ? "active" : ""}
                onClick={() => setOrderStatus(7)}
              >
                Call Not-Receiced
              </li>
              <li
                className={orderStatus === 8 ? "active" : ""}
                onClick={() => setOrderStatus(8)}
              >
                Ask-Advance
              </li>
              <li
                className={orderStatus === 9 ? "active" : ""}
                onClick={() => setOrderStatus(9)}
              >
                Ship-Later
              </li>
              <li
                className={orderStatus === 3 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(3);
                  setReceiveReturn(false);
                }}
              >
                Courier-Done
              </li>
              <li
                className={orderStatus === 10 ? "active" : ""}
                onClick={() => setOrderStatus(10)}
              >
                Waiting
              </li>

              <li
                className={orderStatus === 4 ? "active" : ""}
                onClick={() => setOrderStatus(4)}
              >
                Print
              </li>
              <li
                className={orderStatus === 5 ? "active" : ""}
                onClick={() => setOrderStatus(5)}
              >
                Cancel
              </li>
              <li
                className={orderStatus === 6 ? "active" : ""}
                onClick={() => setOrderStatus(6)}
              >
                Return
              </li>
              <li
                className={orderStatus === 11 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(11);
                  setReceiveReturn(false);
                }}
              >
                Overseas
              </li>

              <li
                className={ReceiveReturn === true ? "active" : ""}
                onClick={() => {
                  setReceiveReturn(true);
                  setOrderStatus(100);
                }}
              >
                Pending-Return
              </li>
            </ul>
          </div>

          <div className="search">
            <form role="search">
              <input
                onChange={(e) => {
                  setSearchNum(e.target.value);
                  setLoading(true);
                }}
                className="searchin"
                type="search"
                placeholder="Search Number.."
                autoFocus
                required
                value={searchN}
              />
            </form>
            {/* <form>
              <label for="gsearch">Search Number: </label>
              <input type="search" id="gsearch" name="gsearch" />
              <input type="submit" />
            </form> */}
          </div>
        </div>

        <div className="tbl-header">
          <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Amount</th>
                <th>Item</th>
                <th>Status</th>
                <th>Edit / Print</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="tbl-content">
          <table cellPadding="0" cellSpacing="0" border="0">
            <tbody>
              {OrderD.map((item, index) => {
                let orderStatus = "";
                if (item.orderStatus === 1) {
                  orderStatus = "New Order";
                } else if (item.orderStatus === 2) {
                  orderStatus = "Call Done";
                } else if (item.orderStatus === 3) {
                  orderStatus = "Courier Done";
                } else if (item.orderStatus === 4) {
                  orderStatus = "Print Done";
                } else if (item.orderStatus === 5) {
                  orderStatus = "Cancel";
                } else if (item.orderStatus === 6) {
                  orderStatus = "Return";
                } else if (item.orderStatus === 7) {
                  orderStatus = "Call Not-Receice";
                } else if (item.orderStatus === 8) {
                  orderStatus = "Ask-Advance";
                } else if (item.orderStatus === 9) {
                  orderStatus = "Ship-Later";
                } else if (item.orderStatus === 10) {
                  orderStatus = "Waiting";
                } else if (item.orderStatus === 11) {
                  orderStatus = "Overseas";
                }

                return (
                  <>
                    <tr key={item._id}>
                      <td
                        style={{ textAlign: "left" }}
                        // onClick={() => {
                        //   setmodalData(item);
                        //   setOpenModal(true);
                        //   setRefresh(false);
                        // }}
                      >
                        <button
                          style={{
                            marginRight: "5px",
                            backgroundColor: "#8167ff",
                            padding: "2px",
                            border: "none",
                            borderRadius: "5px",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(item._id)}
                            onChange={() => handleToggleItem(item._id)}
                          />
                        </button>
                        {item.cName}
                      </td>
                      <td
                        onClick={() => {
                          setmodalData(item);
                          setOpenModal(true);
                          setRefresh(false);
                        }}
                      >
                        {item.cMobile}
                      </td>
                      <td
                        onClick={() => {
                          setmodalData(item);
                          setOpenModal(true);
                          setRefresh(false);
                        }}
                      >
                        {item.totalPrice}
                      </td>
                      <td
                        onClick={() => {
                          setmodalData(item);
                          setOpenModal(true);
                          setRefresh(false);
                        }}
                      >
                        {item.item}
                      </td>
                      <td
                        onClick={() => {
                          setmodalData(item);
                          setOpenModal(true);
                          setRefresh(false);
                        }}
                      >
                        <button
                          className={
                            item.orderStatus === 6 ? "colorRed" : "td-button"
                          }
                        >
                          {orderStatus}
                        </button>
                      </td>
                      <td>
                        <button
                          className="td-button"
                          onClick={() => {
                            navigate("/edit-team-order", { state: item });
                          }}
                        >
                          Edit
                        </button>

                        {/* <button
                          className="td-button delete"
                          onClick={() => AlartSubmit(item._id)}
                        >
                          Delete
                        </button> */}
                        <button
                          className="td-button"
                          onClick={() => {
                            // navigate("/print", { state: item });
                            setPrintModalData(item);
                            setOpenPrint(true);
                            setRefresh(false);
                          }}
                        >
                          Print
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
      {Totalpage > 0 && (
        <div className="pagination-order">
          <Stack spacing={2}>
            <Pagination
              count={Totalpage}
              color="primary"
              onChange={PageNumber}
            />
          </Stack>
        </div>
      )}
    </div>
  );
}

export default Order;
