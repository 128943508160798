import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosUrl from "../../../AxiosUrl";
import "./AddDomain.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  overflowY: "auto",
  "@media (min-width: 600px)": {
    width: 400,
  },
};

function AddDomainModal({ setAddDomainModal, setRefresh, Refresh }) {
  const [domain, setDomain] = useState("");
  const [dnsType, setDnsType] = useState("Own DNS"); // Default selection
  const [loading, setLoading] = useState(false);

  const uid = localStorage.getItem("id");

  const fromData = {
    uid,
    sub: "false",
    dnsType,
    domain: domain
      .replace(/^(https?:\/\/)?(www\.)?/, "")
      .replace(/\/+$/, "")
      .toLowerCase(),
  };

  function isValidDomain(domain) {
    var domainRegex =
      /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    return domainRegex.test(domain);
  }

  const submitDomain = () => {
    setLoading(true);
    if (!isValidDomain(domain)) {
      toast.error("Invalid domain.");
      setLoading(false);
      return;
    }
    if (!domain) {
      toast.error("Domain is required.");
      setLoading(false);
      return;
    }

    AxiosUrl.post(`/add-domain-new/${uid}`, fromData)
      .then((res) => {
        toast.success(res.data);
        setRefresh(Refresh + 1);
        setLoading(false);
        setAddDomainModal(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={setAddDomainModal}
      onClose={() => setAddDomainModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box sx={style}>
        <p
          style={{
            border: "2px solid #cccccc70",
            padding: "10px",
            textAlign: "center",
            lineHeight: "30px",
            borderRadius: "7px",
            background: "aliceblue",
          }}
        >
          আপনার নিজের হোস্টিং থেকে যদি সাব ডোমেইন অ্যাড করতে চান যেমন
          shop.yourdomain.com তাহলে এই 103.133.141.4 ইপিতে একটি এ রেকর্ড করতে
          হবে আর যদি ফুল ডোমেইন আমাদের DNS এ নিয়ে আসতে চান তাহলে {"("}
          ns1.ecombd.org এবং ns2.ecombd.org {")"} Name Server সেট করতে হবে -
          আমরা রিকমেন্ড করি আমাদের DNS কারণ 99% আপ টাইম পাবেন{" "}
        </p>
        <div className="title">
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Domain Name"
              placeholder="Domain Example: example.com"
              onChange={(e) => setDomain(e.target.value)}
              value={domain}
              error={!isValidDomain(domain) && domain !== ""}
            />
          </Box>
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <Select
              fullWidth
              value={dnsType}
              onChange={(e) => setDnsType(e.target.value)}
              displayEmpty
            >
              <MenuItem value="Own DNS">Own DNS</MenuItem>
              <MenuItem value="Ecomtech DNS">EcomTech DNS</MenuItem>
            </Select>
          </Box>
        </div>
        <div style={{ margin: 20, textAlign: "center" }}>
          <Button
            onClick={() => setAddDomainModal(false)}
            variant="outlined"
            style={{
              marginRight: 20,
              background: "red",
              color: "white",
              border: "red",
            }}
          >
            Cancel
          </Button>
          <Button onClick={submitDomain} variant="contained" disabled={loading}>
            {loading ? "Loading..." : "Continue"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default AddDomainModal;
