import React, { useState } from "react";
import { DescriptionCard } from "../utils/Cards";
import ImageCard from "../utils/ImageCard";
import Slider from "../utils/Slider";
import {
  ChangeHeroModal,
  ChangeDescriptionModal,
  ChangeSliderModal,
} from "../../LandingPageModals/LandingPageModals";

const Description = ({ data, setData }) => {
  const [open, setOpen] = useState(false);
  const [openDescription, setOpenDescription] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);
  const [openSlider, setOpenSlider] = useState(false);
  return (
    <div className="description">
      <div className="container">
        <div className="description__container">
          <div className="description__image">
            <button
              className="landing-page-edit-btn"
              onClick={() => setOpen(true)}
            >
              Edit
            </button>
            <ImageCard
              src={updatedImage ? updatedImage : data.HeroImage}
              alt={data.HeroImage}
            />
            <div className="description__image__slider">
              <Slider>
                {data.SubSliders.length > 0 &&
                  data.SubSliders.map((image) => (
                    <img key={image.id} src={image.src} alt="slider-image" />
                  ))}
              </Slider>
            </div>
            <button
              className="landing-page-edit-btn"
              onClick={() => setOpenSlider(true)}
            >
              Edit
            </button>
          </div>

          <div className="description__text">
            <button
              className="landing-page-edit-btn"
              onClick={() => setOpenDescription(true)}
              style={{ marginBottom: "20px" }}
            >
              Edit
            </button>
            <DescriptionCard data={data} />
          </div>
        </div>
      </div>
      <ChangeHeroModal
        data={data}
        setData={setData}
        open={open}
        setOpen={setOpen}
        updatedImage={updatedImage}
        setUpdatedImage={setUpdatedImage}
      />
      <ChangeDescriptionModal
        data={data}
        setData={setData}
        openDescription={openDescription}
        setOpenDescription={setOpenDescription}
      />

      <ChangeSliderModal
        data={data}
        setData={setData}
        openSlider={openSlider}
        setOpenSlider={setOpenSlider}
      />
    </div>
  );
};

export default Description;
