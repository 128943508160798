import React, { useState, useEffect } from "react";
import "./Team.css";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditTeamModal from "../EditTeamModal/EditTeamModal";
import TeamModal from "../TeamModal/TeamModal";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

function Team() {
  const uid = localStorage.getItem("id");
  const [teamMembers, setTeamMembers] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editMember, setEditMember] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchTeamMembers();
  }, [openEditModal, refresh, openAddModal]);

  const fetchTeamMembers = () => {
    setLoading(true);
    AxiosUrl.get(`/get-team/${uid}`)
      .then((res) => {
        setTeamMembers(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const deleteTeamMember = (mobile) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this team member?"
    );
    if (confirmDelete) {
      setLoading(true);
      setRefresh(false);
      AxiosUrl.post(`/delete-team/${uid}`, { mobile })
        .then((res) => {
          setLoading(false);
          if (res.data === "Delete succes..") {
            toast.success(res.data);
            setRefresh(true);
          }
        })
        .catch(() => {
          toast.error("Failed to delete the team member.");
        });
    }
  };

  const copyLinkToClipboard = () => {
    const loginLink = "https://seller.ecomtechbd.com/teamlogin";
    navigator.clipboard.writeText(loginLink);
    toast.success("কপি হয়েছে অন্য ব্রাউজারে পেস্ট করুন !");
  };

  return (
    <>
      <div className="team-video">
        <div>
          কিভাবে টীম মেম্বার অ্যাড করবেন ➡️{" "}
          <a href="#" target="_blank">
            Video Link
          </a>{" "}
        </div>
      </div>
      <ToastContainer />
      {openEditModal && (
        <EditTeamModal setOpenEditModal={setOpenEditModal} data={editMember} />
      )}
      {openAddModal && <TeamModal setOpenAddModal={setOpenAddModal} />}
      <div className="team-main">
        <div className="team-actions">
          <Button
            style={{ marginRight: "12px", color: "#fff", border: "1px solid" }}
            onClick={copyLinkToClipboard}
          >
            Copy LogIn Link
          </Button>
          <Button variant="contained" onClick={() => setOpenAddModal(true)}>
            Add Member
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Number</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <ReactLoading
                  type="cylon"
                  color="rgb(226,115,29)"
                  height={60}
                  width={60}
                />
              )}
              {!loading && teamMembers.length <= 0 ? (
                <div className="team-no-data">No data available</div>
              ) : (
                teamMembers.map((data) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={data._id}
                    >
                      <TableCell component="th" scope="row">
                        {data.name}
                      </TableCell>
                      <TableCell align="center">{data.mobile}</TableCell>
                      <TableCell align="center">
                        <Button
                          style={{
                            backgroundColor: `${
                              data.userStatus === 0 ? "green" : "red"
                            }`,
                            color: "#fff",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                        >
                          {data.userStatus === 0 ? "Active" : "Deactive"}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          style={{
                            backgroundColor: "rgb(23, 98, 23)",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => {
                            setEditMember(data);
                            setOpenEditModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => deleteTeamMember(data.mobile)}
                        >
                          {loading ? "Deleting.." : "Delete"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Team;
