import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";

function Login() {
  const [LoginLoading, setLoginLoading] = useState(false);
  const [mobile, setMobile] = useState();
  const [password, setPassword] = useState();

  const navigate = useNavigate();
  const handleLogin = async (event) => {
    setLoginLoading(true);
    axios
      .post(`${URL}login-team`, { mobile, password })
      .then((res) => {
        setLoginLoading(false);
        if (res.data.message === "Password not match") {
          toast.error("Password not match");
        }
        if (res.data.message === "User not found") {
          toast.error("User not found..");
        }

        if (res.data.refreshToken) {
          localStorage.setItem("token", res.data.refreshToken);
          localStorage.setItem("teamid", res.data.user._id);
          localStorage.setItem("Admin_Id", res.data.user.Admin_Id);
          navigate("/team-order");
        }
      })
      .catch((err) => toast.error(err.response.data));
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="header-login">
        <div className="logo-main">
          <h2>RUSUBD.COM</h2>
        </div>
        <div className="login-main">
          <ul>
            <li>SIGNUP</li>
            <li>LOGIN</li>
          </ul>
        </div>
      </div>
      <div className="login-signup-main">
        <div className="login-or-signup">
          <div className="login-inputs">
            <input
              type="number"
              placeholder="Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
            />
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button className="login-btn" onClick={() => handleLogin()}>
              {LoginLoading ? "Loading.." : "LOG IN"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
