import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./Footer.css";

const CustomIcon = ({ iconName }) => (
  <Icon icon={iconName} style={{ color: "white", fontSize: "20px" }} />
);

const FooterIcon = ({ iconName, url = "#" }) => {
  return (
    <Link to={url} className="footer-link-item">
      <CustomIcon iconName={iconName} />
    </Link>
  );
};

const FooterText = ({ text, url = "#" }) => {
  return (
    <Link to={url} className="footer-link-item">
      {text}
    </Link>
  );
};

const Footer = () => {
  return (
    <>
      <div className="login-footer-main">
        <div className="copyright">
          © {new Date().getFullYear()}{" "}
          <a href="https://ecomtechbd.com" target="blank">
            Ecom Tech
          </a>
          . All Right Reserved
        </div>

        <div className="make-money">
          <a href="https://wa.me/+8801711026578" target="blank">
            WhatsApp
          </a>
        </div>

        <div className="login-footer-right">
          <div className="login-footer-facebook">
            <a href="https://www.facebook.com/ecomtechbd" target="blank">
              FaceBook
            </a>
          </div>
          <div className="login-footer-youtube">
            <a href="https://www.youtube.com/@ecomtechbd" target="blank">
              YouTube
            </a>
          </div>
          <div className="login-footer-youtube">
            <a href="https://www.instagram.com/ecomtechbd/" target="blank">
              Instagram
            </a>
          </div>
          <div className="login-footer-youtube">
            <a href="https://bd.linkedin.com/company/ecomtechbd" target="blank">
              Linkedin
            </a>
          </div>
        </div>
      </div>
      {/* footer section end */}
    </>
  );
};

export default Footer;
