import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import Logo from "../../img/logo.png";
import ReactPixel from "react-facebook-pixel";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the initial tab based on the URL query parameter
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get("tab") || "login";

  const [signup, setSignup] = useState(initialTab === "signup");
  const [login, setLogin] = useState(initialTab === "login");

  const [forgot, setForgot] = useState(false);
  const [forgotVerify, setForgotVerify] = useState(false);
  const [VerifyDone, setVerifyDone] = useState(false);

  const [SignupVerify, setSignupVerify] = useState(false);
  const [SignupLoading, setSignupLoading] = useState(false);
  const [VerifyCode, setVerifyCode] = useState("");
  const [user_id, setUserID] = useState(null);

  const [LoginLoading, setLoginLoading] = useState(false);
  const [lMobile, setLMobile] = useState("");
  const [lpass, setLpass] = useState("");

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");

  // Update URL query parameter when switching tabs
  const handleTabChange = (tab) => {
    if (tab === "login") {
      setSignup(false);
      setLogin(true);
      navigate("?tab=login");
    } else if (tab === "signup") {
      setSignup(true);
      setLogin(false);
      navigate("?tab=signup");
    }
    setForgot(false);
    setForgotVerify(false);
    setVerifyDone(false);
    setSignupVerify(false);
  };

  const handleLogin = async () => {
    setLoginLoading(true);
    axios
      .post(`${URL}login`, { lMobile, lpass })
      .then((res) => {
        setLoginLoading(false);
        if (res.data.message === "Password not match") {
          toast.error("Password not match");
        } else if (res.data.message === "User not found") {
          toast.error("User not found..");
        } else if (res.data.refreshToken) {
          localStorage.setItem("token", res.data.refreshToken);
          localStorage.setItem("id", res.data.UserId);
          localStorage.setItem("IsSuper", res.data.IsSuper);
          localStorage.setItem("IsTeam", res.data.IsTeam);
          localStorage.setItem("CreateStore", res.data.CreateStore);

          if (res.data.Role === 0) {
            window.location.reload("/dashboard");
          } else if (res.data.Role === 1) {
            window.location.reload("/super-admin-dashboard");
          } else if (res.data.Role === 2) {
            window.location.reload("/dashboard");
          }
        }
      })
      .catch((err) => {
        setLoginLoading(false);
        toast.error(err.response.data);
      });
  };

  const handleSignup = async () => {
    setSignupLoading(true);

    if (!mobile || !password || !username) {
      toast.error("Mobile, username, and password are required.");
      setSignupLoading(false);
      return;
    }
    if (mobile.length !== 11) {
      toast.error("১১ সংখ্যার মোবাইল নম্বর লিখুন");
      setSignupLoading(false);
      return;
    }
    axios
      .post(`${URL}signup`, { mobile, password, username })
      .then((res) => {
        setSignupLoading(false);
        if (res.data === "User already exists") {
          toast.error("User already exists");
        } else if (res.data.refreshToken) {
          localStorage.setItem("token", res.data.refreshToken);
          localStorage.setItem("id", res.data.UserId);
          localStorage.setItem("IsSuper", res.data.IsSuper);
          localStorage.setItem("IsTeam", res.data.IsTeam);
          localStorage.setItem("CreateStore", false);
          setSignup(false);
          setSignupVerify(true);
          setUserID(res.data.UserId);
          toast.success("Signup successful! Please verify your OTP.");
        }
      })
      .catch((err) => {
        setSignupLoading(false);
        toast.error(err.response.data);
      });
  };

  const handleOtpVerify = async () => {
    if (!VerifyCode) {
      toast.error("Verification code is required.");
      return;
    }

    setLoginLoading(true);
    axios
      .post(`${URL}signup-otp/validate`, { userId: user_id, otp: VerifyCode })
      .then((res) => {
        console.log(res.data);
        setLoginLoading(false);
        if (res.data === "OTP is valid") {
          toast.success("OTP verified successfully!");
          window.location.reload("/dashboard");
        } else if (res.data === "OTP not found") {
          toast.error("OTP not found");
        } else if (res.data === "OTP has expired") {
          toast.error("OTP has expired");
        } else if (res.data === "OTP is invalid") {
          toast.error("OTP is invalid");
        }
      })
      .catch((err) => {
        setLoginLoading(false);
        toast.error(err.response.data);
      });
  };

  useEffect(() => {
    let is_running = false;

    if (is_running === true) {
      return;
    }

    is_running = true;
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init("6874141362695006", options);
    ReactPixel.pageView();
    is_running = false;
  }, []);

  return (
    <div className="login-container">
      <ToastContainer />
      {/* header section start */}
      <div className="header-login">
        <div className="logo-main">
          <a href="https://ecomtechbd.com">
            <img src={Logo} width={150} alt="Logo" />
          </a>
        </div>
        <div className="login-main">
          <ul>
            <li onClick={() => handleTabChange("signup")}>SIGNUP</li>
            <li onClick={() => handleTabChange("login")}>LOGIN</li>
          </ul>
        </div>
      </div>
      {/* header section end */}

      {/* login and signup section start */}
      <div className="login-signup-main">
        <div className="login-or-signup">
          <div className="login-signup-tab">
            <div
              className={login ? "login-tab-active" : "login-tab"}
              onClick={() => handleTabChange("login")}
            >
              LogIn
            </div>
            <div
              className={signup ? "signup-tab-active" : "signup-tab"}
              onClick={() => handleTabChange("signup")}
            >
              Signup
            </div>
          </div>
          {login && (
            <div className="login-inputs">
              <input
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setLMobile(e.target.value)}
                value={lMobile}
              />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setLpass(e.target.value)}
                value={lpass}
              />
              <button className="login-btn" onClick={handleLogin}>
                {LoginLoading ? "Loading.." : "LOG IN"}
              </button>
              <p
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </p>
            </div>
          )}
          {signup && !SignupVerify && (
            <div className="signup-inputs">
              <input
                type="text"
                placeholder="Your Name"
                onChange={(e) => setUserName(e.target.value)}
              />
              <input
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setMobile(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="login-btn" onClick={handleSignup}>
                {SignupLoading ? "Loading.." : "SIGN UP"}
              </button>
            </div>
          )}
          {SignupVerify && (
            <div className="signup-inputs">
              <p
                style={{
                  textAlign: "center",
                  border: "1px solid #ccc",
                  padding: "7px",
                }}
              >
                আপনার মোবাইলে একটি ভেরিফিকেসন কোড পাঠানো হয়েছে কোডটি নিচে লিখুন{" "}
              </p>
              <input
                type="number"
                placeholder="Verification Code"
                onChange={(e) => setVerifyCode(e.target.value)}
                value={VerifyCode}
              />
              <button className="login-btn" onClick={handleOtpVerify}>
                {LoginLoading ? "Verifying.." : "Verify-Code"}
              </button>
              <p
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => {
                  // Optional: Implement resend OTP functionality here
                }}
              >
                Code-Not-Receive? Resend
              </p>
            </div>
          )}
          {/* You can keep other forms like forgot password and OTP verification here */}
        </div>
      </div>
      {/* login and signup section end */}
    </div>
  );
}

export default Login;
