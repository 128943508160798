import React, { useEffect, useState } from "react";
import "./OrderReport.css";
import DatePicker from "react-datepicker";
import { HiSearchCircle } from "react-icons/hi";
import myAxios from "../AxiosUrl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";

function OrderReport() {
  const [DateFrom, setDateFrom] = useState(new Date());
  DateFrom.setHours(0);
  DateFrom.setMinutes(0);
  DateFrom.setSeconds(0);

  const [DateTo, setDateTo] = useState(new Date());
  DateTo.setHours(0);
  DateTo.setMinutes(0);
  DateTo.setSeconds(0);

  const [DateSearch, setDateSearch] = useState(false);
  const [RefreshSe, setRefreshSe] = useState(1);
  const uid = localStorage.getItem("id");

  const [Totalpage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setPageLimit] = useState("all");
  const [pData, setPdata] = useState([]);
  const [CalData, setCalData] = useState();

  const [searchN, setSearchNum] = useState("");

  const [orderStatus, setOrderStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [FilterDate, setFilterDate] = useState("Today");
  const [selectAll, setSelectAll] = useState(false);
  console.log(CalData);
  const getData = () => {
    setLoading(true);
    const Nurl = `get-order-details?searchN=${searchN}&orderStatus=${orderStatus}&uid=${uid}&page=${page}&limit=${limit}&FilterDate=${FilterDate}&DateFrom=${DateFrom}&DateTo=${DateTo}&DateSearch=${DateSearch}`;
    myAxios
      .get(Nurl)
      .then((res) => {
        setPdata(res.data);
        setTotalPage(res.data.total);
        setLoading(false);
        setDateSearch(false);

        let areturn = 0;
        let cancel = 0;
        let qnt = 0;
        let delivered = 0;

        res.data.map((data) => {
          qnt += data.qnt;
          cancel += data.cancel;
          areturn += data.return;
          delivered += data.delivered;
        });
        setCalData({
          return: areturn,
          cancel,
          qnt,
          delivered,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, [FilterDate, RefreshSe]);

  return (
    <div className="OrderReport">
      <div className="filter-2nd">
        <div className="sort-day">
          <select
            name="filterData"
            className="date-filter"
            onChange={(e) => setFilterDate(e.target.value)}
          >
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
            <option value="7 Days">Week</option>
            <option value="Month">Month</option>
            <option value="Last Month">Last Month</option>
            <option value="all">All Orders</option>
          </select>
        </div>

        <div className="date-date-Picker">
          <DatePicker
            selected={DateFrom}
            onChange={(date) => setDateFrom(date)}
          />
          <p style={{ paddingRight: "10px" }}>To</p>
          <DatePicker selected={DateTo} onChange={(date) => setDateTo(date)} />
          <HiSearchCircle
            className="search-date"
            onClick={() => {
              setDateSearch(true);
              setRefreshSe(RefreshSe + 1);
            }}
          />
        </div>

        <h3
          style={{
            color: "rgb(88, 82, 82)",
            margin: "0px",
            padding: "0px",
            fontSize: "15px",
          }}
        >
          Sale: {CalData?.qnt}
        </h3>
        <h3
          style={{
            color: "rgb(88, 82, 82)",
            margin: "0px",
            padding: "0px",
            fontSize: "15px",
          }}
        >
          Delivery: {CalData?.delivered}
        </h3>
        <h3
          style={{
            color: "rgb(88, 82, 82)",
            margin: "0px",
            padding: "0px",
            fontSize: "15px",
          }}
        >
          Return: {CalData?.return}
        </h3>
        <h3
          style={{
            color: "rgb(88, 82, 82)",
            margin: "0px",
            padding: "0px",
            fontSize: "15px",
          }}
        >
          Cancel: {CalData?.cancel}
        </h3>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Photo</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Sale</TableCell>
              <TableCell align="center">Delivered</TableCell>
              <TableCell align="center">Return</TableCell>
              <TableCell align="center">Cancel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <ReactLoading
                type="cylon"
                color="rgb(226,115,29)"
                height={60}
                width={60}
              />
            )}
            {!loading && pData.length <= 0 ? (
              <div className="no-data-available">No data available</div>
            ) : (
              pData.map((data, index) => {
                function calculateReturnPercentage(shipped, returned) {
                  const res = Math.floor((returned / shipped) * 100);
                  return res;
                }
                return (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={index}
                  >
                    <TableCell component="th" scope="row" align="center">
                      <img src={data.image} height={80} width={80} />
                    </TableCell>
                    <TableCell align="center">{data.colorName}</TableCell>
                    <TableCell align="center">{data.qnt}</TableCell>
                    <TableCell align="center">{data.delivered}</TableCell>
                    <TableCell align="center">
                      {data.return} |{" "}
                      {data.return == 0
                        ? "0"
                        : calculateReturnPercentage(
                            data.delivered,
                            data.return
                          )}
                      %
                    </TableCell>
                    <TableCell align="center">{data.cancel}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default OrderReport;
