import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import Button from "@mui/material/Button";
import "./BannerList.css";
import AxiosUrl from "../AxiosUrl";
import { toast, ToastContainer } from "react-toastify";

const Banner = () => {
  const uid = localStorage.getItem("id");
  const navigate = useNavigate();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true); // Start with loading state as true
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setLoading(true); // Set loading to true when starting to fetch data
    AxiosUrl.get(`get-banner/${uid}`)
      .then((res) => {
        setBanner(res.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, [refresh]);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this banner?"
    );
    if (confirmDelete) {
      AxiosUrl.post(`delete-banner/${uid}`, { id })
        .then((res) => {
          setRefresh(refresh + 1);
          toast.success(res.data);
        })
        .catch((error) => console.log(error.response.data));
    }
  };

  const ActiveDeactive = async (data) => {
    AxiosUrl.post(`actve-banner/${uid}`, {
      id: data._id,
      status: data.status == 1 ? 0 : 1,
    })
      .then((res) => {
        setRefresh(refresh + 1);
        toast.success(res.data);
      })
      .catch((error) => console.log(error.response.data));
  };

  return (
    <div className="banner-main">
      <div className="Banner-Video">
        <div>
          এক্সপেন্স কিভাবে কাজ করে দেখুন ➡️{" "}
          <a href="#" target="_blank">
            VIDEO LINK
          </a>{" "}
        </div>
      </div>
      <ToastContainer />
      <div className="create-banner">
        {banner?.length == 0 && (
          <Button variant="contained" onClick={() => navigate("/add-banner")}>
            Add Banner
          </Button>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <div className="loading-container">
                <ReactLoading
                  type="cylon"
                  color="rgb(226,115,29)"
                  height={60}
                  width={60}
                />
              </div>
            )}
            {!loading && banner?.length <= 0 ? (
              <div className="no-data-available">No data available</div>
            ) : (
              banner?.map((data) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  key={data._id}
                >
                  <TableCell align="center">
                    <img src={data.Data[0].image} height={50} alt="Banner" />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      style={{
                        backgroundColor: data.status == 0 ? "red" : "green",
                        color: "#fff",
                        padding: "5px 10px",
                      }}
                      onClick={() => ActiveDeactive(data)}
                    >
                      {data.status === 0 ? "Deactive" : "Active"}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      style={{
                        backgroundColor: "#318e31d9",
                        color: "#fff",
                        padding: "5px 10px",
                      }}
                      onClick={() => navigate(`/edit-banner`, { state: data })}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      style={{
                        backgroundColor: "red",
                        color: "#fff",
                        padding: "5px 10px",
                      }}
                      onClick={() => handleDelete(data._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Banner;
