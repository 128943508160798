import React, { useState, useEffect, useRef } from "react";
import myAxios from "../AxiosUrl";
import "./DashboardSuper.css";
import BarChart from "./BarChart";
import ColorPicker from "./ColorPicker";
import ReactLoading from "react-loading";

function Dashboard() {
  const uid = localStorage.getItem("id");
  const [dashboard, setDashboard] = useState([]);
  const [getVisitor, setVissitor] = useState([]);
  const [city, setCity] = useState([]);
  const [LoadingCity, setLoadingCity] = useState(false);

  const [dashToday, setDashToday] = useState([]);
  const [dashYesterday, setDashYesterday] = useState([]);
  const [dashMonth, setDashMonth] = useState([]);
  const [dashLastMonth, setDashLastMonth] = useState([]);

  const [VisitToday, setVisitToday] = useState([]);
  const [VisitYesterday, setVisitYesterday] = useState([]);
  const [VistMonth, setVistMonth] = useState([]);
  const [VisitLastMonth, setVisitLastMonth] = useState([]);

  const [message, setMessage] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [loadingVissit, setLoadingVisit] = useState(false);
  const [loadingDash, setLoadingDash] = useState(false);

  const [loadingDay, setLoadingDay] = useState(false);
  const [loadingYes, setLoadingYes] = useState(false);
  const [loadingMonth, setLoadingMonth] = useState(false);
  const [loadingLMonth, setLoadingLMonth] = useState(false);

  const [loadingVDay, setloadingVDay] = useState(false);
  const [loadingVYes, setloadingVYes] = useState(false);
  const [loadingVMonth, setloadingVMonth] = useState(false);
  const [loadingVLMonth, setloadingVLMonth] = useState(false);

  useEffect(() => {
    setLoadingCity(true);
    myAxios
      .get(`get-city/${uid}/today`)
      .then((res) => {
        setLoadingCity(false);
        setCity(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getdata = () => {
    setLoadingDash(true);
    myAxios
      .get(`dashboard/${uid}`)
      .then((res) => {
        setLoadingDash(false);
        setDashboard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMessage = () => {
    setLoadingMessage(true);
    myAxios
      .get(`get-message/${uid}`)
      .then((res) => {
        setLoadingMessage(false);
        setMessage(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DashToday = () => {
    setLoadingDay(true);
    myAxios
      .get(`dash-today/${uid}`)
      .then((res) => {
        setLoadingDay(false);
        setDashToday(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DashYesterday = () => {
    setLoadingYes(true);
    myAxios
      .get(`dash-yesterday/${uid}`)
      .then((res) => {
        setLoadingYes(false);
        setDashYesterday(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DashMonth = () => {
    setLoadingMonth(true);
    myAxios
      .get(`dash-month/${uid}`)
      .then((res) => {
        setLoadingMonth(false);
        setDashMonth(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DashLastMonth = () => {
    setLoadingLMonth(true);
    myAxios
      .get(`dash-last-month/${uid}`)
      .then((res) => {
        setLoadingLMonth(false);
        setDashLastMonth(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const VToday = () => {
    setloadingVDay(true);
    myAxios
      .get(`visitor-today/${uid}`)
      .then((res) => {
        setloadingVDay(false);
        setVisitToday(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const VYesterday = () => {
    setloadingVYes(true);
    myAxios
      .get(`visitor-yesterday/${uid}`)
      .then((res) => {
        setloadingVYes(false);
        setVisitYesterday(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const VMonth = () => {
    setloadingVMonth(true);
    myAxios
      .get(`visitor-month/${uid}`)
      .then((res) => {
        setloadingVMonth(false);
        setVistMonth(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const VLastMonth = () => {
    setloadingVLMonth(true);
    myAxios
      .get(`visitor-last-month/${uid}`)
      .then((res) => {
        setloadingVLMonth(false);
        setVisitLastMonth(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    VToday();
    VYesterday();
    VMonth();
    VLastMonth();
    getdata();
    getMessage();
    DashToday();
    DashYesterday();
    DashMonth();
    DashLastMonth();
  }, []);

  return (
    <>
      <div className="mainDashboard">
        <div className="dashboard-item">
          <div className="dash-box">
            <h1>
              {loadingVDay ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                VisitToday.today
              )}
            </h1>
            <p>Waiting to Call</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingVYes ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                VisitYesterday.yesterday
              )}
            </h1>
            <p>Inactive Users</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingVMonth ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                VistMonth.month
              )}
            </h1>
            <p>Active Users</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingVLMonth ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                VisitLastMonth.lastMonth
              )}
            </h1>
            <p>Total Users</p>
          </div>
        </div>
      </div>
      <div className="mainDashboard">
        <div className="dashboard-item">
          <div className="dash-box">
            <h1>
              {loadingVDay ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                VisitToday.today
              )}
            </h1>
            <p>Visitor Today</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingVYes ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                VisitYesterday.yesterday
              )}
            </h1>
            <p>Visitor Yesterday</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingVMonth ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                VistMonth.month
              )}
            </h1>
            <p>Monthly Visitor</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingVLMonth ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                VisitLastMonth.lastMonth
              )}
            </h1>
            <p>Last Month Visitor</p>
          </div>
        </div>
      </div>
      <div className="mainDashboard">
        <div className="dashboard-item">
          <div className="dash-box">
            <h1>
              {loadingMessage ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                message.today
              )}
            </h1>
            <p>Today Message</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingMessage ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                message.yesterday
              )}
            </h1>
            <p>Yesterday Message</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingMessage ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                message.month
              )}
            </h1>
            <p>Monthly Message</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingMessage ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                message.lastMonth
              )}
            </h1>
            <p>Last_Month Message</p>
          </div>
        </div>
      </div>
      <div className="mainDashboard">
        <div className="dashboard-item">
          <div className="dash-box">
            {loadingDash ? (
              <ReactLoading
                type="cylon"
                color="#2b3ed6"
                height={"40px"}
                width={"40px"}
              />
            ) : (
              <>
                <h1>{dashboard.TotalInventory}</h1>
                <p>Total Inventory</p>
              </>
            )}
          </div>
          <div className="dash-box">
            {loadingDash ? (
              <ReactLoading
                type="cylon"
                color="#2b3ed6"
                height={"40px"}
                width={"40px"}
              />
            ) : (
              <>
                <h1>৳ {dashboard.TotalInventoryValue}</h1>
                <p>Inventory Value</p>
              </>
            )}
          </div>
          <div className="dash-box">
            {loadingDash ? (
              <ReactLoading
                type="cylon"
                color="#2b3ed6"
                height={"40px"}
                width={"40px"}
              />
            ) : (
              <>
                <h1>0</h1>
                <p>Total Products</p>
              </>
            )}
          </div>
          <div className="dash-box">
            {loadingDash ? (
              <ReactLoading
                type="cylon"
                color="#2b3ed6"
                height={"40px"}
                width={"40px"}
              />
            ) : (
              <>
                {" "}
                <h1>0</h1>
                <p>Money Available</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
