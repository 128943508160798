import React, { useState } from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import TextItem from "../utils/TextItem";
import { ChangeConsModal } from "../../LandingPageModals/LandingPageModals";

const Problems = ({ data, setData }) => {
  const [openCons, setOpensCons] = useState(false);
  return (
    <div className="effectiveness">
      <div className="container">
        <div className="problems__container">
          <button
            className="landing-page-edit-btn"
            onClick={() => setOpensCons(true)}
          >
            Edit
          </button>
          <h1 style={{ marginBottom: "10px" }}>অসুবিধা</h1>
          {data.Cons.length > 0 &&
            data.Cons.map((item) => (
              <TextItem
                key={item.id}
                icon={<FiArrowRightCircle className="icon" />}
                text={item.text}
              />
            ))}
        </div>
      </div>
      <ChangeConsModal
        data={data}
        setData={setData}
        openCons={openCons}
        setOpenCons={setOpensCons}
      />
    </div>
  );
};

export default Problems;
