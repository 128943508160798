import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";

function Store() {
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");

  const responseFacebook = (response) => {
    console.log(response);
    setData(response);
    setPicture(response.picture.data.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  return (
    <div class="container">
      <div style={{ width: "600px" }}>
        <h1>
          {!login && (
            <FacebookLogin
              appId="1440365780064783"
              autoLoad={true}
              fields="name,email,picture"
              scope="public_profile,user_friends"
              callback={responseFacebook}
              icon="fa-facebook"
            />
          )}
          {login && <img src={picture} roundedCircle />}
        </h1>
        {login && (
          <div>
            <h1>{data.name}</h1>
            <h1>{data.email}</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default Store;
