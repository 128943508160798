import React from "react";

const Effectiveness = ({ data }) => {
  return (
    <div className="effectiveness">
      <div className="container">
        <div className="effectiveness__container">
          <h1 style={{ marginBottom: "10px" }}>সুবিধা</h1>
          <p>{data.ProsTitle}</p>
        </div>
      </div>
    </div>
  );
};

export default Effectiveness;
