import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SetStoreName.css";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import Logo from "../../img/logo.png";

function SetStoreName() {
  const [storeName, setStoreName] = useState();
  const [loading, SetLoading] = useState(false);

  const uid = localStorage.getItem("id");

  const handleStoreName = async () => {
    SetLoading(true);
    if (!storeName) {
      toast.error("Please input store name");
      SetLoading(false);
      return;
    }

    axios
      .post(`${URL}set-store-name/${uid}`, { storeName })
      .then((res) => {
        SetLoading(false);
        window.location.reload("/dashboard");
      })
      .catch((err) => toast.error(err.response.data));
  };

  return (
    <div className="store-name-container">
      <ToastContainer />
      {/* header section start */}
      <div className="header-login">
        <div className="logo-main">
          <a href="https://ecomtechbd.com">
            <img src={Logo} width={150} alt="EcomTech" />
          </a>
        </div>
        <div className="login-main">
          <ul>
            <li>SIGNUP</li>
            <li>LOGIN</li>
          </ul>
        </div>
      </div>
      {/* header section end */}

      {/* login and signup section start */}
      <div className="login-signup-main">
        <div className="login-or-signup">
          <div className="login-signup-tab">
            <div className="storeNameTitle">Set Store Name</div>
          </div>

          <div className="store-name-inputs">
            <input
              type="text"
              placeholder="Write Store Name"
              onChange={(e) => setStoreName(e.target.value)}
              value={storeName}
            />

            <button className="login-btn" onClick={() => handleStoreName()}>
              {loading ? "Loading.." : "SET STORE NAME"}
            </button>
          </div>
        </div>
      </div>
      {/* login and signup section end */}
    </div>
  );
}

export default SetStoreName;
