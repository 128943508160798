import axios from "axios";
import { URL } from "../Component/AxiosUrlGlobal";

export default function BkashExecutePaymentAPICall(paymentID) {
  const url = `${URL}api/bkash/execute`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        paymentID: paymentID,
      })
      .then((response) => {
        // console.log('Data was successfully sent.', response.data);
        resolve(response.data); // Resolve the promise with the response data
      })
      .catch((error) => {
        console.log("An error occurred:", error);
        reject(error); // Reject the promise with the error
      });
  });
}
