import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Video from "./Video";
import Faq from "./Faq";
import Pricing from "./Pricing";
import { Review } from "./Review";
import { Form } from "./Form";
import DeliveryCharge from "./DeliveryCharge";
import myAxios from "../../AxiosUrl";
import { toast, ToastContainer } from "react-toastify";
import "../landing-2.css";
import PageName from "./PageName";

const UpdateLanding1Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state);
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const SaveLanding1 = () => {
    setLoading(true);
    if (!data.Name) {
      toast.error("Please set page name");
      setLoading(false);
      return;
    }
    myAxios
      .post(`update-landing1/${uid}`, { ...data, lid: location.state._id })
      .then((res) => {
        setLoading(false);
        navigate("/landing-list");
      })
      .catch((err) => console.log(err.response.data));
  };

  return (
    <>
      <div className="landing-page-main-container">
        <PageName data={data} setData={setData} />
        <Video data={data} setData={setData} />
        <Faq data={data} setData={setData} />
        <Pricing data={data} setData={setData} />
        <Review data={data} setData={setData} />
        <DeliveryCharge data={data} setData={setData} />
        <Form data={data} />
      </div>
      <div className="save-landing" onClick={SaveLanding1}>
        {loading ? "LOADING.." : "UPDATE PAGE"}
      </div>
    </>
  );
};

export default UpdateLanding1Home;
