const Landing1Data = {
  video: {
    id: 1,
    mainTitle: "চুল সমস্যার প্রাকৃতিক সমাধান",
    videoURL: "https://youtu.be/BX548LuZA4Y",
    subTitle:
      "চুলের যেকোনো সমস্যার যদি প্রাকৃতিক ভাবে সমাধান করতে চান কোন পার্শ্ব-প্রতিক্রিয়া ছাড়া তাহলে এখনি অর্ডার করুন প্রাকৃতিক প্রায় ৩০ টি উপাদানে তৈরি Natural Hair oil",
  },
  Faqs: [
    {
      id: 1,
      title: "তেলের দাম কত এবং কিভাবে অর্ডার পাবো?",
      content:
        "২০০ ml Natural Hair Oil এর দাম ৫০০ টাকা। ডেলিভারি চার্জ ঢাকার ভেতর ৮০ টাকা। ঢাকার বাইরে ১৪০ টাকা। ৪০০ মিলি. অর্থাৎ ২ বোতল নিলে ডেলিভারি চার্জ ফ্রি। সেক্ষেত্রে ১০০০ টাকা দিলেই হবে। হোম ডেলিভারিতে প্রোডাক্ট হাতে পাবার পর টাকা দিয়ে নিতে পারবেন। অর্ডার করতে আপনার বিস্তারিত লিখে নীচের ফর্মটি পূরণ করুন। ৩-৫ দিনের মধ্যে ডেলিভারি পাবেন।",
    },
    {
      id: 2,
      title:
        "আমার প্রচুর চুল পড়ছে। আমার কি চুল পড়া কম হবে কিংবা নতুন চুল গজাবে?",
      content:
        "চুল পড়ার বিভিন্ন কারণ থাকতে পারে। কারও বংশগত কারণে হয় কারও বা আবার প্রোটিনের অভাবে। আসলে চুল পড়া শুরু হওয়ার আগে আমরা খুব কম মানুষই চুলের যত্ন নিই। কিন্তু সময় থাকতে আপনি সঠিকভাবে যত্ন নিলে আপনি একদম টাক হয়ে যাওয়া থেকে রক্ষা পাবেন। চুলের জন্য উপকারী প্রায় ৩০ টি প্রাকৃতিক উপাদানে তৈরি আমার তেলটি ব্যবহারে চুল পড়া কমবে, নতুন চুল গজাবে, চুল লম্বা ও ঘন হবে, চুল সিল্কি, শাইনি এবং কালো হবে, খুশকি থাকলে সেটাও কমে যাবে, তেলটি ব্যবহারের অল্প কিছুদিনের মধ্যেই আপনি পরিবর্তন টা বুঝতে পারবেন। মোটকথা চুলের যেকোনো সমস্যার সমাধান আমার এই ন্যাচারাল হেয়ার অয়েল।",
    },
    {
      id: 3,
      title: "আমার চুল অকালে পেকে যাচ্ছে। এই তেল কি আমার চুল কালো করবে?",
      content:
        "অকালে চুল পেকে যাওয়া চুলের জন্য খুব খারাপ লক্ষণ। এর মানে আপনার চুলের স্বাস্থ্য ভালো নেই কিংবা প্রোটিনের অভাব। সময় থাকতে সঠিক ভাবে যত্ন না নিলে সামনে আরও ক্ষতি হবে। আমার তেলটি প্রাকৃতিক ভাবে আপনার চুলকে কালো করবে শতভাগ। বহু মানুষের অকাল পক্কতার সমাধান করছে আমার প্রাকৃতিক তেলটি।",
    },
    {
      id: 4,
      title: "আমার খুশকির সমস্যা আছে। এই তেল কি খুশকি দূর করবে?",
      content:
        "চুখুশকি হওয়া চুল পড়ে যাওয়ার প্রধান কারণ। আমার এই তেল নিয়মিত ব্যবহারে খুশকি শতভাগ দূর হবে এবং আপনার চুল পড়ে যাওয়া থেকে রক্ষা পাবে।",
    },
    {
      id: 5,
      title: "কি কি উপদান দিয়ে তৈরি?",
      content:
        "খাটি নারিকেল তেলের সাথে জবা ফুলের নির্যাস, আমলকি, মেথি, ব্রাহ্মি, কারিপাতা, শিকাকাই, রিঠা সহ প্রায় ৩০ টি প্রাকৃতিক উপাদান ব্যবহার করা হয়েছে যা চুলের জন্য অনেক অনেক উপকারী।",
    },
    {
      id: 6,
      title: "আপনারা কি কোন গ্যারান্টি দেন?",
      content:
        "১০০% গ্যারান্টির কথা বলা বিক্রির জন্য মিথ্যার আশ্রয় ছাড়া আর কিছুই না। কারণ চুলপড়ার অনেক কারণ থাকতে পারে। অনেকের বংশগত কারণেও চুল পড়ে থাকে। আবার অনেকের প্রোটিনের অভাবে চুল পড়ে। আমরা শতভাগ গ্যারান্টি দিইনা তবে প্রাকৃতিক যেসব উপাদান চুলের জন্য উপকারী এমন প্রায় ৩০ টা উপাদান দিয়ে তেলটি তৈরি করেছি। আমি এবং আরও অনেকে উপকার পেয়েছে। আশা করছি আপনিও উপকার পাবেন। এছাড়াও আমাদের পেইজে আপনি উপকার পেয়েছে এমন মানুষের প্রচুর পরিমাণে রিভিউ দেখতে পাবেন। নিয়ম মেনে ব্যবহার করলে আপনি অবশ্যই উপকার পাবেন।",
    },
    {
      id: 7,
      title: "আগে থেকে কোন টাকা দেয়া লাগবে?",
      content:
        "আগে থেকে এক টাকাও দেয়া লাগবে না। ডেলিভারি ম্যান এর কাছ থেকে প্রোডাক্ট বুঝে পেয়ে তারপর টাকা দিবেন। অর্ডার করতে নীচের ফর্মটি পূরণ করুন।",
    },
  ],
  courses: [
    {
      id: 1,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
      title: "ট্রায়াল কোর্স – ২০০ মিলি তেল",
      price: "৫০০",
      delivery_charge:
        "(ঢাকার ভেতর ৮০ টাকা ঢাকার বাইরে ১৪০ টাকা ডেলিভারি চার্জ যোগ হবে)",
    },
    {
      id: 2,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-white.webp",
      title: "হাফ কোর্সঃ ৪০০ মিলি তেল",
      price: "১০০০",
      delivery_charge: "ডেলিভারি চার্জ ফ্রি",
    },
    {
      id: 3,
      src: "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
      title: "কম্বো প্যাকেজঃ ২৫০ গ্রাম হেয়ার স্পা + ৪০০ মিলি তেল",
      price: "১৫০০",
      delivery_charge: "ডেলিভারি চার্জ ফ্রি",
    },
  ],
  deliveryCharge: {
    insideDhaka: "৮০",
    outsideDhaka: "১৪০",
  },
  ReviewImage:
    "https://naturalsbyrakhi.com/wp-content/uploads/2022/08/Naturals-by-Rakhi-Hair-Oil-Review-1024x455.jpg",
};

export default Landing1Data;
