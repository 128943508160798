import React, { useState } from "react";
import { ChromePicker } from "react-color";
import rgbHex from "rgb-hex";
const ColorPickerComponent = ({ name, SetColor, C_Color }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "10px",
        width: "60%",
        margin: "20px auto",
      }}
    >
      <p>
        {name} Color: {C_Color}
      </p>
      <ChromePicker
        color={C_Color}
        onChange={(c) =>
          SetColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a))
        }
      />
    </div>
  );
};

export default ColorPickerComponent;
