import React, { useState, useEffect } from "react";
import "./UserListSuper.css";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UserModalEdit from "../UserModalEdit/UserModalEdit";
import UserModal from "../UserModal/UserModal";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Moment from "moment";

function User() {
  const uid = localStorage.getItem("id");
  const [users, setUsers] = useState([]);
  const [openEdit, setUserModalEdit] = useState(false);
  const [openUser, setUserModal] = useState(false);
  const [edit, setEdit] = useState("");
  const [refresh, setRefresh] = useState(Math.random());
  const [Totalpage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setPageLimit] = useState(15);

  const PageNumber = (e, p) => {
    setUsers([]);
    setPage(p);
  };

  useEffect(() => {
    getCustomer();
  }, [openEdit, refresh, openUser, page, limit]);

  const getCustomer = () => {
    AxiosUrl.get(`/admin-user/${uid}`, { limit, page })
      .then((res) => {
        setTotalPage(res.data.total);
        setUsers(res.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const seeDomain = (data) => {
    AxiosUrl.post(`/admin-user-domin/${uid}`, { user: data._id })
      .then((res) => {
        toast.success(res.data);
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };
  const deleteCustomer = (data) => {
    AxiosUrl.post(`/admin-user-delete/${uid}`, { user: data._id })
      .then((res) => {
        setRefresh();
        toast.success(res.data);
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };

  return (
    <div style={{ height: "70vh" }}>
      <div>
        <ToastContainer />
        {openEdit && (
          <UserModalEdit setUserModalEdit={setUserModalEdit} data={edit} />
        )}
        {openUser && <UserModal setUserModal={setUserModal} />}
        <div className="category-main">
          <div className="create-category">
            <Button variant="contained" onClick={() => setUserModal(true)}>
              {users.length}
            </Button>
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Mobile</TableCell>
                  <TableCell align="center">Domain</TableCell>
                  <TableCell align="right">Package</TableCell>
                  <TableCell align="right">Subscription</TableCell>
                  <TableCell align="right">nextBilling</TableCell>
                  <TableCell align="right">Trial</TableCell>
                  <TableCell align="right">Paid</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Edit</TableCell>
                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!users.length && (
                  <ReactLoading
                    type="cylon"
                    color="rgb(226,115,29)"
                    height={60}
                    width={60}
                  />
                )}
                {users.map((data) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={data._id}
                    >
                      <TableCell component="th" scope="row">
                        {data.username}
                      </TableCell>
                      <TableCell align="center">{data.mobile}</TableCell>
                      <TableCell align="center">
                        <button onClick={() => seeDomain(data)}>DOMAIN</button>
                      </TableCell>
                      <TableCell align="center">{data.package}</TableCell>
                      <TableCell align="center">
                        {data.IsYear ? "Yearly" : "Monthly"}
                      </TableCell>
                      <TableCell align="center">
                        {Moment(data.nextBilling).format("DD MMM Y ")}
                      </TableCell>
                      <TableCell align="center">
                        {data.IsTrial ? "Trial" : "N/A"}
                      </TableCell>

                      <TableCell align="center">
                        {data.IsPaid ? "Paid" : "Due"}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            padding: "0px",
                          }}
                        >
                          {data.status === "0" ? "Deactive" : "Active"}
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "#318e31d9",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => {
                            setEdit(data);
                            setUserModalEdit(true);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => deleteCustomer(data)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Totalpage > 0 && (
          <div className="pagination-order">
            <Stack spacing={2}>
              <Pagination
                count={Totalpage}
                color="primary"
                onChange={PageNumber}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
}

export default User;
