import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Header from "./Component/HeaderMain/HeaderMain";
import OrderReport from "./Component/OrderReport/OrderReport";
import HeaderSuper from "./Component/HeaderSuper/HeaderSuper";
import Accounts from "./Component/Accounts/Accounts";
import Purchase from "./Component/Purchase/Purchase";
import Expense from "./Component/Expense/Expense";
import NewStore from "./Component/NewStore/NewStore";
import UserListSuper from "./Component/UserListSuper/UserListSuper";
import DashboardSuper from "./Component/DashboardSuper/DashboardSuper";
import HeaderTeam from "./Component/HeaderTeam/Header";
import DNS from "./Component/Dns/DNS";

import Forgot from "./Component/Login/Forgot";
import ForgotVerify from "./Component/Login/ForgotVerify";
import Login from "./Component/Login/Login";

import SetStoreName from "./Component/SetStoreName/SetStoreName";
import Dashboard from "./Component/Dashboard/Dashboard";
import Addproduct from "./Component/AddProduct/Addproduct";
import Stock1 from "./Component/GetProducts/Stock1";
import Order from "./Component/Order/Order";
import Theme from "./Component/Theme/Theme";
import OrderList from "./Component/OrderList/OrderList";
import OrderListTeam from "./Component/OrderListTeam/OrderList";
import EditProduct from "./Component/EditProduct/EditProduct";
import AddtoCart from "./Component/AddtoCart/AddtoCart";
import Cart from "./Component/Cart/Cart";
import CheckOut from "./Component/CheckOut/CheckOut";
import EditOrder from "./Component/EditOrder/EditOrder";
import EditOrderTeam from "./Component/EditOrder/EditOrderTeam";
import VisitorVideo from "./Component/VisitorVideo/VisitorVideo";
import Print from "./Component/Print/Print";
import Category from "./Component/Category/Category";
import SubCategoryList from "./Component/Category/SubCategoryList";
import CategorySelect from "./Component/CategorySelect/CategorySelect";
import Domain from "./Component/Domain/Domain";
import Store from "./Component/Store/Store";
import Footer from "./Component/Footer/Footer";
import User from "./Component/User/User";
import Team from "./Component/Team/Team";
import ProductEdit from "./Component/ProductEdit/ProductEdit";
import ManualOrder from "./Component/ManualOrder/ManualOrder";
import TeamLogin from "./Component/TeamLogin/Login";
import { Cartcontext } from "../src/context/Context";
import Billing from "./Component/Billing/Billing";
import LandingPage from "./Component/LandingPage/LandingPage";
import LandingList from "./Component/LandingList/LandingList";

// Bkash
import Success from "./Component/Bkash/Success";
import Fail from "./Component/Bkash/Fail";
import Callback from "./Component/Bkash/Callback";
import CallbackSMS from "./Component/Bkash/CallbackSMS";
import Profile from "./Component/Profile/Profile";
import BannerList from "./Component/BannerList/BannerList";
import AddBanner from "./Component/AddBanner/AddBanner";
import EditBanner from "./Component/EditBanner/EditBanner";
import Landing1Home from "./Component/Landing1/components/Home";
import Landing2Home from "./Component/Landing/components/Home";
import EditLanding2Home from "./Component/EditLanding/components/Home";
import UpdateLanding2Home from "./Component/UpdateLanding/components/Home";
import EditLanding1Home from "./Component/EditLanding1/components/Home";
import UpdateLanding1Home from "./Component/UpdateLanding1/components/Home";

const App = () => {
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;

  function IsSuperAdmin({ children }) {
    const token = localStorage.getItem("token");
    const superAdmin = localStorage.getItem("IsSuper");

    if (!token && !superAdmin) {
      localStorage.removeItem("token");
      localStorage.removeItem("IsSuper");
      localStorage.removeItem("id");
      return <Navigate to="/login" replace />;
    }
    return children;
  }

  function PrivateRoute({ children }) {
    const token = localStorage.getItem("token");
    if (!token) {
      return <Navigate to="/login" replace />;
    }
    return children;
  }

  function PrivateRouteTeam({ children }) {
    const token = localStorage.getItem("teamid");
    if (!token) {
      return <Navigate to="/teamlogin" replace />;
    }
    return children;
  }

  function IsLogin({ children }) {
    const token = localStorage.getItem("token");
    const superAdmin = localStorage.getItem("IsSuper");
    if (token && superAdmin == "false") {
      return <Navigate to="/dashboard" replace />;
    } else if (token && superAdmin == "true") {
      return <Navigate to="/super-admin-dashboard" replace />;
    } else {
      return children;
    }
  }

  return (
    <BrowserRouter>
      {/* {state.length && <ManualOrder />}
      {state.length && <AddtoCart />} */}
      <Routes>
        <Route
          path="/"
          element={
            <IsLogin>
              <Login />
            </IsLogin>
          }
        />
        <Route
          path="/set-store-name/:id"
          element={
            <IsLogin>
              <SetStoreName />
            </IsLogin>
          }
        />
        <Route path="/store" element={<Store />} />
        <Route
          path="/login"
          element={
            <IsLogin>
              <Login />
            </IsLogin>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <IsLogin>
              <Forgot />
            </IsLogin>
          }
        />
        <Route
          path="/forgot-verify"
          element={
            <IsLogin>
              <ForgotVerify />
            </IsLogin>
          }
        />
        <Route
          path="/teamlogin"
          element={
            <IsLogin>
              <TeamLogin />
            </IsLogin>
          }
        />
        <Route
          path="/signup"
          element={
            <IsLogin>
              <Login />
            </IsLogin>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Header />
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/expense"
          element={
            <PrivateRoute>
              <Header />
              <Expense />
            </PrivateRoute>
          }
        />
        <Route
          path="/accounts"
          element={
            <PrivateRoute>
              <Header />
              <Accounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/purchase"
          element={
            <PrivateRoute>
              <Header />
              <Purchase />
            </PrivateRoute>
          }
        />
        <Route
          path="/team-order"
          element={
            <PrivateRouteTeam>
              <HeaderTeam />
              <OrderListTeam />
            </PrivateRouteTeam>
          }
        />
        <Route
          path="/super-admin-dashboard"
          element={
            <IsSuperAdmin>
              <HeaderSuper />
              <DashboardSuper />
            </IsSuperAdmin>
          }
        />
        <Route
          path="/super-admin-users"
          element={
            <IsSuperAdmin>
              <HeaderSuper />
              <UserListSuper />
            </IsSuperAdmin>
          }
        />
        <Route
          path="/super-admin-billing"
          element={
            <IsSuperAdmin>
              <HeaderSuper />
              <UserListSuper />
            </IsSuperAdmin>
          }
        />
        <Route
          path="/super-update-theme"
          element={
            <IsSuperAdmin>
              <HeaderSuper />
              <UserListSuper />
            </IsSuperAdmin>
          }
        />
        <Route
          path="/super-admin-store"
          element={
            <IsSuperAdmin>
              <HeaderSuper />
              <UserListSuper />
            </IsSuperAdmin>
          }
        />
        <Route
          path="/edit-team-order"
          element={
            <PrivateRouteTeam>
              <HeaderTeam />
              <EditOrderTeam />
            </PrivateRouteTeam>
          }
        />
        <Route
          path="/team"
          element={
            <PrivateRoute>
              <Header />
              <Team />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-product"
          element={
            <PrivateRoute>
              <Header />
              <Addproduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/product"
          element={
            <PrivateRoute>
              <Header />
              <Stock1 />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-order"
          element={
            <PrivateRoute>
              <Header />
              <Order />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-list"
          element={
            <PrivateRoute>
              <Header />
              <OrderList />
            </PrivateRoute>
          }
        />
        <Route
          path="/dns-list"
          element={
            <PrivateRoute>
              <Header />
              <DNS />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-product"
          element={
            <PrivateRoute>
              <Header />
              <EditProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <Header />
              <Cart />
            </PrivateRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <Header />
              <CheckOut />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-order"
          element={
            <PrivateRoute>
              <Header />
              <EditOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/visitor-video"
          element={
            <PrivateRoute>
              <Header />
              <VisitorVideo />
            </PrivateRoute>
          }
        />
        <Route
          path="/print"
          element={
            <PrivateRoute>
              <Header />
              <Print />
            </PrivateRoute>
          }
        />
        <Route
          path="/special-category"
          element={
            <PrivateRoute>
              <Header />
              <CategorySelect />
            </PrivateRoute>
          }
        />
        <Route
          path="/subcategories/:categoryId"
          element={
            <PrivateRoute>
              <Header />
              <SubCategoryList />
            </PrivateRoute>
          }
        />
        <Route
          path="/category"
          element={
            <PrivateRoute>
              <Header />
              <Category />
            </PrivateRoute>
          }
        />
        <Route
          path="/advance-report"
          element={
            <PrivateRoute>
              <Header />
              <OrderReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/domain"
          element={
            <PrivateRoute>
              <Header />
              <Domain />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-store"
          element={
            <PrivateRoute>
              <Header />
              <NewStore />
            </PrivateRoute>
          }
        />
        <Route
          path="/product-edit"
          element={
            <PrivateRoute>
              <Header />
              <ProductEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/billing"
          element={
            <PrivateRoute>
              <Header />
              <Billing />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Header />
              <User />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Header />
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/banner-list"
          element={
            <PrivateRoute>
              <Header />
              <BannerList />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-banner"
          element={
            <PrivateRoute>
              <Header />
              <AddBanner />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-banner"
          element={
            <PrivateRoute>
              <Header />
              <EditBanner />
            </PrivateRoute>
          }
        />
        <Route
          path="/landing-page"
          element={
            <PrivateRoute>
              <Header />
              <LandingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/landing-list"
          element={
            <PrivateRoute>
              <Header />
              <LandingList />
            </PrivateRoute>
          }
        />
        <Route
          path="/landing/:id"
          element={
            <PrivateRoute>
              <Header />
              <Landing1Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/landing1/:id"
          element={
            <PrivateRoute>
              <Header />
              <Landing2Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/landing1/edit/:id"
          element={
            <PrivateRoute>
              <Header />
              <EditLanding2Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/landing1/update/:id"
          element={
            <PrivateRoute>
              <Header />
              <UpdateLanding2Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/landing/edit/:id"
          element={
            <PrivateRoute>
              <Header />
              <EditLanding1Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/landing/update/:id"
          element={
            <PrivateRoute>
              <Header />
              <UpdateLanding1Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/theme"
          element={
            <PrivateRoute>
              <Header />
              <Theme />
            </PrivateRoute>
          }
        />
        <Route path="/success" element={<Success />} />
        <Route path="/fail" element={<Fail />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/callbackSMS" element={<CallbackSMS />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
