import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

function DnsModal({ open, setOpenDnsModal }) {
  const [dnsName, setDnsName] = useState("");
  const [dsnResord, setDsnResord] = useState("");
  const [dsnType, setDsnType] = useState("");
  const [isLocal, setIsLocal] = useState(false);
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const handleClose = () => {
    setOpenDnsModal(false);
  };

  const handleTypeChange = (e) => {
    setDsnType(e.target.value);
    setDnsName("");
    setDsnResord("");
  };

  const submitCategory = () => {
    setLoading(true);
    if (!dsnType || !dnsName || !dsnResord) {
      toast.error("DNS Type, Record Name, and Record Value are required");
      setLoading(false);
      return;
    }

    const formData = {
      uid,
      dnsName,
      dsnResord,
      dsnType,
      isLocal,
    };

    AxiosUrl.post(`/create-dns/${uid}`, formData)
      .then((data) => {
        if (data.data === "DNS record created successfully") {
          toast.success("DNS record created successfully");
          handleClose();
        } else {
          toast.error(data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Failed to create Dns");
        setLoading(false);
      });
  };

  const getPlaceholderForName = () => {
    switch (dsnType) {
      case "A":
      case "AAAA":
      case "CNAME":
        return "Example: domain.com or shop.domain.com";
      case "MX":
      case "NS":
        return "domain.com";
      case "TXT":
        return "domain.com or shop.domain.com";
      case "SRV":
        return "Enter the name for the record (optional)";
      case "SOA":
        return "Enter the domain's authoritative information";
      default:
        return "Enter Record Name";
    }
  };

  const getPlaceholderForValue = () => {
    switch (dsnType) {
      case "A":
        return "Enter IP address (e.g., 192.168.1.1)";
      case "AAAA":
        return "Enter IPv6 address (e.g., 2001:0db8:85a3:0000:0000:8a2e:0370:7334)";
      case "CNAME":
        return "Enter domain name (e.g., www.domain.com)";
      case "MX":
        return "Mail server (mail.domain.com)";
      case "TXT":
        return "Enter text record (e.g., v=spf1 include:domain.com ~all)";
      case "SRV":
        return "Enter service record (e.g., _sip._tcp.domain.com)";
      case "NS":
        return "Nameservers (ns1.domain.com ns2.domain.com)";
      case "SOA":
        return "Enter the Start of Authority record";
      default:
        return "Enter value";
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? 290 : 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <h2
          style={{
            margin: 0,
            textTransform: "uppercase",
            color: "#1e2124",
            background: "aliceblue",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          Create New DNS Record
        </h2>

        <FormControl fullWidth margin="normal">
          <InputLabel id="dns-type-label">DNS Type</InputLabel>
          <Select
            labelId="dns-type-label"
            value={dsnType}
            onChange={handleTypeChange}
            label="DNS Type"
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="AAAA">AAAA</MenuItem>
            <MenuItem value="CNAME">CNAME</MenuItem>
            <MenuItem value="MX">MX</MenuItem>
            <MenuItem value="TXT">TXT</MenuItem>
            <MenuItem value="SRV">SRV</MenuItem>
            <MenuItem value="NS">NS</MenuItem>
            <MenuItem value="SOA">SOA</MenuItem> {/* Added SOA option */}
          </Select>
        </FormControl>

        <TextField
          label="Record Name"
          placeholder={getPlaceholderForName()}
          variant="outlined"
          fullWidth
          margin="normal"
          value={dnsName}
          onChange={(e) => setDnsName(e.target.value)}
        />

        <TextField
          label="Record Value"
          placeholder={getPlaceholderForValue()}
          variant="outlined"
          fullWidth
          margin="normal"
          value={dsnResord}
          onChange={(e) => setDsnResord(e.target.value)}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="is-local-label">Is Local</InputLabel>
          <Select
            labelId="is-local-label"
            value={isLocal}
            onChange={(e) => setIsLocal(e.target.value)}
            label="Is Local"
          >
            <MenuItem value={false}>False</MenuItem>
            <MenuItem value={true}>True</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={submitCategory}
          fullWidth
          sx={{ mt: 2, p: 1 }}
        >
          {loading ? "Loading..." : "Create DNS Record"}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          fullWidth
          sx={{
            mt: 2,
            p: 1,
            color: "white",
            borderColor: "red",
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "darkred",
              borderColor: "darkred",
            },
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default DnsModal;
