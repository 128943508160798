import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";

function SubCategoryModal({ setOpenSubCategoryModal, category, setRefresh }) {
  const uid = localStorage.getItem("id");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryImgUrl, setSubCategoryImgUrl] = useState("");
  const [photo, setPhoto] = useState(null);

  const handleClose = () => setOpenSubCategoryModal(false);

  const handleSave = () => {
    if (!subCategoryName) {
      toast.error("Subcategory name is required");
      return;
    }
    if (!subCategoryImgUrl) {
      toast.error("Subcategory image is required");
      return;
    }
    AxiosUrl.post(`/add-subcategory/${uid}/${category._id}`, {
      name: subCategoryName,
      imgUrl: subCategoryImgUrl,
    })
      .then((res) => {
        if (res.data) {
          toast.success("Subcategory added successfully");
          setRefresh((prev) => prev + 1); // Refresh the parent component's data
          handleClose();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to add subcategory");
      });
  };

  const cdnImage = async () => {
    const image = new FormData();
    image.append("photo", photo);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy", // Replace with your actual token
      },
    };
    const url = "https://cdn.ecombd.org/upload/category";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Image uploaded to CDN");
        setSubCategoryImgUrl(res.data); // Store the URL after uploading
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to upload image");
      });
  };

  useEffect(() => {
    if (photo) {
      cdnImage();
    }
  }, [photo]);

  const handleFileChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? 290 : 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <h2
          style={{
            margin: 0,
            textTransform: "uppercase",
            color: "#1e2124",
            background: "aliceblue",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          Create Sub-Category for {category.name}
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          {!subCategoryImgUrl && (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleFileChange}
              />
              <CloudUploadIcon style={{ marginTop: 20, fontSize: 100 }} />
            </IconButton>
          )}
          {subCategoryImgUrl && (
            <img
              src={subCategoryImgUrl}
              alt="Uploaded"
              style={{
                height: 150,
                width: 150,
                objectFit: "cover",
                marginTop: 20,
                borderRadius: 5,
                border: "1px solid #ccc",
              }}
            />
          )}
        </div>
        <TextField
          label="Sub-Category Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={subCategoryName}
          onChange={(e) => setSubCategoryName(e.target.value)}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          fullWidth
          sx={{ mt: 2, p: 1 }}
        >
          Create Sub-Category
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          fullWidth
          sx={{
            mt: 2,
            p: 1,
            color: "white",
            borderColor: "red",
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "darkred", // Darker shade on hover
              borderColor: "darkred",
            },
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default SubCategoryModal;
