import React from "react";

const Variantions = ({ data }) => {
  return (
    <div className="variation__container">
      <div className="container">
        <div className="varinat__title">ভেরিয়েশন</div>
        <div className="variant__cards">
          {data.Variations.map((variant) => (
            <div className="variant__card" key={variant.id}>
              <img src={variant.src} alt="variant" />
              <div>Color: {variant.color}</div>
              <div>Price: {variant.price}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Variantions;
