import React, { useState, useEffect } from "react";
import "./EditDomainModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Modal({ setEOpenModal, data }) {
  const [domain, setDomain] = useState(data.domain);
  const [number, setNumber] = useState(data.number);
  const [WhatsApp, setWhatsApp] = useState(data.WhatsApp);
  const [bkash, setBkash] = useState(data.bkash);
  const [loading, setloading] = useState(false);
  const [photo, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const uid = localStorage.getItem("id");
  const [pixel, setPixel] = useState(data.pixel);
  const [Dhaka, setDhaka] = useState(data.Dhaka);
  const [Outside, setOutside] = useState(data.Outside);
  const [MessangerUrl, setMessangerUrl] = useState(data.MessangerUrl);
  const [SteadFast_key, setSteadFastApiKey] = useState(data.SteadFast_key);
  const [SteadFast_Secret, setSteadFastApiSecret] = useState(
    data.SteadFast_Secret
  );

  const fromData = {
    uid,
    domain: domain.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/+$/, ""),
    logo: imageUrl,
    oldDomain: data.domain,
    pixel,
    number,
    WhatsApp,
    bkash,
    Dhaka,
    Outside,
    SteadFast_key,
    SteadFast_Secret,
    MessangerUrl,
  };
  const submitCategory = () => {
    if (!domain) {
      return toast.error("Domain Require");
    }
    setloading(true);
    if (!domain) {
      return setloading(false);
    }

    AxiosUrl.post(`/edit-domain/${uid}`, fromData)
      .then((res) => {
        setEOpenModal(false);
        setloading(false);
        setDomain("");
      })
      .catch((error) => {});
  };
  const cdnImage = async () => {
    const image = new FormData();
    image.append("photo", photo);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload/desc";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Logo Uploaded");
        setImageUrl(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (photo) {
      cdnImage();
    }
  }, [photo]);

  return (
    <div className="modalBackground">
      <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setEOpenModal(false)}>X</button>
        </div>
        <div className="category-upload">
          <h3>Upload Logo Max Width 2 Inch</h3>
          <input
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
            type="file"
            className="CmFile"
            name="photo"
          ></input>
        </div>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              fullWidth
              label="Edit Domain"
              id="fullWidth"
              onChange={(e) => setDomain(e.target.value)}
              value={domain}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Optional Facebook Pixel Ex: 1307091900148979"
              fullWidth
              label="Facebook Pixel ID"
              id="fullWidth"
              onChange={(e) => setPixel(e.target.value)}
              value={pixel}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Mobile Number Ex: 01711000000"
              fullWidth
              label="Mobile Number"
              id="fullWidth"
              onChange={(e) => setNumber(e.target.value)}
              value={number}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="WhatsApp Number Ex: 01711000000"
              fullWidth
              label="WhatsApp Number"
              id="fullWidth"
              onChange={(e) => setWhatsApp(e.target.value)}
              value={WhatsApp}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Bkash Ex: 01711000000"
              fullWidth
              label="Bkash Number"
              id="fullWidth"
              onChange={(e) => setBkash(e.target.value)}
              value={bkash}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Delivery Charge: 70"
              fullWidth
              label="Delivery Charge Dhaka"
              id="fullWidth"
              onChange={(e) => setDhaka(e.target.value)}
              value={Dhaka}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Delivery Charge: 130"
              fullWidth
              label="Delivery Charge OutSide"
              id="fullWidth"
              onChange={(e) => setOutside(e.target.value)}
              value={Outside}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Stead Fast Api Key"
              fullWidth
              label="Stead Fast Api Key"
              id="fullWidth"
              onChange={(e) => setSteadFastApiKey(e.target.value)}
              value={SteadFast_key}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Stead Fast Api Secret"
              fullWidth
              label="Stead Fast Api Secret"
              id="fullWidth"
              onChange={(e) => setSteadFastApiSecret(e.target.value)}
              value={SteadFast_Secret}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginTop: "15px" }}>
            <TextField
              placeholder="Messanger Url"
              fullWidth
              label="Messanger Url"
              id="fullWidth"
              onChange={(e) => setMessangerUrl(e.target.value)}
              value={MessangerUrl}
            />
          </Box>
        </div>

        <div className="footer">
          <button
            onClick={() => {
              setEOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              submitCategory();
            }}
          >
            {loading ? "Loading.." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
