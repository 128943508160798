import React, { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import TextItem from "./TextItem";
import StarRating from "./StarRating";
import { PrimaryButton } from "./Button";
import Separator from "./Separator";
import ImageCard from "./ImageCard";
import { ChangeFeaturesModal } from "../../LandingPageModals/LandingPageModals";

export const DescriptionCard = ({ data }) => {
  return (
    <div className="description__card__container">
      <div className="description__card__header">
        <h1>{data.Title}</h1>
        <h2>{"কোড " + data.ProductCode}</h2>
      </div>
      <div className="description__card__price__container">
        <div className="description__card__price__section">
          <h3>{data.Price + " TK"}</h3>
          <h1>{data.Buying + " TK"}</h1>
        </div>
        <div className="description__card__price__container">
          <div className="description__card__price__section">
            <h3>{data.Price}</h3>
            <h1>{data.Buying}</h1>
          </div>
          <div className="description__card__price__text">
            <h4>{data.DeliveryOption}</h4>
          </div>
        </div>
        <div className="description__card__body">
          {data.BulletPoints.length > 0 &&
            data.BulletPoints.map((point) => (
              <TextItem
                key={point.id}
                icon={<FaRegCheckCircle className="icon" />}
                text={point.text}
              />
            ))}
        </div>
        <div className="description__card__footer">
          <PrimaryButton
            text={"order now"}
            handler={() => alert("order now")}
          />
          <div className="description__card__review">
            <div className="description__card__review__rating">
              <StarRating color={"#ff6700"} rating={data.Reviews.point} />
              {data.Reviews.point}
            </div>
            <div>{data.Reviews.number} Reviews</div>
            <div>{data.Reviews.orders} Orders</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FeatureCard = ({ data, setData }) => {
  const [openFeatures, setOpenFeatures] = useState(false);
  return (
    <>
      <button
        className="landing-page-edit-btn"
        onClick={() => setOpenFeatures(true)}
      >
        Edit
      </button>
      <div className="feature__card">
        {data.Features.length > 0 &&
          data.Features.map((feature, index) => (
            <div key={feature.id}>
              <p>{feature.text}</p>
              {data.Features.length - 1 !== index && <Separator />}
            </div>
          ))}
        <div className="feature__btn">
          <PrimaryButton text="Buy Now" handler={() => alert("buy now")} />
        </div>
        <ChangeFeaturesModal
          openFeatures={openFeatures}
          setOpenFeatures={setOpenFeatures}
          data={data}
          setData={setData}
        />
      </div>
    </>
  );
};

export const TextCard = ({ item }) => {
  return (
    <div className="text__card__container">
      <div className="text__card__image">
        <ImageCard src={item.src} />
      </div>
      <div className="text__card__content">
        <h2>{item.title}</h2>
        <p>{item.text}</p>
      </div>
    </div>
  );
};
