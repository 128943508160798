import React, { useState, useEffect } from "react";
import "./LandingList.css";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

function LandingPageList() {
  const navigate = useNavigate();
  const uid = localStorage.getItem("id");
  const [landingPages, setLandingPages] = useState([]);
  const [open, setOpenModal] = useState(false);
  const [openCategory, setOpenCategoryModal] = useState(false);
  const [edit, setEdit] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLandingPages();
  }, [open, refresh, openCategory]);

  const getLandingPages = () => {
    setLoading(true);
    AxiosUrl.get(`/get-landing/${uid}`)
      .then((res) => {
        setLandingPages(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const deleteLandingPage = (did) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this landing page?"
    );
    if (confirmDelete) {
      setRefresh(false);
      AxiosUrl.post(`/delete-landing/${uid}`, { did })
        .then((res) => {
          if (res.data.acknowledged) {
            toast.success("Deleted successfully.");
            setRefresh(true);
          }
        })
        .catch(() => {
          toast.error("Failed to delete the landing page.");
        });
    }
  };

  const viewLandingPage = (data) => {
    AxiosUrl.get(`/veiw-landing/${uid}`)
      .then((res) => {
        if (data.Landing == 1) {
          window.open(`${res.data}/landing/${data._id}`, "_blank");
        } else {
          window.open(`${res.data}/landing1/${data._id}`, "_blank");
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="landing-main">
        <div className="landing-video">
          <div>
            ল্যান্ডিং পেজ কিভাবে কাজ করে দেখুন ➡️{" "}
            <a href="#" target="_blank">
              VIDEO LINK
            </a>{" "}
          </div>
        </div>
        <div className="create-landing">
          <Button variant="contained" onClick={() => navigate("/landing-page")}>
            Create Landing Page
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Landing</TableCell>
                <TableCell align="center">View</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <ReactLoading
                  type="cylon"
                  color="rgb(226,115,29)"
                  height={60}
                  width={60}
                />
              )}
              {!loading && landingPages.length <= 0 ? (
                <div className="no-data-available">No data available</div>
              ) : (
                landingPages.map((data) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={data._id}
                  >
                    <TableCell align="center">{data.Name}</TableCell>
                    <TableCell align="center">
                      <Button
                        style={{
                          backgroundColor: "#2e7568",
                          color: "#fff",
                          padding: "0px",
                        }}
                      >
                        Page {data.Landing}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        style={{
                          backgroundColor: "#3e3366",
                          color: "#fff",
                          padding: "0px",
                        }}
                        onClick={() => viewLandingPage(data)}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        style={{
                          backgroundColor: "rgba(142, 49, 132, 0.85)",
                          color: "#fff",
                          padding: "0px",
                        }}
                        onClick={() => {
                          setEdit(data);
                          setOpenModal(true);
                          navigate(
                            data.Landing == 1
                              ? `/landing1/update/${data._id}`
                              : `/landing/update/${data._id}`,
                            {
                              state: data,
                            }
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        style={{
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "0px",
                        }}
                        onClick={() => deleteLandingPage(data._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default LandingPageList;
