import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "./HeaderSuper.css";
import { useNavigate } from "react-router-dom";
import Avatar from "../../img/avatar.png";
import myAxios from "../AxiosUrl";
import AxiosUrl from "../AxiosUrl";

function HeaderMain() {
  const location = useLocation();
  const navigate = useNavigate();
  const navRef = useRef();
  const uid = localStorage.getItem("id");
  const [store, setStore] = useState();
  const [IsTrial, setIsTrial] = useState();
  const [DaysLeft, setDaysLeft] = useState();
  const [PayBill, setPayBill] = useState();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  useEffect(() => {
    AxiosUrl.get(`/get-subscription/${uid}`)
      .then((res) => {
        setIsTrial(res.data.IsTrial);
        setDaysLeft(res.data.DaysLeft);
        setPayBill(res.data.PayBill);
        localStorage.setItem("DaysLeft", res.data.DaysLeft);
        localStorage.setItem("PayBill", res.data.PayBill);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const Left = parseInt(localStorage.getItem("DaysLeft"));
    if (Left <= 0) {
      navigate("/billing");
    }
    if (PayBill) {
      navigate("/billing");
    }
    if (IsTrial && Left <= 0) {
      navigate("/billing");
    }
  }, [location.pathname]);

  useEffect(() => {
    myAxios
      .post(`/store/${uid}`, { domain: "kilagbe.com" })
      .then((res) => {
        setStore(res.data);
      })
      .catch((err) => {
        if (err.response.data === "Token is not valid") {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          setIsAuthenticate(false);
          setOpen(false);
          navigate("/");
        }
      });
  }, [uid]);
  const [isAuthenticated, setIsAuthenticate] = useState(
    localStorage.getItem("token") ? true : false
  );

  const [open, setOpen] = useState(false);
  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("IsSuper");
    localStorage.removeItem("IsTeam");
    setIsAuthenticate(false);
    setOpen(false);
  };
  return (
    <>
      <header>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          {/* <h2 className="h-logo">{store?.domain ? store.domain : "Admin"}</h2> */}
          <h2 className="h-logo">Super Admin</h2>
        </Link>
        <nav ref={navRef}>
          <Link
            to="/super-admin-dashboard"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Dashboard</a>
          </Link>
          <Link
            to="/super-admin-users"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Users</a>
          </Link>

          <Link
            to="/super-update-theme"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>UpdateTheme</a>
          </Link>
          <Link
            to="/super-admin-store"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Store</a>
          </Link>

          <Link
            to="/super-admin-billing"
            style={{ textDecoration: "none" }}
            onClick={showNavbar}
          >
            <a>Billing</a>
          </Link>

          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={logoutHandler}
          >
            <a>Logout</a>
          </Link>
          <div className="avatar">
            <img src={Avatar} height={40} width={40} />
          </div>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
      {DaysLeft <= 7 && (
        <div className="notification-header">
          <Link
            to="/billing"
            style={{ textDecoration: "none", color: "bisque" }}
          >
            <p>{DaysLeft} days left to Deactivate pay bill </p>
          </Link>
        </div>
      )}

      {PayBill ? (
        <div className="notification-header">
          <Link
            to="/billing"
            style={{ textDecoration: "none", color: "bisque" }}
          >
            <p>Account Deactivated Pay bill to reactivate</p>
          </Link>
        </div>
      ) : (
        ""
      )}

      {IsTrial && (
        <div className="notification-header">
          <Link
            to="/billing"
            style={{ textDecoration: "none", color: "bisque" }}
          >
            <p>Trial Activated for 7 Days</p>
          </Link>
        </div>
      )}
    </>
  );
}

export default HeaderMain;
