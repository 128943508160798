import React, { useState, useEffect } from "react";
import "./Customer.css";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UserModalEdit from "../UserModalEdit/UserModalEdit"; // Kept the same
import UserModal from "../UserModal/UserModal"; // Kept the same
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function Customer() {
  const uid = localStorage.getItem("id");
  const [customers, setCustomers] = useState([]);
  const [openEdit, setUserModalEdit] = useState(false); // Kept the same
  const [openCustomer, setUserModal] = useState(false); // Kept the same
  const [edit, setEdit] = useState("");
  const [refresh, setRefresh] = useState(Math.random());
  const [Totalpage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setPageLimit] = useState(10);
  const [loading, setLoading] = useState(false);

  const PageNumber = (e, p) => {
    setCustomers([]);
    setPage(p);
  };

  useEffect(() => {
    getCustomer();
  }, [openEdit, refresh, openCustomer, page, limit]);

  const getCustomer = () => {
    setLoading(true);
    AxiosUrl.post(`/customers/${uid}`, { limit, page })
      .then((res) => {
        setTotalPage(res.data.total);
        setCustomers(res.data.customers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setLoading(false);
      });
  };

  const deleteCustomer = (cid) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this customer?"
    );
    if (confirmDelete) {
      setRefresh(false);
      AxiosUrl.post(`/delete-customer/${uid}`, { cid })
        .then((res) => {
          if (res.data.acknowledged) {
            toast.success("Customer deleted successfully.");
            setRefresh(true);
          }
        })
        .catch(() => {
          toast.error("Failed to delete the customer.");
        });
    }
  };

  return (
    <div style={{ minHeight: "90vh", margin: "7px auto" }}>
      <div className="customer-video">
        <div>
          কাস্টমার কিভাবে কাজ করে ➡️{" "}
          <a href="#" target="_blank">
            Video Link
          </a>{" "}
        </div>
      </div>
      <div>
        <ToastContainer />
        {openEdit && (
          <>
            {/* Kept the same */}
            <UserModalEdit setUserModalEdit={setUserModalEdit} data={edit} />
          </>
        )}
        {openCustomer && (
          <>
            {/* Kept the same */}
            <UserModal setUserModal={setUserModal} />
          </>
        )}
        <div className="customer-main">
          <div className="create-customer">
            <Button variant="contained" onClick={() => setUserModal(true)}>
              Add Customer
            </Button>
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Customer Name</TableCell>
                  <TableCell align="center">Mobile</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Edit</TableCell>
                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <ReactLoading
                    type="cylon"
                    color="rgb(226,115,29)"
                    height={60}
                    width={60}
                  />
                )}
                {!loading && customers.length <= 0 ? (
                  <div className="no-data-available">No data available</div>
                ) : (
                  customers.map((data) => {
                    return (
                      <TableRow key={data._id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none" }}
                        >
                          {data.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none" }}
                        >
                          {data.mobile}
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none" }}
                        >
                          <Button
                            style={{
                              backgroundColor: "green",
                              color: "#fff",
                              padding: "2px 10px",
                              fontSize: "13px",
                            }}
                          >
                            {data.status === "0" ? "Deactive" : "Active"}
                          </Button>
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none" }}
                        >
                          <Button
                            style={{
                              backgroundColor: "rgba(3, 62, 15, 0.85)",
                              color: "#fff",
                              padding: "2px 10px",
                              fontSize: "13px",
                            }}
                            onClick={() => {
                              setEdit(data);
                              setUserModalEdit(true); // Kept the same
                            }}
                          >
                            Edit
                          </Button>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ borderBottom: "none" }}
                          component="th"
                          scope="row"
                        >
                          <Button
                            style={{
                              backgroundColor: "#8f2121",
                              color: "#fff",
                              padding: "2px 10px",
                              fontSize: "13px",
                            }}
                            onClick={() => deleteCustomer(data._id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Totalpage > 0 && (
          <div className="pagination-customer">
            <Stack spacing={2}>
              <Pagination
                count={Totalpage}
                color="primary"
                onChange={PageNumber}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
}

export default Customer;
