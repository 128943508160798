import React, { useState, useEffect } from "react";
import { HiSearchCircle } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Expense.css";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExpenseModal from "../ExpenseModal/ExpenseModal";
import ExpenseModalEdit from "../ExpenseModalEdit/ExpenseModalEdit";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import Moment from "moment";

function Expense() {
  const uid = localStorage.getItem("id");
  const [DateFrom, setDateFrom] = useState(new Date());
  DateFrom.setHours(0);
  DateFrom.setMinutes(0);
  DateFrom.setSeconds(0);
  const [DateTo, setDateTo] = useState(new Date());
  DateTo.setHours(0);
  DateTo.setMinutes(0);
  DateTo.setSeconds(0);
  const [DateSearch, setDateSearch] = useState(false);
  const [Expense, setExpense] = useState([]);
  const [ExpenseAmount, setExpenseAmount] = useState(0);
  const [ExpenseLAmount, setExpenseLAmount] = useState(0);
  const [OpenEditModal, setOpenEditModal] = useState(false);
  const [OpenAddModal, setOpenAddModal] = useState(false);
  const [edit, setEdit] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ExpenseData();
  }, [refresh]);

  const ExpenseData = () => {
    setLoading(true);
    AxiosUrl.post(`/get-expense/${uid}`, { DateFrom, DateTo, DateSearch })
      .then((res) => {
        setExpense(res.data.Data);
        setExpenseAmount(res.data.Month);
        setExpenseLAmount(res.data.LMonth);
        setDateSearch(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const deleteExpense = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this expense?"
    );
    if (confirmDelete) {
      setRefresh(false);
      AxiosUrl.post(`/delete-expense/${uid}`, { id })
        .then((res) => {
          toast.success(res.data);
          setRefresh(refresh + 1);
        })
        .catch(() => {
          toast.error("Failed to delete the expense.");
        });
    }
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <ToastContainer />
      {OpenAddModal && (
        <ExpenseModal
          setOpenAddModal={setOpenAddModal}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      {OpenEditModal && (
        <ExpenseModalEdit
          setOpenEditModal={setOpenEditModal}
          data={edit}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      <div className="expenes-main">
        <div className="Expense-Video">
          <div>
            এক্সপেন্স কিভাবে কাজ করে দেখুন ➡️{" "}
            <a href="#" target="_blank">
              VIDEO LINK
            </a>{" "}
          </div>
        </div>
        <div className="create-expenes">
          <Button variant="contained" onClick={() => setOpenAddModal(true)}>
            Add Expense
          </Button>
          <div className="Expense-Amount">{ExpenseAmount} TK</div>
          <div className="Expense-Amount">LastMonth: {ExpenseLAmount} TK</div>
          <div className="date-date-Picker">
            <DatePicker
              selected={DateFrom}
              onChange={(date) => setDateFrom(date)}
            />
            <p style={{ paddingRight: "10px" }}>To</p>
            <DatePicker
              selected={DateTo}
              onChange={(date) => setDateTo(date)}
            />
            <HiSearchCircle
              className="search-date"
              onClick={() => {
                setDateSearch(true);
                setRefresh(refresh + 1);
              }}
            />
          </div>
        </div>

        <TableContainer component={Paper} style={{ width: "100%" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="center">Title</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Expense ID</TableCell>
                <TableCell align="center">View</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <ReactLoading
                  type="cylon"
                  color="rgb(226,115,29)"
                  height={60}
                  width={60}
                />
              )}
              {!loading && Expense.length <= 0 ? (
                <div className="no-data-available">No data available</div>
              ) : (
                Expense.map((data) => {
                  return (
                    <TableRow key={data._id}>
                      <TableCell component="th" scope="row">
                        {Moment(data.Date).format("DD MMM YYYY")}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {data.Title}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {data.Amount}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {data.ExpenseId}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor: "rgba(13, 85, 13, 0.85)",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => {
                            setEdit(data);
                            setOpenEditModal(true);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor: "rgb(30, 50, 157)",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => {
                            setEdit(data);
                            setOpenEditModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            padding: "0px",
                          }}
                          onClick={() => deleteExpense(data._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Expense;
