import React from "react";

export const Review = ({ data }) => {
  return (
    <div className="review">
      <div className="container">
        <div className="review__container">
          <div className="review__title">
            <h2>
              শত শত মানুষের উপকার পাওয়ার রিভিউ আমাদের ফেসবুক পেইজে আছে। তার
              মধ্যে থেকে কিছু রিভিউ এখানে দেয়া হলঃ
            </h2>
            <img src={data.ReviewImage} alt="review__image" />
          </div>
        </div>
      </div>
    </div>
  );
};
