import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";

function CreateCategoryModal({ open, setOpenCategoryModal }) {
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const uid = localStorage.getItem("id");

  const handleClose = () => {
    setOpenCategoryModal(false);
  };

  const submitCategory = () => {
    setLoading(true);
    if (!category) {
      toast.error("Category name is required");
      setLoading(false);
      return;
    }
    if (!imageUrl) {
      toast.error("Category image is required");
      setLoading(false);
      return;
    }

    const formData = {
      uid,
      category,
      imageUrl,
      message,
    };

    AxiosUrl.post(`/create-category`, formData)
      .then((data) => {
        if (data.data === "Category Already Exist") {
          toast.error(data.data);
        } else if (data.data === "Category Successful") {
          toast.success("Category created successfully");
          setOpenCategoryModal(false);
        }
        setLoading(false);
        setCategory("");
        setMessage("");
        setImageUrl("");
        setPhoto(null);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to create category");
        setLoading(false);
      });
  };

  const cdnImage = async () => {
    const image = new FormData();
    image.append("photo", photo);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy", // Replace with your actual token
      },
    };
    const url = "https://cdn.ecombd.org/upload/category";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Image uploaded to CDN");
        setImageUrl(res.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to upload image");
      });
  };

  useEffect(() => {
    if (photo) {
      cdnImage();
    }
  }, [photo]);

  const handleFileChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? 290 : 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <h2
          style={{
            margin: 0,
            textTransform: "uppercase",
            color: "#1e2124",
            background: "aliceblue",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          Create New Category
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          {!imageUrl ? (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleFileChange}
              />
              <CloudUploadIcon style={{ marginTop: 20, fontSize: 100 }} />
            </IconButton>
          ) : (
            <img
              src={imageUrl}
              alt="Uploaded"
              style={{
                height: 150,
                width: 150,
                objectFit: "cover",
                marginTop: 20,
                borderRadius: 5,
                border: "1px solid #ccc",
              }}
            />
          )}
        </div>

        <TextField
          label="Category Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />

        <TextField
          label="Category Message (Optional)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={submitCategory}
          fullWidth
          sx={{ mt: 2, p: 1 }}
        >
          {loading ? "Loading..." : "Create Category"}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          fullWidth
          sx={{
            mt: 2,
            p: 1,
            color: "white",
            borderColor: "red",
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "darkred",
              borderColor: "darkred",
            },
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default CreateCategoryModal;
