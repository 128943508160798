import React from "react";
import TextItem from "../utils/TextItem";
import { PrimaryButton } from "../utils/Button";

const Decisions = ({ data }) => {
  return (
    <div className="decisions">
      <div className="container">
        <div className="decisions__container">
          <h1>সিদ্ধান্ত</h1>
          {data.Decisions.length > 0 &&
            data.Decisions.map((item, index) => (
              <TextItem key={index} text={item} />
            ))}
          <PrimaryButton text={"buy now"} handler={() => alert("buy now")} />
        </div>
      </div>
    </div>
  );
};

export default Decisions;
