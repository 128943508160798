import React, { useState, useEffect } from "react";
import "./CategoryEdtSelectModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CategoryEdtSelectModal({ setOpenModal, data }) {
  const [category, setCategory] = useState("");
  const [SelectData, setSelectData] = useState();
  const [loading, setloading] = useState(false);
  const uid = localStorage.getItem("id");

  const fromData = {
    uid,
    HcategoryID: data._id,
    CategoryData: SelectData,
  };

  const SelectCategory = () => {
    if (SelectData) {
      setloading(true);
      AxiosUrl.post(`/home-edit-category/${uid}`, fromData)
        .then((res) => {
          setOpenModal(false);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }
  };

  useEffect(() => {
    AxiosUrl.get(`/category/${uid}`)
      .then((data) => {
        setCategory(data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="modalBackground">
      <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setOpenModal(false)}>X</button>
        </div>
        <p
          style={{
            textAlign: "center",
            fontFamily: "initial",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          Select Category From The Below List
        </p>
        {loading && (
          <p
            style={{
              textAlign: "center",
              fontFamily: "initial",
              border: "1px solid #ccc",
              padding: "10px",
            }}
          >
            Loaging..
          </p>
        )}

        {category &&
          category.map((data) => {
            return (
              <div
                style={{
                  backgroundColor: "rgb(39, 82, 119)",
                  padding: "10px",
                  borderBottom: "1px",
                  marginBottom: "10px",
                  textAlign: "center",
                  cursor: "pointer",
                  color: "#fff",
                }}
                onClick={() => {
                  setSelectData(data);
                  SelectCategory();
                }}
              >
                {data.name}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CategoryEdtSelectModal;
