import React, { useState } from "react";
import Video from "./Video";
import Faq from "./Faq";
import Pricing from "./Pricing";
import { Review } from "./Review";
import { Form } from "./Form";

import Landing1Data from "../Landing1Data";

import "../landing-2.css";

const Landing1Home = () => {
  const [data, setData] = useState(Landing1Data);

  return (
    <div className="landing-page-main-container">
      <Video data={data} />
      <Faq data={data} />
      <Pricing data={data} />
      <Review data={data} />
      <Form data={data} />
    </div>
  );
};

export default Landing1Home;
