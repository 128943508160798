import React, { useState, useEffect } from "react";
import "./CategoryCreateSelectModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CategoryCreateSelectModal({ setOpenCategoryModal, setRefresh }) {
  const [Name, setName] = useState("");
  const [loading, setloading] = useState(false);

  const uid = localStorage.getItem("id");

  const fromData = {
    uid,
    Name,
  };

  const SubmitData = () => {
    setloading(true);
    AxiosUrl.post(`/home-create-category`, fromData)
      .then((data) => {
        setloading(false);
        if (data.data === "HomeCategory Limit 3") {
          toast.error(data.data);
        }
        if (data.data === "HomeCategory Successful") {
          setOpenCategoryModal(false);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="modalBackground">
      <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setOpenCategoryModal(false)}>X</button>
        </div>

        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="HomeCategory Name"
              id="fullWidth"
              onChange={(e) => setName(e.target.value)}
              value={Name}
            />
          </Box>
        </div>

        <div className="footer">
          <button
            onClick={() => {
              setOpenCategoryModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              SubmitData();
            }}
          >
            {loading ? "Loading.." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CategoryCreateSelectModal;
