import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import "./SearchDesktop.css";

const SearchDesktop = ({ products, setSearchResult }) => {
  const [search, setSearch] = useState("");

  const [clear, setClear] = useState(true);

  const handleSearch = (e) => {
    if (!e.target.value) {
      setClear(false);
    } else {
      setClear(true);
    }
    setSearch(e.target.value);

    const filteredResults = products.filter((item) =>
      item.productName.toLowerCase().includes(search.toLowerCase())
    );

    setSearchResult(filteredResults);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const clearSearch = () => {
    setSearch("");
    setSearchResult([]);
    setClear(false);
  };

  return (
    <form className="search-desktop-container" onSubmit={handleSubmit}>
      <div className="search-desktop-input">
        <input
          type="text"
          placeholder="I'am looking for..."
          value={search}
          onChange={handleSearch}
        />
        {search !== "" ? (
          <AiOutlineClose
            className="search-desktop-close-icon"
            onClick={clearSearch}
          />
        ) : (
          <FiSearch className="search-desktop-search-icon" />
        )}
      </div>
    </form>
  );
};

export default SearchDesktop;
