import React, { useState } from "react";
import TextItem from "../utils/TextItem";
import { PrimaryButton } from "../utils/Button";
import { ChangeDecisionModal } from "../../LandingPageModals/LandingPageModals";

const Decisions = ({ data, setData }) => {
  const [openDecision, setOpenDecision] = useState(false);
  return (
    <div className="decisions">
      <div className="container">
        <div className="decisions__container">
          <button
            className="landing-page-edit-btn"
            onClick={() => setOpenDecision(true)}
          >
            Edit
          </button>
          <h1>সিদ্ধান্ত</h1>
          {data.Decisions.length > 0 &&
            data.Decisions.map((item) => (
              <TextItem key={item.id} text={item.text} />
            ))}
          <PrimaryButton text={"buy now"} handler={() => alert("buy now")} />
        </div>
      </div>
      <ChangeDecisionModal
        openDecision={openDecision}
        setOpenDecision={setOpenDecision}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default Decisions;
