import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BkashExecutePaymentAPICall from "../../action/BkashExecutePaymentAPICall";
import BkashQueryPaymentAPICall from "../../action/BkashQueryPaymentAPICall";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";

function Callback() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentID = searchParams.get("paymentID");
  const status = searchParams.get("status");

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await BkashExecutePaymentAPICall(paymentID);

        if (response?.message) {
          response = await BkashQueryPaymentAPICall(paymentID);
        }

        if (response?.statusCode && response.statusCode === "0000") {
          await axios.post(`${URL}update-payment-sms-ec/${paymentID}`, {
            TransactionID: response?.trxID,
          });
          console.log("Success", response?.statusMessage);
          window.location.href = `/profile?message=${response?.statusMessage}&trxID=${response?.trxID}`;
        } else {
          await axios.post(`${URL}fail-payment-sms-ec/${paymentID}`, {
            TransactionID: response?.trxID,
          });
          console.log("Failure", response?.statusMessage);
          window.location.href = `/fail?message=${response?.statusMessage}`;
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }

    if (status && status === "success") {
      fetchData();
    } else {
      window.location.href = "/fail";
    }
  }, [status, paymentID]);

  return <h1 style={{ height: "100vh" }}>Loading...</h1>;
}

export default Callback;
