import React from "react";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

const StarRating = ({ rating, color }) => {
  const renderStars = () => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);

    const starElements = [];

    for (let i = 0; i < fullStars; i++) {
      starElements.push(<FaStar key={i} color={color} />);
    }

    if (hasHalfStar) {
      starElements.push(<FaStarHalfAlt key="half" color={color} />);
    }

    for (let i = 0; i < emptyStars; i++) {
      starElements.push(<FaRegStar key={i + fullStars + 1} color={color} />);
    }

    return starElements;
  };

  return <div>{renderStars()}</div>;
};

export default StarRating;
