import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "./Print.css";
import { useLocation, useNavigate } from "react-router-dom";

function Print() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  let productName;
  location.state.orderData.map((data) => {
    productName = `${data.title.substring(0, 30)} { Color: ${data.colorName} }`;
  });
  console.log(location.state);
  let PriceAfterAdvance;
  if (data.advance) {
    PriceAfterAdvance = data.totalPrice - data.advance;
  } else {
    PriceAfterAdvance = data.totalPrice;
  }
  // let tp =
  //   data.deliveryCharge === "dhaka"
  //     ? PriceAfterAdvance + 70 * 1
  //     : PriceAfterAdvance + 130 * 1;
  // console.log("tp", tp);
  let tp;
  if (data.deliveryCharge === "dhaka") {
    tp = 70 + PriceAfterAdvance * 1;
  } else {
    tp = 130 + PriceAfterAdvance * 1;
  }
  console.log("tp", tp);
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "emp-data",
    onAfterPrint: () => alert("Print completed"),
  });
  return (
    <>
      <div className="pbutton">
        <button onClick={handleprint}>Print Sticker</button>
        <button onClick={() => navigate("/create-list")}>Back to Order</button>
      </div>
      <div ref={componentRef} className="print-main">
        <div className="print-inside">
          <h2>RNS STAR</h2>
          <p>{`Name: ${data.cName}`}</p>
          <p>{`Mobile: ${data.cMobile}`}</p>
          <p>{`Address: ${data.address.substring(0, 48)}`}</p>
          <p>{`Total Due: ${tp}`}</p>
          <p>{`Product: ${data.product ? data.product : productName}`}</p>
        </div>
      </div>
    </>
  );
}

export default Print;
