import React from "react";
import { FeatureCard } from "../utils/Cards";
import Slider from "../utils/Slider";

const features = [
  "সৌন্দর্যবর্ধক উপকরণ হিসেবে লেডিস ব্যাগ তালিকার সবচেয়ে উপরের উপকরণ। কারণ এটি শুধু সৌন্দর্য বাড়ায় না এর উপযোগিতা যথেষ্ট গুরুত্বপূর্ণ। এটির ব্যবহার সময়ের সাথে বেড়েই চলেছে। আর সেই সাথে যুক্ত হয়েছে বাহারি রং, নকশা আর ডিজাইন।",
  "সকলে তার পছন্দ অনুযায়ী বেছে নিচ্ছে প্রিয় ব্যাগটি। তবে ব্যাগ পছন্দের পূর্বে ব্যাগটির কিছু বিশেষ বৈশিষ্ট্যের প্রতি নজর দেওয়া প্রয়োজন। এসবের মধ্যে রয়েছে ব্যাগটির গুনগত মান, উপযোগিতা, মূল্য ইত্যাদি। এসব বিষয় বিবেচনা করে ব্যাগ নির্বাচন করা প্রয়োজন।",
  "আর এই নির্বাচনের সুবিধার্থে একটি ব্যাগের কিছু বৈশিষ্ট্য নিয়ে আলোচনা করা হয়েছে। আশা করছি এটি আপনাকে ব্যাগটি বেছে নিতে যথেষ্ট সাহায্য করবে।",
];

const images = [
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-white.webp",
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-coffee.webp",
  "https://reviewpart.com/wp-content/uploads/2023/10/ladies-bag-code-108-brown.webp",
];

const Features = ({ data, setData }) => {
  return (
    <div className="features__container">
      <div className="container">
        <div className="features__card__container">
          <FeatureCard data={data} setData={setData} />
          <div className="feature__image__slider">
            <Slider>
              {data.SubSliders.length > 0 &&
                data.SubSliders.map((image, index) => (
                  <img key={image.id} src={image.src} />
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
