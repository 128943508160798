import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "./NewStore.css";
import AxiosUrl from "../AxiosUrl";
import AddDomainModal from "./Modal/AddDomain/AddDomain";
import StoreDescription from "./Modal/StoreDescription/StoreDescription";
import Contact from "./Modal/Contact/Contact";
import Delivery from "./Modal/DeliveryCharge/DeliveryCharge";
import BkashNagadRocket from "./Modal/BkashNagadRocket/BkashNagadRocket";
import BkashPaymentAPI from "./Modal/BkashPaymentAPI/BkashPaymentAPI";
import RocketPaymentAPI from "./Modal/RocketPaymentAPI/RocketPaymentAPI";
import NagadPaymentAPI from "./Modal/NagadPaymentAPI/NagadPaymentAPI";
import PathaoCourierAPI from "./Modal/PathaoCourierAPI/PathaoCourierAPI";
import SteadFastCourierAPI from "./Modal/SteadFastCourierAPI/SteadFastCourierAPI";
import Tracking from "./Modal/Tracking/Tracking";
import axios from "axios";

function NewStore() {
  const CreateStore = localStorage.getItem("CreateStore");
  const [addDomainModal, setAddDomainModal] = useState(false);
  const [DescriptionModal, setDescriptionModal] = useState(false);
  const [ContactModal, setContactModal] = useState(false);
  const [DeliveryModal, setDeliveryModal] = useState(false);
  const [BNRModal, setBNRModal] = useState(false);
  const [BkashApiModal, setBkashApiModal] = useState(false);
  const [RocketApiModal, setRocketApiModal] = useState(false);
  const [NagadApiModal, setNagadApiModal] = useState(false);
  const [PathaoApiModal, setPathaoApiModal] = useState(false);
  const [SteadFastApiModal, setSteadFastApiModal] = useState(false);
  const [TrackingModal, setTrackingModal] = useState(false);

  const [storeData, setStoreData] = useState();
  const [Refresh, setRefresh] = useState(1);
  const uid = localStorage.getItem("id");
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [loadingMeta, setLoadingMeta] = useState(false);
  const [favicon, setFavicon] = useState(null);
  const [faviconUrl, setFaviconUrl] = useState(null);
  const [faviconPreview, setFaviconPreview] = useState(null);
  const [storeLoading, setStoreLoading] = useState(false);
  const [storeName, setStoreName] = useState();

  const handleFaviconChange = (e) => {
    const file = e.target.files[0];
    setFavicon(file);
    setFaviconPreview(URL.createObjectURL(file));
  };

  const handleFaviconUpload = () => {
    if (!favicon) {
      toast.error("Please select a favicon to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("favicon", favicon);

    axios
      .post(`https://cdn.ecombd.org/upload/favicon`, formData)
      .then((data) => {
        setFaviconUrl(data.data);
      })
      .catch((error) => {
        toast.error("Favicon upload failed.");
      });
  };

  useEffect(() => {
    if (faviconUrl) {
      AxiosUrl.post(`/favicon/${uid}`, { favicon: faviconUrl }).then((data) => {
        if (data.data === "Failed") {
          toast.error(data.data);
        } else {
          toast.success(data.data);
          setRefresh(Refresh + 1);
        }
      });
    }
    return;
  }, [faviconUrl]);

  const handleVerify = (domain) => {
    setLoadingVerify(true);
    AxiosUrl.post(`/verify-domain/${uid}`, { domain })
      .then((data) => {
        toast.success(data.data);
        setRefresh(Refresh + 1);
        setLoadingVerify(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        setLoadingVerify(false);
      });
  };

  const AlartSubmit = (domain) => {
    setRefresh(false);
    confirmAlert({
      title: "DELETE CONFIRMATION",
      message: "Are you sure to delete the domain.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(domain),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const handleDelete = (domain) => {
    AxiosUrl.post(`/delete-domain/${uid}`, { domain })
      .then((data) => {
        toast.success(data.data);
        setStoreData();
        setRefresh(Refresh + 1);
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };

  const handleMeta = () => {
    if (storeData.metaTitle) {
      setLoadingMeta(true);
      AxiosUrl.post(`/update-meta/${uid}`, storeData)
        .then((data) => {
          toast.success(data.data);
          setRefresh(Refresh + 1);
          setLoadingMeta(false);
        })
        .catch((error) => {
          toast.error(error.response.data);
          setLoadingMeta(false);
        });
    } else {
      toast.error("Please set metaTitle, Description and keywords");
    }
  };

  useEffect(() => {
    AxiosUrl.get(`/get-domain-new/${uid}`)
      .then((data) => {
        setStoreData(data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [Refresh]);

  const CreateStoreSubmit = () => {
    setStoreLoading(true);
    if (!storeName) {
      toast.error("পেজ অথবা কোম্পানির নাম লিখুন");
      setStoreLoading(false);
      return;
    }

    AxiosUrl.post(`/add-domain-new/${uid}`, {
      uid,
      storeName,
      sub: "true",
    })
      .then((data) => {
        console.log(data.data);
        if (data.data === "Domain and SubDomain added successfully.") {
          toast.success(data.data);
          localStorage.setItem("CreateStore", true);
        }
        setRefresh(Refresh + 1);
        setStoreLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        setStoreLoading(false);
      });
  };
  if (CreateStore == "false")
    return (
      <>
        <div className="store-creation-main">
          <ToastContainer />
          <p>
            1 মিনিটের মধ্যে আপনার ই কমার্স ওয়েবসাইট তৈরী করতে নিচে আপনার
            ফেইসবুক পেজ অথবা কোম্পানির নাম লিখুন উধাহরণ Ecom Tech লিখে নিচে সেট
            স্টোর বাটন এ ক্লিক করুন
          </p>
          <div className="store-input-submit">
            <input
              placeholder="পেজ অথবা কোম্পানির নাম লিখুন"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            />
            <button onClick={CreateStoreSubmit}>
              {storeLoading ? "CREATEING STORE.." : "SET STORE"}
            </button>
          </div>
        </div>
      </>
    );

  return (
    <>
      {addDomainModal && (
        <AddDomainModal
          setAddDomainModal={setAddDomainModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
        />
      )}
      {DescriptionModal && (
        <StoreDescription
          setDescriptionModal={setDescriptionModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {ContactModal && (
        <Contact
          setContactModal={setContactModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {DeliveryModal && (
        <Delivery
          setDeliveryModal={setDeliveryModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {BNRModal && (
        <BkashNagadRocket
          setBNRModal={setBNRModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {BkashApiModal && (
        <BkashPaymentAPI
          setBkashApiModal={setBkashApiModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {RocketApiModal && (
        <RocketPaymentAPI
          setRocketApiModal={setRocketApiModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {NagadApiModal && (
        <NagadPaymentAPI
          setNagadApiModal={setNagadApiModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {PathaoApiModal && (
        <PathaoCourierAPI
          setPathaoApiModal={setPathaoApiModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {SteadFastApiModal && (
        <SteadFastCourierAPI
          setSteadFastApiModal={setSteadFastApiModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      {TrackingModal && (
        <Tracking
          setTrackingModal={setTrackingModal}
          setRefresh={setRefresh}
          Refresh={Refresh}
          Data={storeData}
        />
      )}
      <ToastContainer />
      <div className="main-new-store">
        <div
          style={{
            margin: "0 auto",
            border: "2px solid rgb(108, 140, 127)",
            padding: "22px",
            fontSize: "15px",
            borderRadius: "3px",
          }}
        >
          {" "}
          স্টোর পেইজ কিভাবে কাজ করে দেখুন ➡️{" "}
          <a href="https://youtu.be/rfcMoRaupWI" target="blank">
            VIDEO LINK
          </a>
        </div>
        <div className="new-store-title">View Store</div>

        {/* Store Section Start */}
        <div className="new-store-container">
          <div className="new-store-row">
            <div>
              <span className="new-store-bold">Store Name :</span>{" "}
              {storeData?.store ? storeData?.store : "Store Name Not Set"}
            </div>

            {/* Store Link Section Start */}
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              {storeData && storeData.domain && (
                <>
                  <span className="new-store-url">
                    {`Domain => ${
                      storeData.DomainSSL === true ? "https://" : "http://"
                    }${storeData?.domain}`}
                  </span>
                </>
              )}

              {storeData && storeData.SubDomain && (
                <>
                  <span className="new-store-url">
                    {`Sub-Domain => https://${storeData?.SubDomain}.ecombd.org`}
                  </span>
                </>
              )}
            </div>

            {/* Store Link Section End */}

            {/* Store Link Button Section Start */}
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              {storeData && storeData.domain && (
                <Link
                  to={`${
                    storeData.DomainSSL === true ? "https://" : "http://"
                  }${storeData?.domain}`}
                  target="_blank"
                  className="new-store-button"
                >
                  Visit Domain
                </Link>
              )}
              {storeData && storeData.SubDomain && (
                <Link
                  to={`https://${storeData?.SubDomain}.ecombd.org`}
                  target="_blank"
                  className="new-store-button"
                >
                  Visit Sub-Domain
                </Link>
              )}
            </div>
            {/* Store Link Button Section End */}
          </div>
        </div>

        {/* Store Section End */}

        <div className="new-store-container" style={{ position: "relative" }}>
          <div className="new-store-add-domain-title">
            <button
              onClick={() => setAddDomainModal(true)}
              className="new-store-add-domain-btn"
            >
              Add Domain
            </button>
          </div>

          {storeData !== undefined && storeData.domain ? (
            <>
              {storeData.DomainSSL == false && (
                <div className="new-store-add-domain-title">
                  <p
                    style={{
                      color: "#fff",
                      backgroundColor: "#ff4040",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    যদি আমাদের DNS সেট করে থাকেন {"( "}
                    ns1.ecombd.org এবং ns2.ecombd.org {")"} Name Server অ্যাড
                    করুন অথবা নিজের হোস্টিং থেকে যদি সাব ডোমেইন সেট করে থাকেন
                    তাহলে ভেরিফাই করতে Add "A" record Host name - "@" IP address
                    - "103.133.141.4" রেকর্ড টি করে ভেরিফাই বাটন এ ক্লিক করুন
                  </p>
                </div>
              )}
              <div className="new-store-content">
                <div className="new-store-content-column">
                  <div className="new-store-content-row">
                    <div className="new-store-side">Domain Name:</div>
                    <div className="new-store-middle">{storeData?.domain}</div>
                    <div className="new-store-middle">
                      {storeData?.DomainSSL !== undefined &&
                      storeData?.DomainSSL == false
                        ? "Not Verified"
                        : "Verified"}
                    </div>

                    {storeData.IsVerified !== 1 && (
                      <button
                        disabled={loadingVerify}
                        className={`new-store-button ${
                          loadingVerify ? "new-store-disabled-btn" : ""
                        }`}
                        onClick={() => handleVerify(storeData?.domain)}
                      >
                        {loadingVerify ? "Loading..." : "Verify Now"}
                      </button>
                    )}
                    <button
                      className="new-store-button-del"
                      onClick={() => AlartSubmit(storeData?.domain)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="new-store-no-domain-available">
              আপনার ডোমেইন থাকলে সেটি অ্যাড করতে পারবেন অথবা আমাদের মাধ্যমে
              ডোমেইন কিনতে পারবেন । কি ভাবে ডোমেইন সেট করবেন বা আমাদের মাধ্যমে
              কিনবেন এই ভিডিও টি দেখুন ➡️{" "}
              <a href="#" target="_blank">
                VIDEO LINK
              </a>
            </div>
          )}
        </div>
        <div className="new-store-title">Store</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Store Logo:</div>
                {storeData?.logo ? (
                  <div className="new-store-middle">
                    <img src={storeData?.logo} height={70} />
                  </div>
                ) : (
                  <div className="new-store-middle">
                    আপনি কোনো লোগো সেট করেননি
                  </div>
                )}

                <button
                  className="new-store-button"
                  onClick={() => setDescriptionModal(true)}
                >
                  Upload Logo
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Store Name:</div>
                <div className="new-store-middle">
                  {storeData?.store ? storeData?.store : "Store Name Not Set"}
                </div>
                <button
                  className="new-store-button"
                  onClick={() => setDescriptionModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">MetaTitle</div>
                <div className="new-store-middle">
                  {storeData?.metaTitle
                    ? storeData?.metaTitle
                    : "Meta Title Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setDescriptionModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">MetaDescription</div>
                <div className="new-store-middle  ">
                  {storeData?.metaDescription
                    ? storeData?.metaDescription
                    : "Meta Description Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setDescriptionModal(true)}
                >
                  Change
                </button>
              </div>

              <div className="new-store-content-row">
                <div className="new-store-side">MetaKeywords</div>
                <div className="new-store-middle">
                  {storeData?.metaKeywords
                    ? storeData?.metaKeywords
                    : "Meta Keyword Not Set Example: best women bag, best sunglass for women, Etc use comma after every keyword"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setDescriptionModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-button">
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => handleMeta()}
                >
                  {loadingMeta ? "Loading.." : "Update Meta Tag"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">Upload Favicon</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Favicon Size(64px By 64px)</div>
                <div className="new-store-middle">
                  {storeData?.favicon ? (
                    <img
                      src={storeData.favicon}
                      alt="Favicon Preview"
                      height={50}
                    />
                  ) : (
                    "No Favicon Uploaded"
                  )}
                </div>
                <input
                  type="file"
                  onChange={handleFaviconChange}
                  className="new-store-button"
                />
                <button
                  type="button"
                  className="new-store-button"
                  onClick={handleFaviconUpload}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">Store Address</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Mobile</div>
                <div className="new-store-middle">
                  {storeData?.number
                    ? storeData?.number
                    : "Mobile Number Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Email</div>
                <div className="new-store-middle">
                  {storeData?.Email
                    ? storeData?.Email
                    : "Email Address Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Address</div>
                <div className="new-store-middle">
                  {storeData?.Address ? storeData?.Address : "Address Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">WhatsApp</div>
                <div className="new-store-middle">
                  {" "}
                  {storeData?.WhatsApp
                    ? storeData?.WhatsApp
                    : "WhatsApp Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Messanger Url</div>
                <div className="new-store-middle">
                  {storeData?.MessangerUrl
                    ? storeData?.MessangerUrl
                    : "Messanger Url Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Facebook Page Url</div>
                <div className="new-store-middle">
                  {" "}
                  {storeData?.FacebookUrl
                    ? storeData?.FacebookUrl
                    : "Facebook Page Url Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">YouTube Url</div>
                <div className="new-store-middle">
                  {" "}
                  {storeData?.YouTubeUrl
                    ? storeData?.YouTubeUrl
                    : "YouTube Url Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Instagram Url</div>
                <div className="new-store-middle">
                  {storeData?.InstagramUrl
                    ? storeData?.InstagramUrl
                    : "Instagram Url Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">TikTok Url</div>
                <div className="new-store-middle">
                  {storeData?.TikTok ? storeData?.TikTok : "TikTok Url Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Linkedin Url</div>
                <div className="new-store-middle">
                  {storeData?.Linkedin
                    ? storeData?.Linkedin
                    : "Linkedin Url Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setContactModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">Store Delivery Charge</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Outside Dhaka</div>
                <div className="new-store-middle">
                  {" "}
                  {storeData?.Outside
                    ? storeData?.Outside
                    : "Delivery Charge Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setDeliveryModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Inside Dhaka</div>
                <div className="new-store-middle">
                  {storeData?.Dhaka
                    ? storeData?.Dhaka
                    : "Delivery Charge Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setDeliveryModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">Store Payment Number</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Bkash</div>
                <div className="new-store-middle">
                  {storeData?.Bkash ? storeData?.Bkash : "Bkash Number Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setBNRModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Rocket</div>
                <div className="new-store-middle">
                  {" "}
                  {storeData?.Rocket
                    ? storeData?.Rocket
                    : "Rocket Number Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setBNRModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Nagad</div>
                <div className="new-store-middle">
                  {storeData?.Nagad ? storeData?.Nagad : "Nagad Number Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setBNRModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">Bkash Payment API</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Bkash API Key</div>
                <div className="new-store-middle">
                  {storeData?.BkashAPIKey
                    ? storeData?.BkashAPIKey
                    : "Bkash Api Key Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setBkashApiModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Bkash Secret Key</div>
                <div className="new-store-middle">
                  {storeData?.BkashSecret
                    ? storeData?.BkashSecret
                    : "Bkash Api Secret Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setBkashApiModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">Rocket Payment API</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Rocket Api Key:</div>
                <div className="new-store-middle">
                  {storeData?.RocketAPIKey
                    ? storeData?.RocketAPIKey
                    : "Rocket Api Key Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setRocketApiModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Rocket Secret Key:</div>
                <div className="new-store-middle">
                  {storeData?.RocketSecret
                    ? storeData?.RocketSecret
                    : "Rocket Api Secret Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setRocketApiModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">Nagad Payment API</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Nagad API Key:</div>
                <div className="new-store-middle">
                  {storeData?.NagadAPIKey
                    ? storeData?.NagadAPIKey
                    : "Nagad Api Key Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setNagadApiModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Nagad Secret Key:</div>
                <div className="new-store-middle">
                  {storeData?.NagadSecret
                    ? storeData?.NagadSecret
                    : "Nagad Api Secret Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setNagadApiModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">Pathao Courier API</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">Pathao API Key</div>
                <div className="new-store-middle">
                  {storeData?.PathaoAPIKey
                    ? storeData?.PathaoAPIKey
                    : "Pathao Api Key Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setPathaoApiModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Pathao Secret Key</div>
                <div className="new-store-middle">
                  {storeData?.PathaoSecret
                    ? storeData?.PathaoSecret
                    : "Pathao Api Secret Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setPathaoApiModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="new-store-title">SteadFast Courier API</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">SteadFast API Key:</div>
                <div className="new-store-middle">
                  {storeData?.SteadFastAPIKey
                    ? storeData?.SteadFastAPIKey
                    : "SteadFast Api Key Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setSteadFastApiModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">SteadFast Secret Key:</div>
                <div className="new-store-middle">
                  {storeData?.SteadFastSecret
                    ? storeData?.SteadFastSecret
                    : "SteadFast Api Secret Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setSteadFastApiModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="new-store-title">Store Tracking</div>
        <div className="new-store-container">
          <div className="new-store-content">
            <div className="new-store-content-column">
              <div className="new-store-content-row">
                <div className="new-store-side">FacebookPixel</div>
                <div className="new-store-middle">
                  {" "}
                  {storeData?.pixel
                    ? storeData?.pixel
                    : "FaceBook Pixel Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setTrackingModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">FaceBook Conversions API</div>
                <div className="new-store-middle">
                  {" "}
                  {storeData?.FAccessToken
                    ? storeData?.FAccessToken.substring(0, 30) + "..."
                    : "Access Token Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setTrackingModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Google Analytic</div>
                <div className="new-store-middle">
                  {storeData?.GoogleAnalytic
                    ? storeData?.GoogleAnalytic
                    : "Google Analytic Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setTrackingModal(true)}
                >
                  Change
                </button>
              </div>
              <div className="new-store-content-row">
                <div className="new-store-side">Google Ads Tag</div>
                <div className="new-store-middle">
                  {storeData?.GoogleAdsTag
                    ? storeData?.GoogleAdsTag
                    : "Google Ads Tag Not Set"}
                </div>
                <button
                  type="button"
                  className="new-store-button "
                  onClick={() => setTrackingModal(true)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewStore;
