import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Cartcontext } from "../../context/Context";
import "./Cart.css";

const Cart = () => {
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;

  const total = state.reduce((total, item) => {
    return total + item.proceSell * item.setQuantity;
  }, 0);

  const navigate = useNavigate();
  return (
    <>
      <div className="cart">
        {state.length <= 0 && <div>No data</div>}
        {state.map((item, index) => {
          return (
            <div className="card" key={index}>
              <img src={item.imgeUrl} alt="" />
              <p>{item.productName}</p>
              <p>{item.setQuantity * item.proceSell}</p>
              <div className="quantity">
                <button
                  onClick={() => dispatch({ type: "INCREASE", payload: item })}
                >
                  +
                </button>
                <p>{item.setQuantity}</p>
                <button
                  onClick={() => {
                    if (item.setQuantity > 1) {
                      dispatch({ type: "DECREASE", payload: item });
                    } else {
                      dispatch({ type: "REMOVE", payload: item });
                    }
                  }}
                >
                  -
                </button>
              </div>
              <h2 onClick={() => dispatch({ type: "REMOVE", payload: item })}>
                x
              </h2>
            </div>
          );
        })}
        {state.length > 0 && (
          <>
            <div className="total">
              <h2>{total}</h2>
              <button
                className="checkout-button"
                onClick={() => navigate("/checkout")}
              >
                CHECK OUT
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;
