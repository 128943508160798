import React, { useState } from "react";
import { TextCard } from "../utils/Cards";
import { ChangeCardModal } from "../../LandingPageModals/LandingPageModals";

const EffectivenessCards = ({ data, setData }) => {
  const [openCard, setOpenCard] = useState(false);
  return (
    <div className="effectiveness__card">
      <div className="container">
        <button
          className="landing-page-edit-btn"
          onClick={() => setOpenCard(true)}
          style={{ marginTop: "30px" }}
        >
          Edit
        </button>
        <div className="effectiveness__card__container">
          {data.ThreeTitles.length > 0 &&
            data.ThreeTitles.map((item) => (
              <TextCard key={item.id} item={item} />
            ))}
        </div>
      </div>
      <ChangeCardModal
        openModal={openCard}
        setOpenModal={setOpenCard}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default EffectivenessCards;
