import React, { useState } from "react";
import "./EditTeamModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditTeamModal({ setOpenEditModal, data }) {
  const [name, setName] = useState(data.name);
  const [mobile, setMobile] = useState(data.mobile);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userStatus, setStatus] = useState(data.userStatus);

  const uid = localStorage.getItem("id");
  const formData = {
    id: data._id,
    name,
    mobile,
    password,
    userStatus,
  };

  const submitTeamEdit = () => {
    setLoading(true);
    AxiosUrl.post(`/edit-team/${uid}`, formData)
      .then((res) => {
        toast.success(res.data);
        setOpenEditModal(false);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <div className="editTeamModalBackground">
      <ToastContainer />
      <div className="editTeamModalContainer">
        <div className="editTeamTitleCloseBtn">
          <button onClick={() => setOpenEditModal(false)}>X</button>
        </div>
        <div className="editTeamTitle">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Edit Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Edit Mobile"
              type="number"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              fullWidth
              label="Update Password"
              placeholder="Optional"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Box>
          <select
            name="status"
            onChange={(e) => setStatus(e.target.value)}
            value={userStatus}
            style={{ margin: "20px" }}
          >
            <option value="0">Active</option>
            <option value="1">Deactive</option>
          </select>
        </div>

        <div className="editTeamFooter">
          <button
            onClick={() => setOpenEditModal(false)}
            id="editTeamCancelBtn"
          >
            Cancel
          </button>
          <button onClick={submitTeamEdit}>
            {loading ? "Loading.." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditTeamModal;
