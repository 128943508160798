import React, { useState } from "react";
import "./TeamModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddTeamModal({ setOpenAddModal }) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("id");

  const formData = {
    uid,
    name,
    mobile,
    password,
  };

  const submitTeam = () => {
    setLoading(true);
    if (!name || !mobile || !password) {
      setLoading(false);
      return;
    }

    AxiosUrl.post(`/add-team/${uid}`, formData)
      .then((data) => {
        if (data.data === "Create_team success..") {
          toast.success(data.data);
          setOpenAddModal(false);
        } else {
          toast.error(data.data);
        }
        setLoading(false);
        setName("");
        setMobile("");
        setPassword("");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="addTeamModalBackground">
      <ToastContainer />
      <div className="addTeamModalContainer">
        <div className="addTeamTitleCloseBtn">
          <button onClick={() => setOpenAddModal(false)}>X</button>
        </div>
        <div className="addTeamTitle">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Name"
              id="fullWidth"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "10px" }}>
            <TextField
              fullWidth
              label="Mobile"
              type="number"
              id="fullWidth"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              fullWidth
              label="Password"
              type="password"
              id="fullWidth"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Box>
        </div>

        <div className="addTeamFooter">
          <button onClick={() => setOpenAddModal(false)} id="addTeamCancelBtn">
            Cancel
          </button>
          <button onClick={submitTeam}>
            {loading ? "Loading.." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddTeamModal;
