import React, { useState } from "react";
import "./ExpenseModalEdit.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ExpenseModalEdit({ setOpenEditModal, setRefresh, refresh, data }) {
  const [Title, setTitle] = useState(data.Title);
  const [Amount, setAmount] = useState(data.Amount);
  const [ExpenseId, setExpenseId] = useState(data.ExpenseId);
  const [Description, setDescription] = useState(data.Description);
  const [loading, setloading] = useState(false);

  const uid = localStorage.getItem("id");
  const fromData = {
    uid,
    Title,
    Amount,
    ExpenseId,
    Description,
    ExId: data._id,
  };

  const SubmitExpenseModal = () => {
    setloading(true);
    if (!Title || !Amount || !ExpenseId || !Description) {
      toast.error("Title, Amount, ExpenseId, Description is Required..");
      return setloading(false);
    }

    AxiosUrl.post(`/edit-expense`, fromData)
      .then((res) => {
        setOpenEditModal(false);
        setRefresh(refresh + 1);
        setloading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <div className="ExpenseBG">
      <ToastContainer />
      <div className="ExpenseModalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenEditModal(false);
              setRefresh(refresh + 1);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "15px" }}>
            <TextField
              fullWidth
              label="Expense Title"
              id="fullWidth"
              onChange={(e) => setTitle(e.target.value)}
              value={Title}
              placeholder="Example: Pay for internet bill"
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "15px" }}>
            <TextField
              type="number"
              fullWidth
              label="Expense Amount"
              id="fullWidth"
              onChange={(e) => setAmount(e.target.value)}
              value={Amount}
              placeholder="Example: Amount of the internet bill *Must be number"
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom: "15px" }}>
            <TextField
              fullWidth
              label="Expense ID"
              id="fullWidth"
              onChange={(e) => setExpenseId(e.target.value)}
              value={ExpenseId}
              placeholder="Example: Invoice number of the bill"
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              fullWidth
              label="Description"
              id="fullWidth"
              onChange={(e) => setDescription(e.target.value)}
              value={Description}
              placeholder="Example: Details of the bill that you've paid"
            />
          </Box>
        </div>

        <div className="footer">
          <button
            onClick={() => {
              setOpenEditModal(false);
              setRefresh(refresh + 1);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              SubmitExpenseModal();
            }}
          >
            {loading ? "Loading.." : "Edit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExpenseModalEdit;
