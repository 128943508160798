import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";

function EditCategoryModal({ setOpenModal, data, setRefresh }) {
  const uid = localStorage.getItem("id");
  const [category, setCategory] = useState(data.name || "");
  const [message, setMessage] = useState(data.message || "");
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [imageUrl, setImageUrl] = useState(data.imgUrl || "");
  const [status, setStatus] = useState(data.status || 0); // Status state

  const handleClose = () => setOpenModal(false);

  const handleSave = () => {
    setLoading(true);
    if (!category) {
      toast.error("Category name is required");
      setLoading(false);
      return;
    }

    const formData = {
      uid,
      category,
      imageUrl,
      oldCategory: data.name,
      message,
      status, // Include status in the formData
    };

    AxiosUrl.post(`/edit-category/${uid}`, formData)
      .then((res) => {
        toast.success("Category updated successfully");
        setRefresh((prev) => prev + 1);
        handleClose();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to update category:", error);
        toast.error("Failed to update category");
        setLoading(false);
      });
  };

  const cdnImage = async () => {
    const image = new FormData();
    image.append("photo", photo);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy", // Replace with your actual token
      },
    };
    const url = "https://cdn.ecombd.org/upload/category";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Image uploaded to CDN");
        setImageUrl(res.data);
      })
      .catch((error) => {
        console.error("Failed to upload image:", error);
        toast.error("Failed to upload image");
      });
  };

  useEffect(() => {
    if (photo) {
      cdnImage();
    }
  }, [photo]);

  const handleFileChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? 290 : 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <ToastContainer />
        <h2
          style={{
            margin: 0,
            textTransform: "uppercase",
            color: "#1e2124",
            background: "aliceblue",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          Edit Category
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "16px",
            position: "relative",
          }}
        >
          {imageUrl && (
            <div style={{ position: "relative" }}>
              <img
                src={imageUrl}
                alt="Uploaded"
                style={{
                  height: 150,
                  width: 150,
                  objectFit: "cover",
                  marginTop: 20,
                  borderRadius: 5,
                  border: "1px solid #ccc",
                }}
              />
              <IconButton
                color="primary"
                aria-label="re-upload picture"
                component="label"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "white",
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileChange}
                />
                <CloudUploadIcon style={{ fontSize: 60 }} />
                <p style={{ fontSize: 10, margin: 0 }}>Re-upload</p>
              </IconButton>
            </div>
          )}
          {!imageUrl && (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleFileChange}
              />
              <CloudUploadIcon style={{ marginTop: 20, fontSize: 100 }} />
            </IconButton>
          )}
        </div>

        <TextField
          label="Category Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />

        <TextField
          label="Category Message (Optional)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            value={status}
            label="Status"
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value={0}>Active</MenuItem>
            <MenuItem value={1}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          fullWidth
          sx={{ mt: 2, p: 1 }}
        >
          {loading ? "Loading..." : "Update Category"}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          fullWidth
          sx={{
            mt: 2,
            p: 1,
            color: "white",
            borderColor: "red",
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "darkred",
              borderColor: "darkred",
            },
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default EditCategoryModal;
