import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import "./Order.css";
import myAxios from "../AxiosUrl";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Order() {
  const uid = localStorage.getItem("id");
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [products, setProduct] = useState([]);

  const Purl = `get-products/${uid}`;
  useEffect(() => {
    myAxios
      .get(Purl)
      .then((res) => {
        setProduct(res.data);
        handleClose(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  const Globalstate = useContext(Cartcontext);
  const dispatch = Globalstate.dispatch;
  return (
    <div style={{height:"80vh"}}>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section>
        <h1 style={{ marginBottom: 40 }}>Product Stock</h1>
        <div className="tbl-header">
          <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th>Photo</th>
                <th>Name</th>
                <th>Buying Price</th>
                <th>Selling Price</th>
                <th>Color</th>
                <th>Quantity</th>
                <th>Edit/Delete</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="tbl-content">
          <table cellPadding="0" cellSpacing="0" border="0">
            <tbody>
              {products.map((item, index) => {
                item.setQuantity = 1;
                return (
                  <tr key={index}>
                    <td>
                      <img
                        style={{ width: 70, height: 70, borderRadius: "12px" }}
                        src={item.imgeUrl}
                        alt="product"
                      />
                    </td>
                    <td>{item.productName}</td>
                    <td>{item.priceBuy}</td>
                    <td>{item.proceSell}</td>
                    <td>{item.color}</td>
                    <td>{item.quantity}</td>
                    <td>
                      <button
                        onClick={() => {
                          if (!item.quantity) {
                            return toast.error("Out of Stock");
                          }
                          dispatch({ type: "ADD", payload: item });
                        }}
                        className="td-button"
                      >
                        Add to Cart
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default Order;
